import React from 'react'
import Button from '../../common/Button'
import Spacer from '../../common/Spacer'

import LockIcon from '../../../assets/svg/Lock'
import styled from 'styled-components'

const StyledLock = styled(LockIcon)`
  float: right;
  padding-top: 3px;

`

const GenerateOtp = ({ onGenerate }) => {
  return (
    <>
      <div>OTP generieren</div>
      <Spacer height='10px' />
      <Button onClick={onGenerate}>
        One Time Password
        <StyledLock height='50px' width='40px' />
      </Button>
    </>
  )
}

export default GenerateOtp
