import React from 'react'

const SvgArrowDown = props => (
  <svg viewBox='0 0 12 12' width='1em' height='1em' {...props}>
    <title>Path 2 Copy</title>
    <path d='M12 0H0l6 12z' fill='#00528C' fillRule='evenodd' />
  </svg>
)

export default SvgArrowDown
