import React from 'react'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const StyledSwitchLabel = styled(Box)`
  font-size: 14px;
  clear: right;
  padding-bottom: 20px;
`

const StyledSwitch = styled(Switch)`
  float: right;
  margin-top: -10px;
  & .MuiSwitch-switchBase.Mui-checked {
    color: #00528C;
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #00528C;
  }
`

const ReaSwitch = (props) => {
  return (
    <StyledSwitchLabel>
      {props.label}
      <StyledSwitch color='primary' {...props} />
    </StyledSwitchLabel>
  )
}

ReaSwitch.propTypes = {
  label: PropTypes.string
}

export default ReaSwitch
