import React from 'react'
import IconUpload from '../../../assets/svg/Upload'
import IconImage from '../../../assets/svg/Foto'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useDropzone } from 'react-dropzone'

const StyledDiv = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 180px;
  border: 1px dashed #9C9C9C;
  cursor: pointer;
  background-color: #F5F5F5;
  .dropzone {
    width: 100%;
    min-height: 200px;
  }
`

const FileInformation = styled.aside`
  background: rgba(0,0,0,0.5);
  padding: 10px;
  ul {
    li {
      padding-top: 10px;
    }
  }
`

const Foreground = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  height: 100%;
`

const bgStyles = `
  position: absolute;
  height: 100px;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`

const UploadBg = styled(IconUpload)`
  ${bgStyles}
`
const ImageBg = styled(IconImage)`
  ${bgStyles}
`

const DropZone = ({ filetype = 'image', icon = 'upload', handleDropzoneChange, target, thumbnail = '' }) => {
  /* Triggers when file is selected via dialog or dropped in */
  const onDropAccepted = (acceptedFiles) => {
    handleDropzoneChange(target, acceptedFiles)
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDropAccepted })

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <StyledDiv>
      {icon === 'upload' &&
        <UploadBg fill='#939393' />}
      {icon === 'image' &&
        <ImageBg fill='#939393' />}
      <Foreground>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {thumbnail &&
            <ThumbnailHolder>
              <DropContainer>
                <DocImageWrapper>
                  <DocImage src={thumbnail} />
                </DocImageWrapper>
              </DropContainer>
            </ThumbnailHolder>}
        </div>
        {acceptedFiles.length > 0 &&
          <FileInformation>
            <h4>Ausgewählte Datei</h4>
            <ul>{files}</ul>
          </FileInformation>}
      </Foreground>
    </StyledDiv>
  )
}

DropZone.propTypes = {
  icon: PropTypes.string,
  filetype: PropTypes.string
}

export default DropZone

const DocImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 2;
`

const DocImage = styled.img`
  max-width: 100%;
  max-height: 150px;
  margin-top: 25px;
  z-index: 2;
`

const ThumbnailHolder = styled.div`
  position:relative
`

const DropContainer = styled.div`
  position: relative;
  z-index: 1
`
