import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Permissions from '../common/ApiHandler/Permission'
import Checkbox from '../common/FormElements/Checkbox'
import ArrowRight from '../../assets/svg/PfeilRechts'
import ArrowDown from '../../assets/svg/PfeilUnten'
import LoadIndicator from '../common/LoadIndicator'

const Wrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 20px 20px 0px 20px;
`
const NestedDiv = styled.div`
  background: #FFF;
  display: block;
  margin-left: 40px;
`

const IconWrapper = styled.div`
  content-align: left;
  float: left;
  padding-right: 10px;
`

const CheckboxWrapper = styled.div`
  padding-left: ${props => props.childless ? '26px' : '0px'};

`

const PermissionItem = ({ permission, onChange, selectedPermissions = [], hasActiveParent = false }) => {
  const findActiveIdRecursive = (item) => {
    if (item.children && item.children.length > 0) {
      if (item.children.find(child => selectedPermissions.includes(child.id)) !== undefined) {
        return true
      }
      // eslint-disable-next-line
      for (const child of item.children) {
        if (findActiveIdRecursive(child)) {
          return true
        }
      }
    }
    return false
  }

  const hasActiveChildren = findActiveIdRecursive(permission)
  const [displayChildren, setDisplayChildren] = useState(hasActiveChildren)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(hasActiveParent)
  }, [hasActiveParent])

  useEffect(() => {
    const checked = hasActiveParent || selectedPermissions.find(search => search === permission.id) !== undefined || false
    setIsChecked(checked)
    setDisplayChildren(checked || findActiveIdRecursive(permission))
    // eslint-disable-next-line
  }, [selectedPermissions])

  const toggleNestedItems = () => {
    setDisplayChildren(!displayChildren)
  }

  const checkChildless = () => {
    if (permission.children && permission.children.length < 1) {
      return true
    } else {
      return false
    }
  }

  const handlePermissionChange = (ChangedPermission, isCheckedStatus) => {
    setIsChecked(isCheckedStatus)
    onChange(ChangedPermission, isCheckedStatus)
  }

  return (
    <>
      {permission.children && permission.children.length > 0 &&
        <IconWrapper>
          {!displayChildren &&
            <ArrowRight onClick={toggleNestedItems} height='18px' fill='#003B64' />}
          {displayChildren &&
            <ArrowDown onClick={toggleNestedItems} height='18px' fill='#003B64' />}
        </IconWrapper>}
      <CheckboxWrapper childless={checkChildless()}>
        <Checkbox
          onChange={(value, checkedStatus) => handlePermissionChange(value, checkedStatus)}
          title={permission.title}
          value={permission.id}
          isChecked={isChecked} />
      </CheckboxWrapper>
      {permission.children && permission.children.length > 0 && displayChildren &&
        <NestedDiv>
          {permission.children.map((childPermission) => {
            return (
              <PermissionItem
                key={childPermission.id}
                permission={childPermission}
                onChange={onChange}
                selectedPermissions={selectedPermissions}
                hasActiveParent={isChecked} />
            )
          })}
        </NestedDiv>
      }
    </>
  )
}

const PermissionSelection = ({ defaultPermissions, onChange, loadingPermission }) => {
  /* List of all permissions */
  const [permissions, setPermissions] = useState([])
  /* List of selected (checked) TAGS */
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      const API = Permissions({})
      const allPermissions = await API.search()
      setPermissions(allPermissions)
      setLoading(false)
    }
    loadData()
  }, [])

  useEffect(() => {
    setSelectedPermissions(defaultPermissions)
  }, [defaultPermissions])

  return (
    <div>
      {!loading &&
        <Wrapper>
          {permissions.map((permission) => {
            return (
              <PermissionItem key={permission.id} permission={permission} onChange={loadingPermission ? () => { } : onChange} selectedPermissions={selectedPermissions} />
            )
          })}
        </Wrapper>}
      {loading &&
        <LoadIndicator />}
    </div>
  )
}

export default PermissionSelection
