import React from 'react'
import Grid from '@mui/material/Grid'
import Spacer from '../../common/Spacer'
import DeviceButton from './DeviceButton'

export default function OtpDeviceLimits ({ limit = 1, onChange }) {
  const handleChange = (newLimit) => () => {
    if (onChange) {
      onChange(newLimit)
    }
  }

  return (
    <>
      <div>Anzahl der Geräte für diesen Nutzer festlegen</div>
      <Spacer height='10px' />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DeviceButton limit={1} currentLimit={limit} onClick={handleChange(1)} />
        </Grid>
        <Grid item xs={2}>
          <DeviceButton limit={2} currentLimit={limit} onClick={handleChange(2)} />
        </Grid>
        <Grid item xs={2}>
          <DeviceButton limit={3} currentLimit={limit} onClick={handleChange(3)} />
        </Grid>
        <Grid item xs={2}>
          <DeviceButton limit={4} currentLimit={limit} onClick={handleChange(4)} />
        </Grid>
        <Grid item xs={2}>
          <DeviceButton limit={5} currentLimit={limit} onClick={handleChange(5)} />
        </Grid>
        <Grid item xs={2}>
          <DeviceButton limit={6} currentLimit={limit} onClick={handleChange(6)} />
        </Grid>
      </Grid>
    </>
  )
}
