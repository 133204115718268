import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import Dropzone from '../common/FormElements/Dropzone'
import styled from 'styled-components'
import Button from '../common/Button'
import Documents, { Document } from '../common/ApiHandler/Document'
import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import TagSelection from '../Tag/TagSelection'
import { Permission } from '../common/ApiHandler/Permission'
import PermissionSelection from '../Permissions/PermissionSelection'
import Tags from '../common/ApiHandler/Tag'
import LoadIndicator from '../common/LoadIndicator'
import { Wrapper, TabSelection, TabButton, Tab, TabContent } from '../Tag/TagBase'

import Switch from '../common/FormElements/Switch'
import TextInput from '../common/FormElements/TextInput'
import Flag from '../common/Flag'
import TextArea from '../common/FormElements/TextArea'
import TagPopUp from './TagPopUp'
import NewButton from '../common/LinkButton'
import 'react-datepicker/dist/react-datepicker.css'

const MailContentArea = styled(Grid)`
  max-height: 400px;
`

const ButtonWithTitleMargin = styled(Button)`
  margin-top: 28px
`

const MailSuccessMessageContainer = styled(Grid)`
  text-align: center;
  font-weight: bold;
`

const SendMailButton = styled(Button)`
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const DocumentEdit = ({ match, ...props }) => {
  const initialState = {
    id: '',
    title_en: '',
    title_de: '',
    channelapp: false,
    shareable: false,
    showAsNew: false,
    channelportal: false,
    draft: true,
    tags: [],
    permissions: [],
    thumbnail: '',
    file: '',
    internalNote: '',
    newSince: ''
  }

  const [document, setDocument] = useState(initialState)
  const [mail, setMail] = useState({})
  const [wasSent, setWasSent] = useState()

  const [errorNotification, setErrorNotification] = useState('')

  /* Default: Create Mode */
  const [status, setStatus] = useState({
    create: true,
    update: false,
    success: false,
    mail: false
  })

  const [activeTab, setActiveTab] = useState()
  const [oldPermissions, setOldPermissions] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [permissionsToAssign, setPermissionsToCreate] = useState([])
  const [oldTags, setOldTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [allTagTypes, setAllTagTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingPermission, setLoadingPermission] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [requiredMailFormFieldsFilled, setRequiredMailFormFieldsFilled] = useState(false)
  const [originalDocument, setOriginalDocument] = useState({})

  const [thumbnail, setThumbnail] = useState()
  const [display, setDisplay] = useState({
    file: false,
    thumbnail: false
  })

  const [newTag, setNewTag] = useState(false)
  const [isNew, setIsNew] = useState(false)

  /* Switch to update mode and receive data */
  useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus({ ...status, update: true, create: false })
      loadData()
    } else {
      // Create Mode, reset everything to initial State
      setStatus({ ...status, create: true, update: false, delete: false, success: false })
      setDocument(initialState)
      setOldTags([])
      setSelectedTags([])
      setOldPermissions([])
      setSelectedPermissions([])
    }
    // eslint-disable-next-line
  }, [match])

  useEffect(() => {
    if (document.filename) {
      setDisplay({ ...display, file: true })
    }
    // eslint-disable-next-line
  }, [document.filename])

  useEffect(() => {
    const getAllTypes = async () => {
      const tagArray = []
      selectedTags.forEach(async (id) => {
        const currentTag = Tags({ id: id })
        const foundTag = await currentTag.findOne()
        tagArray.push(foundTag)
      })
      setAllTagTypes(tagArray)
    }
    getAllTypes()
  }, [selectedTags])

  const getThumbnail = async (foundDocument) => {
    const thumbnail = await Document(foundDocument).getThumbnail(foundDocument)
    await setThumbnail(thumbnail)
    if (foundDocument.filename) {
      setDisplay(({ file: true, thumbnail }))
    } else {
      setDisplay({ ...display, thumbnail })
    }
  }
  /**
   * INITIAL DATALOAD
   */
  /* Load Data for Update */
  const loadData = async () => {
    const currentDocument = Documents({ id: match.params.id })
    const foundDocument = await currentDocument.findOne()

    const allTagData = foundDocument.tags
    const originalTags = []
    allTagData.forEach((tag) => {
      originalTags.push(tag.id)
    })
    setOriginalDocument(foundDocument)
    setTags(originalTags)
    setDocument(foundDocument)
    getThumbnail(foundDocument)
    if (foundDocument.title_de) {
      setActiveTab('de')
    } else {
      setActiveTab('en')
    }
    const originalPermissions = []
    const allPermissionData = foundDocument.permissions
    allPermissionData.forEach((permission) => {
      originalPermissions.push(permission.id)
    })
    setOldPermissions(originalPermissions)
    setSelectedPermissions(originalPermissions)
  }

  /**
   * DATA SUBMIT
   */

  const submit = async () => {
    if (isNew) {
      document.newSince = new Date()
    }
    /* remove tags from document object to prevent errors */
    let res = false
    let thumbnailUploadSuccessful = false

    /* DOCUMENT CREATE */
    if (!document.id) {
      const missingProps = []

      if (!document.file) {
        missingProps.push('eine Datei')
      }
      if (selectedTags.length < 1) {
        missingProps.push('einen Tag')
      }
      if (!document.channelapp && !document.channelportal) {
        missingProps.push('eine Sichtbarkeit')
      }
      if (!document.title_de && !document.title_en) {
        missingProps.push('einen Titel')
      }
      if (selectedPermissions.length < 1) {
        missingProps.push('eine Berechtigung')
      }

      if (missingProps.length > 0) {
        if (missingProps.length > 1) {
          missingProps[missingProps.length - 1] = ' und ' + missingProps[missingProps.length - 1]
        }

        let errorString = 'Sie müssen '

        missingProps.forEach((prop) => {
          if (missingProps.indexOf(prop) === missingProps.length - 1 || missingProps.indexOf(prop) === missingProps.length - 2) {
            errorString = errorString + prop
          } else {
            errorString = errorString + prop + ', '
          }
        })
        errorString = errorString + ' auswählen'
        setErrorNotification(errorString)
      } else {
        setLoading(true)
        const API = Documents(document)
        res = await API.create()
        if(permissionsToAssign && permissionsToAssign.length){
          const PermissionAPI = Permission({})
          await permissionsToAssign.map(async (permission) => {
            await PermissionAPI.assignDocument(res.id, permission)
          })
        }
        if (document.thumbnail !== '') {
          // eslint-disable-next-line
          let uploadRes = false
          const DocAPI = Documents(document.thumbnail, true, res.id)
          uploadRes = await DocAPI.upload(document.thumbnail, true, res.id)
          thumbnailUploadSuccessful = uploadRes.success
        }
        if (document.file) {
          // eslint-disable-next-line
          const DocAPI = Documents(document.file, false, res.id)
          await DocAPI.upload(document.file, false, res.id, thumbnailUploadSuccessful)
        }
        setLoading(false)
      }

      /* DOCUMENT UPDATE */
    } else {
      setLoading(true)
      const updatedDocument = { ...document }
      delete updatedDocument.referenceid

      if (typeof document.thumbnail !== 'string' && document.thumbnail !== undefined) {
        const DocAPI = Documents(document.thumbnail, false, document.id)
        let uploadRes = false
        uploadRes = await DocAPI.upload(document.thumbnail, true, document.id)
        thumbnailUploadSuccessful = uploadRes.success
        if (uploadRes.error) {
          setErrorNotification(Translation(uploadRes.error))
          return
        } else if (typeof uploadRes !== 'object') {
          setErrorNotification(Translation(uploadRes))
          return
        }
      }
      if (document.file && typeof document.file !== 'string') {
        let uploadRes = false
        const DocAPI = Documents(document)
        uploadRes = await DocAPI.upload(document.file, false, document.id, thumbnailUploadSuccessful)

        if (uploadRes.error) {
          setErrorNotification(Translation(uploadRes.error))
          return
        } else if (typeof uploadRes !== 'object') {
          setErrorNotification(Translation(uploadRes))
          return
        }
      }
      res = await updatedDocument.save()
      setLoading(false)
    }
    if (typeof res === 'object') {
      if (res.error) {
        setErrorNotification(Translation(res.error))
        return
      }
      getUnassignedTags().forEach(async (tagId) => {
        await res.unassignTag(tagId)
      })
      getAssignedTags().forEach(async (tagId) => {
        await res.assignTag(tagId)
      })
      setStatus({ ...status, success: true })
    }
  }

  useEffect(() => {
    // Remove scrolling from document Overview and go to top of page
    const scrollTop = () => {
      window.document.getElementById('#header').scrollIntoView()
    }
    scrollTop()
  }, [])

  /**
   * TAG & PERMISSION HANDLING
   */

  /* Assign/Unassign Tags */
  const setTags = (tags) => {
    setOldTags(tags)
    setSelectedTags(tags)
  }

  const getUnassignedTags = () => {
    return oldTags.filter(id => !selectedTags.includes(id))
  }

  const getAssignedTags = () => {
    return selectedTags.filter(id => !oldTags.includes(id))
  }

  /* Updates the tags to current selection */
  const handleTagChange = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(stag => stag !== tag))
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  const handlePermissionChange = async (permission, isCheckedStatus) => {
    setLoadingPermission(true)
    const API = Permission({})
    if(document.id){
      if(isCheckedStatus){
        await API.assignDocument(document.id, permission)
      }
      else{
        await API.unassignDocument(document.id, permission)
      }
    }
    if(isCheckedStatus){
      setPermissionsToCreate([...permissionsToAssign, permission])
    }
    else{
      setSelectedPermissions(permissionsToAssign.filter(perm => perm !== permission))
    }
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions([...selectedPermissions, permission])
    }
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    if(document.id){
      await delay(500)
    }
    setLoadingPermission(false)
  }

  /* Modals & Notifications */

  const closeModal = () => {
    setStatus({ ...status, success: false })
    setNewTag(false)
  }

  /**
   * EVENT HANDLERS
   */
  const handleDropzoneChange = async (field, acceptedFiles) => {
    try {
      const file = acceptedFiles[0]
      setDocument({
        ...document,
        [field]: file
      })
    } catch (err) {
      console.error(err)
    }
    // check which field is filled to remove preview
    if (field === 'file') {
      setDisplay({ ...display, file: false })
    } else {
      setDisplay({ ...display, thumbnail: false })
      setThumbnail('')
    }
  }

  const handleSwitchChange = (event, field) => {
    setDocument({
      ...document,
      [field]: event.target.checked
    })
  }

  const handleChange = (event, field) => {
    setDocument({
      ...document,
      [field]: event.target.value
    })
  }


  useEffect(() => {
    if (mail.to && mail.to !== '') {
      setRequiredMailFormFieldsFilled(false)
      return
    }
    if (originalDocument.title_de && originalDocument.title_de !== '') {
      if (!(mail.text_de && mail.text_de !== '' && mail.subject_de && mail.subject_de !== '')) {
        setRequiredMailFormFieldsFilled(false)
        return
      }
    }
    if (originalDocument.title_en && originalDocument.title_en !== '') {
      if (!(mail.text_en && mail.text_en !== '' && mail.subject_en && mail.subject_en !== '')) {
        setRequiredMailFormFieldsFilled(false)
        return
      }
    }
    setRequiredMailFormFieldsFilled(true)
  }, [mail, originalDocument])

  const handleMailChange = (event, field) => {
    setMail({
      ...mail,
      [field]: event.target.value
    })
  }

  const toggleMarkAsNew = ({ target }) => {
    setIsNew(target.checked)
  }

  const goToDocs = () => {
    window.location.hash = '#/documents'
  }

  useEffect(() => {
    if (!newTag) {
      setRefresh(true)
    }
  }, [newTag])

  const refreshTagList = () => {
    setRefresh(!refresh)
  }

  if (!document.internalNote) {
    document.internalNote = ''
  }

  const sendMail = async (closeModal) => {
    const API = Documents(document)
    const mailRes = await API.sendMail(mail)

    if (mailRes.error) {
      setErrorNotification(Translation(mailRes.error))
    } else {
      setErrorNotification(undefined)
    }

    if (closeModal) {
      setWasSent(true)
    } else {
      setMail({
        ...mail,
        to: ''
      })
    }
  }

  return (
    <Grid id='#top'>
      {status.success && status.create &&
        <Notification type='create' title='Dokument erfolgreich angelegt' close={e => goToDocs()}>
          <Grid item xs={6}>
            <Button to='/documents'>Zurück zur Übersicht</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/documents/create' onClick={e => window.location.reload()}>Neues Dokument anlegen</Button>
          </Grid>
        </Notification>}
      {status.success && status.update &&
        <Notification type='success' title='Änderungen wurden erfolgreich übernommen' close={e => goToDocs()}>
          <Grid item xs={12}>
            <Button to='/documents'>Ok</Button>
          </Grid>
        </Notification>}
      {status.mail && status.update &&
        <Notification type='mail' title={wasSent ? '' : 'Info-Mail erstellen'} close={e => setStatus({ ...status, mail: false })}>
          {errorNotification && errorNotification !== '' &&
            <Grid item xs={12}>
              <Notification type='error' message={errorNotification} />
              <Spacer />
            </Grid>}
          {!wasSent &&
            <>
              <Grid item xs={6}>
                <TextInput
                  title='Empfänger für Test-Mail'
                  type='email'
                  value={mail.to}
                  onChange={e => handleMailChange(e, 'to')}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonWithTitleMargin onClick={e => sendMail(false)}>Test-Mail senden</ButtonWithTitleMargin>
              </Grid>
              <MailContentArea item xs={12}>
                <Wrapper>
                  <TabSelection>
                    {!!originalDocument.title_de &&
                      <TabButton key='tabtitle_de' onClick={() => setActiveTab('de')} className={(activeTab === 'de' ? 'active' : '')}><Flag flag='de' /> Deutsch</TabButton>}
                    {!!originalDocument.title_en &&
                      <TabButton key='tabtitle_en' onClick={() => setActiveTab('en')} className={(activeTab === 'en' ? 'active' : '')}><Flag flag='en' /> Englisch</TabButton>}
                  </TabSelection>
                  <TabContent>
                    <Tab key='tabcontent_de' active={activeTab === 'de'}>
                      <Grid item xs={12}>
                        <TextInput
                          title='Betreff'
                          value={mail.subject_de}
                          onChange={e => handleMailChange(e, 'subject_de')}
                        />
                      </Grid>
                      <Spacer height='15px' />
                      <Grid item xs={12}>
                        <TextArea
                          title='E-Mail Inhalt'
                          value={mail.text_de}
                          onChange={e => handleMailChange(e, 'text_de')}
                        />
                      </Grid>
                    </Tab>
                    <Tab key='tabcontent_en' active={activeTab === 'en'}>
                      <Grid item xs={12}>
                        <TextInput
                          title='Betreff'
                          value={mail.subject_en}
                          onChange={e => handleMailChange(e, 'subject_en')}
                        />
                      </Grid>
                      <Spacer height='15px' />
                      <Grid item xs={12}>
                        <TextArea
                          title='E-Mail Inhalt'
                          value={mail.text_en}
                          onChange={e => handleMailChange(e, 'text_en')}
                        />
                      </Grid>
                    </Tab>
                  </TabContent>
                </Wrapper>
              </MailContentArea>
              <Grid item xs={6}>
                <Button type='secondary' onClick={e => setStatus({ ...status, mail: false })}>Abbrechen</Button>
              </Grid>
              <Grid item xs={6}>
                <SendMailButton disabled={!requiredMailFormFieldsFilled} onClick={e => sendMail(true)}>Info-Mail senden</SendMailButton>
              </Grid>
              <Spacer height='30px' />
            </>}
          {wasSent &&
            <>
              <Spacer height='30px' />
              <MailSuccessMessageContainer item xs={12}>
                Ihre E-Mail wurde an unser System übermittelt und wird nun verarbeitet.
              </MailSuccessMessageContainer>
              <Spacer height='30px' />
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button onClick={e => window.location.reload()}>Ok</Button>
              </Grid>
            </>}
        </Notification>}
      {loading &&
        <Notification type='upload' title='Datei wird hochgeladen' message='Einen Moment bitte...'>
          <LoadIndicator small />
        </Notification>}
      {loadingPermission &&
        <Notification type='upload' title='Berechtigungen werden aktualisiert' message='Einen Moment bitte...'>
          <LoadIndicator small />
        </Notification>}
      <Spacer height='40px' />
      {status.create &&
        <h1>Neues Dokument anlegen</h1>}
      {status.update &&
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <h1>Dokument bearbeiten</h1>
          </Grid>
          <Grid item xs={6}>
            <NewButton title='Info-Mail erstellen' icon='mail' onClick={e => setStatus({ ...status, mail: true })} />
          </Grid>
        </Grid>}
      <Spacer height='30px' />
      <Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <p>
              Hier können Sie das Dokument auf die Fläche droppen lassen oder per Klick auf die
              Fläche vom Computer auswählen
            </p>
            <Spacer height='15px' />
            <Dropzone handleDropzoneChange={handleDropzoneChange} target='file' />
            {display.file &&
              <StyledFilenameBox>
                <FileHeader>Hochgeladene Datei</FileHeader>
                <Filename>{document.filename}</Filename>
              </StyledFilenameBox>}
          </Grid>
          <Grid item xs={6}>
            <p>
              Hier können Sie das Vorschaubild für das Dokument auf die Fläche droppen lassen oder per Klick auf die Fläche vom Computer auswählen
            </p>
            <Spacer height='15px' />
            <Dropzone handleDropzoneChange={handleDropzoneChange} target='thumbnail' icon='image' thumbnail={thumbnail} />
            {display.thumbnail &&
              <StyledFilenameBox>
                {!document.filePreviewPreviewGenerated &&
                  <FileHeader>Generiertes Vorschaubild</FileHeader>}
                {document.filePreviewPreviewGenerated &&
                  <FileHeader>Hochgeladenes Vorschaubild</FileHeader>}
                <Filename>{document.filenamePreview}</Filename>

              </StyledFilenameBox>}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Spacer height='30px' />
            <h3>Titel für Dokument vergeben (max. 35 Zeichen)</h3>
            <Spacer height='20px' />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Flag flag='de' />
            <TextInput
              title='Deutschen Titel hinzufügen'
              value={document.title_de}
              onChange={e => handleChange(e, 'title_de')}
            />
          </Grid>
          <Grid item xs={6}>
            <Flag flag='en' />
            <TextInput
              title='Englischen Titel hinzufügen'
              value={document.title_en}
              onChange={e => handleChange(e, 'title_en')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Spacer height='30px' />
            <h3>Interne Bemerkung</h3>
            <Spacer height='20px' />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Flag flag='de' />
            <TextArea
              title='Deutsche Bemerkung hinzufügen'
              value={document.note_de}
              onChange={e => handleChange(e, 'note_de')}
            />
          </Grid>
          <Grid item xs={6}>
            <Flag flag='en' />
            <TextArea
              title='Englische Bemerkung hinzufügen'
              value={document.note_en}
              onChange={e => handleChange(e, 'note_en')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Spacer height='20px' />
            <h3>Einstellungen zur Datei</h3>
            <Spacer height='20px' />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Switch
              onChange={e => handleSwitchChange(e, 'channelapp')}
              checked={document.channelapp}
              label='Sichtbar in SalesApp'
            />
            <Switch
              onChange={e => handleSwitchChange(e, 'channelportal')}
              checked={document.channelportal}
              label='Sichtbar im Händlerportal'
            />
          </Grid>
          <Grid item xs={6}>
            <Switch
              onChange={e => handleSwitchChange(e, 'shareable')}
              checked={document.shareable}
              label='Teilen aktivieren'
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Spacer height='20px' />
            <h3>Neu-Markierung der Datei</h3>
            <Spacer height='20px' />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Switch
              onChange={toggleMarkAsNew}
              checked={isNew}
              label='Als neu markieren'
            />
          </Grid>
        </Grid>
        <Spacer />
        <Grid container>
          <Grid item xs={6}>
            <h3>Tag Zuweisung</h3>
          </Grid>
          <Grid item xs={6}>
            <NewButton onClick={e => setNewTag(!newTag)} title='Neuen Tag anlegen' icon='tag' />
          </Grid>
          {newTag &&
            <TagPopUp close={e => closeModal()} />}
        </Grid>
        <TagSelection onChange={handleTagChange} defaultTags={selectedTags} tagTypes={allTagTypes} refreshTagList={refreshTagList} refresh={refresh} />
        <Spacer />
        <h3>Berechtigungen</h3>
        <Spacer height='20px' />
        <PermissionSelection defaultPermissions={oldPermissions} onChange={handlePermissionChange}/>
        <Spacer height='20px' />
        {errorNotification !== '' &&
          <div>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </div>}
        <Button onClick={e => submit()}>
          {status.create &&
            <>Dokument erstellen</>}
          {status.update &&
            <>Dokument aktualisieren</>}
        </Button>
        <Spacer />
      </Grid>
    </Grid>
  )
}

const StyledFilenameBox = styled.div`
  background-color: grey;
  min-height: 59px;
  padding-bottom: 5px;
`

const FileHeader = styled.h4`
  position: relative;
  text-align:center;
  padding-top: 10px
`

const Filename = styled.p`
position: relative;
  text-align:center;
  padding-top: 5px
`

export default DocumentEdit
