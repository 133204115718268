import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import OverviewTableItem from './OverviewTableItem'

const StyledTable = styled.div`
  table {
    margin-top: 5px;
    border-spacing: 0;
    border: none;
    width: 100%;
    table-layout: fixed;
    thead {
      tr {
        th {
          width: 25%
          border-bottom: 1px solid #979797;
          text-align: left;
          padding: 7px 0px 5px 15px;
          font-family: "Open Sans SemiBold";
          color: #30393F;
          cursor: pointer;
        }
      }
    }
    tbody {
      tr {
        :nth-child(odd) {
          background-color: #E7F7FF;
        }
        td {
          font-family: "Open Sans";
          line-height: 40px;
          height: 40px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      font-size: 14px;
      line-height: 28px;
      vertical-align:top;
      box-sizing: border-box;
      padding: 0px 0px 0px 15px;
    }
  }
  .pagination {
    display: none;
  }
`

const tableHeadTranslation = {
  company: 'Firma',
  firstname: 'Vorname',
  lastname: 'Zuname',
  createdAt: 'Erstelldatum'
}

const OverviewTable = ({ users, options }) => {
  const [sortedBy, setSortedBy] = useState({})
  const [sortAscending, setSortAscending] = useState(true)

  const sortUsers = (users, property) => {
    if (sortedBy[property]) {
      users.reverse()
      setSortAscending(!sortAscending)
    } else {
      setSortAscending(true)
      if (property === 'firstname' || property === 'lastname' || property === 'company') {
        users.sort(function (a, b) {
          var valueA = a[property].toLowerCase(); var valueB = b[property].toLowerCase()
          if (valueA < valueB) { return -1 }
          if (valueA > valueB) { return 1 }
          return 0
        })
      } else if (property === 'createdAt') {
        users.sort(function (a, b) {
          var dateA = new Date(a[property]); var dateB = new Date(b[property])
          return dateA - dateB
        })
      }
    }
    setSortedBy({ [property]: true })
  }

  const checkSortedBy = async () => {
    if (!sortedBy.keys) {
      users.sort(function (a, b) {
        var dateA = new Date(a.createdAt); var dateB = new Date(b.createdAt)
        return dateB - dateA
      })
      setSortedBy({ createdAt: true })
      setSortAscending(false)
    }
  }

  useEffect(() => {
    checkSortedBy()
    // eslint-disable-next-line
  }, [])

  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            {Object.keys(tableHeadTranslation).map(key => (
              <th key={`tableheader_${key}`} onClick={e => sortUsers(users, key)}>{`${tableHeadTranslation[key]} ${sortedBy[key] ? sortAscending ? '⏶' : '⏷' : ''}`}</th>
            ))}
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {users.slice(0, options.pageSize).map((user, index) => {
            return (
              <OverviewTableItem key={`user-${user.id}-${index}`} user={user} />
            )
          })}
        </tbody>
      </table>
    </StyledTable>
  )
}

OverviewTable.propTypes = {
  users: PropTypes.any,
  options: PropTypes.any
}

export default OverviewTable
