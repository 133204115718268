import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'

import Translation from '../common/BasicTranslation'
import Modal from './NotificationModal'
import Button from '../common/Button'
import Permissions, { Permission } from '../common/ApiHandler/Permission'
import NewButton from '../common/LinkButton'
import Documents from '../common/ApiHandler/Document'

import Notification from '../common/GeneralNotification'

import ModalConfirm from '../common/GeneralNotification.js'

import Checkbox from '../common/FormElements/Checkbox'
import ArrorRight from '../../assets/svg/PfeilRechts'
import ArrorDown from '../../assets/svg/PfeilUnten'

const BlueText = styled.button`
  color: #69BED0;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  padding-left: 23px;
  background-color: transparent;
  margin-left: -20px;
  font-family: Open Sans Light;
`

const LargePlus = styled.button`
  color: #69BED0;
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  text-decoration: none;
  margin-left: -5px;
  font-family: Open Sans Light;
`

const StyledAdd = styled.div`
  margin-left: 30px;
  margin-top: 20px;
`

const StyledArrorDown = styled(ArrorDown)`
  float: left;
`

const StyledName = styled.div`
  padding-left: 27px;
  margin-top: -1px;
  cursor: pointer;

`

const IconWrapper = styled.div`
  content-align: left;
  float: left;
  padding-right: 10px;
`

const StyledAbort = styled(Button)`
  margin-top: -10px;
  background-color: #A8ADB0;
  height: 40px;
`

const StyledConfirm = styled(Button)`
  height: 40px;
`

const HiddenImage = styled.div`
  float: left;
  overflow: hidden;
  display: ${props => !props.childless ? 'inline-block' : 'none'};
`

const NestedDiv = styled.div`
  background: #FFF;
  display: ${props => props.active ? 'block' : 'none'};
  margin-left: ${props => props.active && props.display === 'true' ? '30px' : '40px'};
  margin-top: ${props => props.active && props.display === 'true' ? '-14px' : '40px'};
`

const StyledCheckboxWrapper = styled.div`
  margin-bottom: -10px;
`

const StyledDisplayArrorRight = styled(ArrorRight)`
  margin-left: 4px;
`

const StyledDisplayArrorDown = styled(ArrorDown)`
  margin-left: 4px;
`

const Comment = ({ comment, toggleSelected, isChecked }) => {
  const [active, setActive] = useState(false)
  const [childless, setChildless] = useState(false)
  const [errorNotification, setErrorNotification] = useState('')
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [status, setStatus] = useState({
    drop: false,
    create: false,
    display: false,
    delete: false
  })
  const [permission, setPermission] = useState({
    title: '',
    parentId: ''
  })
  const [assignedDocLength, setAssignedDocLength] = useState(0)

  const checkLocation = () => {
    let currentLocation = window.location.toString()
    const index = currentLocation.lastIndexOf('/') + 1
    currentLocation = currentLocation.slice(index, currentLocation.length)
    if (currentLocation === 'permissions') {
      setStatus({ ...status, display: true })
    }
  }

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus({ ...status, success: false, create: false, update: false, delete: false, warning: false })
  }

  const submit = async () => {
    permission.parentId = comment.id
    let res = 'false'
    const API = Permissions(permission)
    res = await API.create(permission)
    if (typeof res !== 'object') {
      setErrorNotification(Translation(res))
    }
    closeModal()
    window.location.reload()
  }

  const updatePermissionTitle = async () => {
    let res = false
    const API = Permission(permission)
    res = await API.set()
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
    closeModal()
    window.location.reload()
  }

  const enterUpdateState = () => {
    setStatus({ ...status, update: true })
  }

  const add = async (id) => {
    if (selectedPermissions.includes(id)) {
      return
    }
    const updatedPermissions = [...selectedPermissions]
    updatedPermissions.push(id)
    await setSelectedPermissions(updatedPermissions)
  }

  const remove = async (id) => {
    const updatedPermissions = selectedPermissions.filter(item => item !== id)
    await setSelectedPermissions(updatedPermissions)
  }

  const handleChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  const handleUpdateChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  const addPermission = () => {
    setPermission({
      ...permission,
      title: ''
    })
    setStatus({ ...status, create: true })
  }

  const toggleNested = () => {
    setActive(!active)
    if (status.drop === true) {
      setStatus({ ...status, drop: false })
    } else {
      setStatus({ ...status, drop: true })
    }
  }

  let nestedComments = null

  if (comment) {
    nestedComments = (comment.children || []).map(comment => {
      return <Comment key={comment.id} comment={comment} toggleSelected={toggleSelected} type='child' />
    })
  }

  const handleCheckbox = (id, checked) => {
    toggleSelected({ id })
    if (checked) {
      add(id)
      return checked
    } else {
      remove(id)
      return checked
    }
  }

  const getAssignedDocs = async (permission) => {
    const DocApi = Documents({})
    const documentList = await DocApi.search({ permissions: [permission.id] })
    return documentList
  }
  const toggleDelete = async () => {
    const assignedDocs = await getAssignedDocs(permission)
    if (assignedDocs.length < 1) {
      setStatus({ ...status, delete: true, update: false })
    } else {
      setAssignedDocLength(assignedDocs.length)
      setStatus({ ...status, update: false, warning: true })
    }
  }

  const deletePermission = async () => {
    let res = false
    const API = Permission(comment)
    res = await API.delete()
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
    closeModal()
    window.location.reload()
  }

  const setPermissionToComment = () => {
    setPermission(
      comment
    )
  }

  // const isChecked = selectedPermissions.find(search => search === comment.id) !== undefined

  useEffect(() => {
    checkLocation()
    setPermissionToComment()
    if (!comment.children || (comment.children[0] === undefined && childless === false)) {
      setChildless(true)
    }
    // eslint-disable-next-line
    }, [])

  // const isChecked = defaultPermissions.find(search => search === comment.id) !== undefined

  return (
    <Grid>
      {status.update &&
        <Modal title='Berechtigung bearbeiten oder löschen' close={e => closeModal()} boxtitle='Berechtigungstitel' handleChange={e => handleUpdateChange(e, 'title')} value={permission.title}>
          <Grid item xs={3}>
            <NewButton icon='delete' onClick={e => toggleDelete()} title='Löschen' />
          </Grid>
          <Grid item xs={3}>
            <NewButton icon='docSearch' to={{ pathname: `/documents/${permission.id}` }} title='Dokumente' />
          </Grid>
          <Grid item xs={3}>
            <NewButton icon='userSearch' to={{ pathname: `/users/${permission.id}` }} title='Benutzer' />
          </Grid>
          <Grid item xs={3}>
            <StyledConfirm onClick={() => updatePermissionTitle()}>Änderung Speichern</StyledConfirm>
          </Grid>

        </Modal>}
      {status.delete &&
        <ModalConfirm message={`Sind Sie sich sicher, dass Sie die Berechtigung ${permission.title} löschen möchten?`} type='delete' close={e => closeModal()} title='Berechtigung löschen'>
          <Grid item xs={6}>
            <Button onClick={e => deletePermission()}>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={() => closeModal()}>Nein</Button>
          </Grid>
        </ModalConfirm>}
      {status.warning &&
        <ModalConfirm message={`Sind Sie sich sicher, dass Sie die Berechtigung ${permission.title} löschen möchten? Mit der Berechtigung ${permission.title} sind ${assignedDocLength} Dokumente verknüpft.`} type='delete' close={e => closeModal()} title='Berechtigung löschen'>
          <Grid item xs={6}>
            <Button onClick={e => deletePermission()}>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={() => closeModal()}>Nein</Button>
          </Grid>
        </ModalConfirm>}
      {status.create &&
        <Modal title='Neue Berechtigung erstellen' close={e => closeModal()} boxtitle='Berechtigungstitel' handleChange={e => handleChange(e, 'title')} value={permission.title} parentId={comment.id}>
          <Grid item xs={6}>
            <StyledAbort onClick={() => closeModal()}>Abbrechen</StyledAbort>
          </Grid>
          <Grid item xs={6}>
            <StyledConfirm onClick={() => submit()}>Erstellen</StyledConfirm>
          </Grid>
        </Modal>}
      {status.drop && status.display &&
        <div>
          <Spacer height='25px' />
          <StyledArrorDown onClick={e => toggleNested()} height='14px' width='14px' fill='#003B64' />
          <StyledName onClick={e => enterUpdateState()}>{comment.title}</StyledName>
          <StyledAdd>
            <LargePlus onClick={e => addPermission()}>+</LargePlus>
            <BlueText onClick={e => addPermission()}>Berechtigung hinzufügen</BlueText>
          </StyledAdd>
        </div>}
      {!status.drop && status.display &&
        <div>
          <Spacer height='25px' />
          <HiddenImage onClick={e => toggleNested()}>
            <StyledDisplayArrorRight onClick={e => toggleNested()} height='14px' width='14px' />
          </HiddenImage>
          <StyledName onClick={e => enterUpdateState()}>{comment.title}</StyledName>
        </div>}
      {status.drop && !status.display &&
        <IconWrapper>
          <StyledDisplayArrorDown onClick={e => toggleNested()} height='18px' fill='#003B64' />
        </IconWrapper>}
      {!status.drop && !status.display &&
        <IconWrapper>
          <HiddenImage childless={childless}>
            <ArrorRight onClick={e => toggleNested()} height='18px' fill='#003B64' />
          </HiddenImage>
        </IconWrapper>}
      {!status.display &&
        <StyledCheckboxWrapper childless={childless}>
          <Checkbox onChange={() => handleCheckbox(comment.id, !isChecked)} title={comment.title} value={comment.id} isChecked={isChecked} toggleSelected={toggleSelected} />
        </StyledCheckboxWrapper>}
      <Spacer height='10px' />
      {nestedComments &&
        <NestedDiv active={active} display={status.display.toString()}>{nestedComments}</NestedDiv>}
      {errorNotification !== '' &&
        <div>
          <Notification type='error' message={errorNotification} />
          <Spacer />
        </div>}
    </Grid>
  )
}

export default Comment
