import React from 'react'
import PropTypes from 'prop-types'
import IconEdit from '../../assets/svg/Bearbeiten.js'
import IconCross from '../../assets/svg/Cross.js'
import IconCheck from '../../assets/svg/Haken.js'
import IconDetail from '../../assets/svg/Detailprofil.js'
import IconTrash from '../../assets/svg/Trash.js'
import IconReload from '../../assets/svg/Reload.js'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const baseStyles = `
  width: 18px;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  border: none;
  padding: 0;
  display: inline-block;
  Vertical-align: sub;
`

const BaseActionButton = styled.button`
  ${baseStyles}
`

const LinkActionButton = styled(Link)`
  ${baseStyles}
`

const IconElement = ({ action }) => {
  return (
    <>
      {action === 'edit' &&
        <IconEdit className='iconEdit' height='18px' width='18px' fill='#A8ADB0' />}
      {action === 'enabled' &&
        <IconCheck className='iconCheck' height='18px' width='18px' fill='#00528C' />}
      {action === 'disabled' &&
        <IconCross className='iconCross' height='18px' width='18px' fill='#003B64' />}
      {action === 'detail' &&
        <IconDetail className='iconDetail' height='18px' width='18px' fill='#A8ADB0' />}
      {action === 'delete' &&
        <IconTrash className='iconTrash' height='18px' width='18px' fill='#A8ADB0' />}
      {action === 'reload' &&
        <IconReload className='reload' height='18px' width='18px' fill='#A8ADB0' />}
    </>
  )
}

const ActionButton = ({ action, to = '', ...restProps }) => {
  return (
    <>
      {to === '' &&
        <BaseActionButton {...restProps}>
          <IconElement action={action} />
        </BaseActionButton>}
      {to !== '' &&
        <LinkActionButton to={to} {...restProps}>
          <IconElement action={action} />
        </LinkActionButton>}
    </>

  )
}

IconElement.propTypes = {
  action: PropTypes.string
}

ActionButton.propTypes = {
  action: PropTypes.string,
  isLink: PropTypes.bool
}

export default ActionButton
