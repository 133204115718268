import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.div`
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 60px;
  border: 1.5px solid;
  border-color: ${props => props.active ? '#134980' : '#CBCBCB'};
  background-color: ${props => props.active ? '#134980' : 'transparent'};
  line-height: 37px;
  color: ${props => props.active ? 'white' : 'black'};
  text-align: center;
  clear: left;
  cursor: pointer;
  font-size: 14px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  position: relative;
  text-decoration:none;
  font-family: "Open Sans Bold";
  float: left;
`

export default function OtpMaxDeviceButton ({ limit, currentLimit, ...props }) {
  return (
    <StyledLink active={limit === currentLimit} {...props}>{limit}</StyledLink>
  )
}
