import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import ShowOtp from './Otp/ShowOtp'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '80'
  },
  content: {
    top: '15%',
    zIndex: '90'
  }
}

const StyledModal = styled(Modal)`
  max-width: 650px;
  height: 250px;
  margin: 0 auto;
  background: #FFF;
  border: 1px solid black;
  position: relative;
  border-radius: 10px;
`

const OTPModal = ({ title = '', message = '', close, pw }) => {
  Modal.setAppElement('#root')

  return (
    <StyledModal
      onRequestClose={close}
      shouldCloseOnOverlayClick
      isOpen
      style={customStyles}
    >
      <ShowOtp title={title} message={message} otp={pw} onClose={close} />
    </StyledModal>
  )
}

OTPModal.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.any,
  children: PropTypes.any
}

export default OTPModal
