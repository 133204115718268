import React from 'react'

const SVGDocSearch = props => (
  <svg viewBox='0 0 17 17' {...props}>
    <defs>
      <path
        id='down_svg__a'
        d='M13.333 9.167V15H1.667V3.333H5.85c.042-.591.183-1.15.4-1.666H1.667C.75 1.667 0 2.417 0 3.333V15c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667v-4.167l-1.667-1.666zm-1.25 4.166H2.917l2.291-2.941 1.634 1.966 2.291-2.95 2.95 3.925zm2.334-7.591A3.717 3.717 0 0015 3.75 3.745 3.745 0 0011.25 0 3.745 3.745 0 007.5 3.75a3.743 3.743 0 003.742 3.75c.733 0 1.416-.217 1.991-.583l2.6 2.6 1.184-1.184-2.6-2.591zm-3.167.091a2.084 2.084 0 11.002-4.168 2.084 2.084 0 01-.002 4.168z'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#down_svg__a'

      fillRule='evenodd'
    />
  </svg>
)

export default SVGDocSearch
