import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

const StyledSelect = styled(Select)`
  > div {
    border-radius: 0 !important;
    svg {
      fill: #003B64;
    }
  }
  span {
    display: none;
  }
  .selectbox__control {
    min-height: 40px;
    outline: none;
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
    border: none;
    &.selectbox__control--is-focused {
      box-shadow: none;
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.2);
      border: 1px solid #00528C;
    }
  }
  .selectbox__value-container {
  }
  .selectbox__indicators {
  }
  .selectbox__menu {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
    .selectbox__option {
      &.selectbox__option--is-focused {
        background-color: rgba(0,82,140,0.1);
      }
      &.selectbox__option--is-selected {
        background-color: #00528C
      }
    }
  }
  .selectbox__menu-list {
      max-height: 450px !important;
    }
`

const ReaSelect = (props) => {
  return (
    <StyledSelect classNamePrefix='selectbox' className='selectbox-container' {...props} />
  )
}

export default ReaSelect
