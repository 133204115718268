import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import Button from '../common/Button'
import Admins from '../common/ApiHandler/Admin'
import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import AdminForm from './FormFields'

const AdminEdit = ({ match, ...props }) => {
  const [admin, setAdmin] = useState({
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    password: ''
  })

  const [errorNotification, setErrorNotification] = useState('')

  /* Default: Create Mode */
  const [status, setStatus] = useState({
    create: true,
    update: false,
    success: false
  })

  /* Switch to update mode and receive data */
  useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus({ ...status, update: true, create: false })
      loadData()
    }
    // eslint-disable-next-line
    }, [match])

  /* Load Data for Update */
  const loadData = async () => {
    const currentAdmin = Admins({ id: match.params.id })
    const foundAdmin = await currentAdmin.findOne()
    setAdmin(foundAdmin)
  }

  const submit = async () => {
    /* remove tags from admin object to prevent errors */
    let res = false
    if (!admin.id) {
      const API = Admins(admin)
      res = await API.create()
    } else {
      const updatedAdmin = { ...admin }
      delete updatedAdmin.referenceid
      res = await updatedAdmin.save()
    }
    if (typeof res === 'object') {
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
  }

  /* Modals & Notifications */
  const goToAdmins = () => {
    window.location.hash = '#/admins'
  }
  return (
    <Grid>
      {status.success && status.create &&
        <Notification type='create' title='Administrator erfolgreich angelegt' close={e => goToAdmins()}>
          <Grid item xs={6}>
            <Button to='/admins'>Zurück zur Übersicht</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/admins/create'>Neuen Administartor anlegen</Button>
          </Grid>
        </Notification>}
      {status.success && status.update &&
        <Notification type='success' title='Änderungen wurden erfolgreich übernommen' close={e => goToAdmins()}>
          <Grid item xs={12}>
            <Button to='/admins'>Ok</Button>
          </Grid>
        </Notification>}
      <Spacer height='40px' />
      {status.create &&
        <h1>Neuen Administrator anlegen</h1>}
      {status.update &&
        <h1>Administrator bearbeiten</h1>}
      <Spacer height='30px' />
      <AdminForm admin={admin} setAdmin={setAdmin}>
        <Spacer />
        <Spacer height='20px' />
        {errorNotification !== '' &&
          <div>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </div>}
        <Button onClick={e => submit()}>
          {status.create &&
            <>Administrator erstellen</>}
          {status.update &&
            <>Administrator aktualisieren</>}
        </Button>
      </AdminForm>
    </Grid>
  )
}

export default AdminEdit
