import React from 'react'

const SvgClosed = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <title>closed</title>
    <path
      d='M14 0H2C.9 0 0 .9 0 2v14h2V2h12V0zm3 4H6c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H6V6h11v14z'
      fill='#A8ADB0'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgClosed
