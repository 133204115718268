import Api from './Api'

const api = Api()

const PermissionGroup = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    set: async function () {
      const res = await api.exec(`/permissiongroups/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        return res.error
      }
      return PermissionGroup(res)
    },
    assignPermission: async (id, permissionid) => {
      const res = await api.exec(`/permissiongroups/${id}/permissions/${permissionid}`,
        {
          method: 'POST',
          body: JSON.stringify({
            permissiongroupid: id,
            permissionid: permissionid
          })
        })
      if (res.error) return res.error
      return res
    },
    unassignPermission: async (id, permissionid) => {
      const res = await api.exec(`/permissiongroups/${id}/permissions/${permissionid}`,
        {
          method: 'DELETE',
          body: JSON.stringify({
            permissiongroupid: id,
            permissionid: permissionid
          })
        })
      if (res.error) return res.error
      return res
    },
    assignUser: async (userId, id) => {
      const res = await api.exec(`/permissiongroups/${id}/users/${userId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            permissiongroupid: id,
            userid: userId
          })
        })
      if (res.error) return res.error
      return res
    },
    unassignUser: async (userId, id) => {
      const res = await api.exec(`/permissiongroups/${id}/users/${userId}`,
        {
          method: 'DELETE',
          body: JSON.stringify({
            permissiongroupid: id,
            userid: userId
          })
        })
      if (res.error) return res.error
      return res
    },
    delete: async () => {
      const res = await api.exec(`/permissiongroups/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    }
  }
}

const PermissionGroups = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    create: async (permissiongroup) => {
      const res = await api.exec('/permissiongroups', {
        method: 'POST',
        body: JSON.stringify({
          title: permissiongroup.title
        })
      })
      if (res.error) {
        return res.error
      }
      return PermissionGroup(res.permissionGroup)
    },
    search: async (params) => {
      const res = await api.exec('/permissiongroups', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        return res.error
      }
      if (res.permissionGroups.length < 1) {
        return []
      }
      return res.permissionGroups.map(permissiongroup => PermissionGroup(permissiongroup))
    },
    findOne: async (permissiongroup) => {
      const res = await api.exec(`/permissiongroups/${permissiongroup.id}`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
      return PermissionGroup(res.permissionGroup)
    }
  }
}

export { PermissionGroup }
export default PermissionGroups
