import React, { useEffect, useState, useCallback } from 'react'
import Select from '../common/FormElements/Select'
import TextInput from '../common/FormElements/TextInput'
import SearchButton from '../common/FormElements/SearchButton'
import SearchWrapper from '../common/FormElements/SearchWrapper'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import PropTypes from 'prop-types'
import Options from '../common/ApiHandler/Options'
import Tags from '../common/ApiHandler/Tag'
import Permissions from '../common/ApiHandler/Permission'
import PermissionGroups from '../common/ApiHandler/PermissionGroup'

const RESET_FILTER = 'Filter zurücksetzen'

const DocumentRefine = ({ filter, setFilter }) => {
  const [tags, setTags] = useState({})
  const [permissionOptions, setPermissionOptions] = useState([])
  const [permissionGroupOptions, setPermissionGroupOptions] = useState([])

  const [resetTags, setResetTags] = useState({
    language: false,
    products: false,
    docarts: false,
    draft: false,
    permissions: false,
    permissiongroups: false,
    application: false,
    themes: false
  })

  /* Tag Loader */
  useEffect(() => {
    getAllTags()
  }, [])

  const loadPermissions = useCallback(async () => {
    const API = Permissions({})
    const allPermissions = await API.search()
    createPermissionOptions(allPermissions)
    if (typeof allPermissions !== 'object') {
      console.error(allPermissions)
    }
    // eslint-disable-next-line
  }, [])

  const loadPermissionGroups = useCallback(async () => {
    const permissionGroupSelection = await PermissionGroups({ id: '' })
    const allPermissionGroups = await permissionGroupSelection.search({})
    createPermissionGroupsOptions(allPermissionGroups)
    if (typeof allPermissionGroups !== 'object') {
      console.error(allPermissionGroups)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    loadPermissions()
    loadPermissionGroups()
  }, [loadPermissionGroups, loadPermissions])

  useEffect(() => {
    if (filter.title === '') {
      delete filter.title
    }
  }, [filter.title])

  const selectFilter = (type, filterValue) => {
    const newResetTags = { ...resetTags }
    newResetTags[type] = false
    if (type === 'products' || type === 'docarts' || type === 'themes') {
      const Tags = filter.tags || []
      const groupTags = orderTags(tags, type)

      /* Change or add tag depending if group is already filtered */
      groupTags.forEach((tag) => {
        if (Tags && Tags.includes(tag.value)) {
          Tags.splice(Tags.indexOf(tag.value), 1)
        }
      })
      Tags.push(filterValue)

      /* delete entry in array if all is chosen */
      let index = -1
      if (Tags.includes('resetproducts')) {
        index = Tags.indexOf('resetproducts')
      }
      if (Tags.includes('resetdocarts')) {
        index = Tags.indexOf('resetdocarts')
      }
      if (Tags.includes('resetthemes')) {
        index = Tags.indexOf('resetthemes')
      }
      if (index !== -1) {
        Tags.splice(index, 1)
        newResetTags[type] = true
      }
      setFilter({ ...filter, tags: Tags })
    } else if (type === 'title') {
      setFilter({ ...filter, title: filterValue })
    } else if (type === 'application') {
      const newFilter = { ...filter }
      if (filterValue === 'channelapp') {
        newFilter.channelportal = false
        newFilter.channelapp = true
      } else if (filterValue === 'channelportal') {
        newFilter.channelapp = false
        newFilter.channelportal = true
      } else if (filterValue === 'all') {
        newFilter.channelapp = true
        newFilter.channelportal = true
      } else {
        delete newFilter.channelapp
        delete newFilter.channelportal
        newResetTags[type] = true
      }
      setFilter(newFilter)
    } else {
      const newFilter = { ...filter }
      if (filterValue !== 'reset') {
        if (type === 'permissions' || type === 'permissiongroups') {
          newFilter[type] = [filterValue]
        } else {
          newFilter[type] = filterValue
        }
      } else {
        delete newFilter[type]
        newResetTags[type] = true
      }
      setFilter(newFilter)
    }
    setResetTags({ ...newResetTags })
  }

  const addResetOption = (options, value = 'reset', label = RESET_FILTER) => {
    options.unshift({ value, label })
  }

  const orderTags = (tags, property) => {
    if (tags[property] !== undefined) {
      const sortedTags = tags[property].sort((a, b) => {
        const labelA = a.label.toUpperCase()
        const labelB = b.label.toUpperCase()
        return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
      })
      const resetValue = `reset${property}`
      const tagsWithReset = [...sortedTags]
      if (!tagsWithReset.some((tag) => tag.value === resetValue)) {
        addResetOption(tagsWithReset, resetValue)
      }

      return tagsWithReset
    }
  }

  /* Prepare Filter Tag Selection */
  const getAllTags = async () => {
    const TagHandler = Tags({})
    const allTags = await TagHandler.findAll({})
    const tagStorage = {}
    if (Array.isArray(allTags) && allTags.length > 0) {
      allTags.forEach((tag, index) => {
        const newTag = {
          label: tag.abbreviation,
          value: tag.id
        }
        if (!tagStorage[tag.type]) {
          tagStorage[tag.type] = []
        }
        tagStorage[tag.type].push(newTag)
      })
      setTags(tagStorage)
    }
  }

  const createPermissionOptions = (permissions) => {
    const optionsArray = []
    const childrenDisplay = (permission) => {
      permission.children.map((childPermission) => {
        optionsArray.push({ label: childPermission.title, value: childPermission.id, depth: childPermission.hierarchyLevel - 1 })
        if (childPermission.children && childPermission.children.length > 0) {
          childrenDisplay(childPermission)
        }
        return true
      })
    }
    permissions.map((permission) => {
      optionsArray.push({ label: permission.title, value: permission.id })
      if (permission.children && permission.children.length > 0) {
        childrenDisplay(permission)
      }
      return true
    })
    addResetOption(optionsArray)
    setPermissionOptions(optionsArray)
  }

  const createPermissionGroupsOptions = (permissionGroups) => {
    const optionsArray = []
    permissionGroups.map((permissionGroup) => {
      optionsArray.push({ label: permissionGroup.title, value: permissionGroup.id })
      return true
    })
    addResetOption(optionsArray)
    setPermissionGroupOptions(optionsArray)
  }

  const hierarchySymbols = [
    '',
    '· ',
    '° ',
    '» ',
    '# ',
    '* '
  ]

  const formatOptionLabel = ({ label, depth }) => (
    <div style={{ display: 'flex' }}>
      <div style={{ paddingLeft: `${15 * (depth || 0)}px` }}>
        {`${hierarchySymbols[depth] || ''}${label}`}
      </div>
    </div>
  )

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Spacer />
          <SearchWrapper>
            <TextInput
              onChange={e => selectFilter('title', e.target.value)}
              value={filter.title}
              placeholder='Suche...'
            />
            {filter.title &&
              <SearchButton active onClick={() => selectFilter('title', '')} />}
          </SearchWrapper>
          <Spacer height='20px' />
          <h3>Filtern nach:</h3>
          <Spacer height='10px' />
        </Grid>
      </Grid>

      <Grid container spacing={3} rowSpacing={2}>
        <Grid item xs={3}>
          <Select
            name='languageOption'
            placeholder='Sprache'
            options={Options.languageOptions}
            onChange={e => selectFilter('language', e.value)}
            value={resetTags.language ? null : undefined}
            size={3}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='productOptions'
            placeholder='Produkt'
            options={orderTags(tags, 'products')}
            onChange={e => selectFilter('products', e.value)}
            value={resetTags.products ? null : undefined}
            size={3}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='docartOptions'
            placeholder='Dokumentenart'
            onChange={e => selectFilter('docarts', e.value)}
            options={orderTags(tags, 'docarts')}
            value={resetTags.docarts ? null : undefined}
            size={3}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='statusOptions'
            placeholder='Status'
            onChange={e => selectFilter('draft', e.value)}
            options={Options.statusOptions}
            value={resetTags.draft ? null : undefined}
            size={3}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='permissionOption'
            placeholder='Berechtigung'
            onChange={e => selectFilter('permissions', e.value)}
            options={permissionOptions}
            value={resetTags.permissions ? null : undefined}
            formatOptionLabel={formatOptionLabel}
            size={3}
          />
        </Grid>

        <Grid item xs={3}>
          <Select
            placeholder='Berechtigungsgruppe'
            name='permissionGroupOptions'
            onChange={e => selectFilter('permissiongroups', e.value)}
            options={permissionGroupOptions}
            value={resetTags.permissiongroups ? null : undefined}
            size={3}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='applicationOptions'
            placeholder='Applikation'
            onChange={e => selectFilter('application', e.value)}
            options={Options.applicationOptions}
            value={resetTags.application ? null : undefined}
            size={3}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='themesOptions'
            placeholder='Thema'
            onChange={e => selectFilter('themes', e.value)}
            options={orderTags(tags, 'themes')}
            value={resetTags.themes ? null : undefined}
            size={3}
          />
        </Grid>
      </Grid>
    </>
  )
}

DocumentRefine.propTypes = {
  onChange: PropTypes.func
}

export default DocumentRefine
