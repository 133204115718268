import React, { useState, useEffect, useCallback } from 'react'
import { User } from '../../common/ApiHandler/User'
import DeviceList from './List'

const byDateProperty =
  (propertyName, direction = 'desc') =>
    (a, b) => {
      const sortDirection = direction === 'desc' ? 1 : -1
      const aPropertyValue = a[propertyName]
      const bPropertyValue = b[propertyName]
      const aTime = new Date(aPropertyValue).getTime()
      const bTime = new Date(bPropertyValue).getTime()
      return bTime - aTime * sortDirection
    }

export default function DeviceSidebar ({ match }) {
  const { userid } = match.params

  const [devices, setDevices] = useState([])
  const [mails, setMails] = useState([])

  const getDevices = useCallback(async () => {
    if (!userid) {
      return setDevices([])
    }
    const { devices } = await User({ id: userid }).getDevices({ userid })
    const devicesWithInfo = devices.map(device => ({
      ...device,
      info: JSON.parse(device.info),
      serviceInfo: JSON.parse(device.serviceInfo)
    }))
    setDevices(devicesWithInfo)
  }, [userid])

  const RELOAD_INTERVAL = 5000

  const getMails = useCallback(async () => {
    if (!userid) {
      return setMails([])
    }
    const { emails: authflowEmails } = await User({ id: userid }).getMails({ type: 'authflow' })
    const { emails: appOtpEmails } = await User({ id: userid }).getMails({ type: 'appOtp' })
    const sortedMails = [...authflowEmails, ...appOtpEmails].sort(byDateProperty('createdAt', 'desc'))
    setMails(sortedMails)
  }, [userid])

  useEffect(() => {
    getDevices()
  }, [getDevices])

  useEffect(() => {
    getMails()
  }, [getMails])

  const reloadMailsInterval = useCallback(async () => {
    if (mails && mails.length && mails[0].status === 'pending') {
      const { emails } = await User({ id: userid }).getMails()
      setMails(emails)
    }
  }, [mails, userid])

  useEffect(() => {
    const intervalId = mails && mails.length && mails[0].status === 'pending'
      ? setInterval(() => {
        reloadMailsInterval()
      }, RELOAD_INTERVAL)
      : null

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [mails, reloadMailsInterval])

  const removeDevice = async (device, appType) => {
    const res = await User({ id: userid }).destroyDevice({ hardwareId: device.hardwareId, appType })
    if (typeof (res) === 'object') {
      getDevices()
    }
  }

  return (<DeviceList devices={devices} onRemove={removeDevice} mails={mails} />)
}
