import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import deFlag from '../../assets/images/de.png'
import enFlag from '../../assets/images/en.png'
import globe from '../../assets/svg/Weltkugel'

const FlagWrapper = styled.div`
  float: left;
  padding-right: 10px;
`

const StyledFlag = styled.img`
  height: 17px;
  width: 28px;
`

const StyledGlobe = styled(globe)`
    height: 23px;
    width: 23px;
    margin-top: -3px
  `

const Flag = ({ flag }) => {
  return (
    <FlagWrapper>
      {flag === 'de' &&
        <StyledFlag src={deFlag} alt='Deutsche Flagge' />}
      {flag === 'en' &&
        <StyledFlag src={enFlag} alt='Englische Flagge' />}
      {flag === 'globe' &&
        <StyledGlobe alt='Weltkugel' fill='#C0BBAE' />}
    </FlagWrapper>
  )
}

Flag.propTypes = {
  flag: PropTypes.string
}

export default Flag
