import React, { useState } from 'react'
import LoginInput from './LoginInput'
import styled from 'styled-components'
import Admin from '../common/ApiHandler/Admin'
import Translation from '../common/BasicTranslation'
import Notification from '../common/GeneralNotification'
import Spacer from '../common/Spacer'

const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

const Background = styled.div`
  height: ${screenHeight};
  width: ${screenWidth};
  maring: 0 auto;
  backgournd: green;
  position: relative;
`

const LoginForm = styled.form`
  display: block;
  width: 400px;
  height: 400px;
  position: absolute;
  left: 50%;
  margin-left: -200px;
  margin-top: 55px;
  text-align: center;
`

const StyledH1 = styled.h1`
  text-align: center;
  margin-bottom: 35px;
  color: #FFF
`

const Button = styled.button`
  height: 34px;
  width: 400px;
  border-radius: 17px;
  background-color: rgba(255,255,255,0.8);
  border: none;
  font-size: 14px;
  letter-spacing: 0.55px;
  line-height: 16px;
`

const Login = (props) => {
  const [admin, setAdmin] = useState({
    email: '',
    password: ''
  })

  const handleChange = (event, field) => {
    setAdmin({
      ...admin,
      [field]: event.target.value
    })
  }
  const [errorNotification, setErrorNotification] = useState('')

  const submit = async (e) => {
    e.preventDefault()
    const AdminHandler = Admin({ id: false })
    const result = await AdminHandler.authorize(admin)

    setErrorNotification(Translation(result))
    return result
  }

  return (
    <Background>
      <LoginForm onSubmit={(e) => submit(e)}>
        <StyledH1>Willkommen</StyledH1>
        <LoginInput onChange={(e) => handleChange(e, 'email')} type='email' placeholder='Geben Sie hier bitte Ihre Email-Adresse ein' label='EMAIL-ADRESSE' />
        <LoginInput onChange={(e) => handleChange(e, 'password')} type='password' label='PASSWORT' placeholder='***********' />
        {errorNotification !== '' &&
          <div>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </div>}
        <Button type='login'>Login</Button>
      </LoginForm>
    </Background>
  )
}

export default Login
