import React, { useEffect, useState, useRef } from 'react'
import Grid from '@mui/material/Grid'
import SearchButton from '../common/FormElements/SearchButton'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'
import SearchWrapper from '../common/FormElements/SearchWrapper'
import PropTypes from 'prop-types'
import TagSearchItem from './TagSearchItem'

const OverviewRefine = ({ tags, setFilteredTags, filteredTags, reload, onChange, defaultTags }) => {
  const [search, setSearch] = useState('')
  const [allTags, setAllTags] = useState([])
  const [searchTags, setSearchTags] = useState([])

  const setSearchInput = (event) => {
    if (event !== '') {
      setSearch(event.target.value)
      submitSearch(event.target.value)
    } else {
      setSearch('')
      submitSearch('')
    }
  }

  const getAllTags = () => {
    const allTagsArray = []
    for (var key in tags) {
      tags[key].forEach(tag => {
        allTagsArray.push(tag)
      })
    }
    setAllTags(allTagsArray)
  }

  const reorderFilteredTags = (array) => {
    // recreate tags object
    const keys = []
    for (var key in tags) keys.push(key)
    const objectFilteredTags = {}
    keys.forEach(key => {
      objectFilteredTags[key] = []
    })

    array.forEach(tag => {
      objectFilteredTags[tag.type].push(tag)
    })
    return objectFilteredTags
  }

  useEffect(() => {
    getAllTags()
    setFilteredTags(tags)
    // eslint-disable-next-line
  }, [tags])

  const submitSearch = (searchString) => {
    let result = []
    const insensitiveSearch = searchString.toLowerCase()
    allTags.forEach(tag => {
      const tagSearchValue = tag.title_de.toLowerCase()
      if (tagSearchValue.includes(insensitiveSearch)) {
        result.push(tag)
      }
      return result
    })
    // only show first 15 results
    result = result.slice(0, 15)
    setSearchTags(result)
    setFilteredTags(reorderFilteredTags(result))
  }

  const resetSearch = () => {
    setSearchInput('')
    setSearchTags([])
  }

  const node = useRef()

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setSearchTags([])
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <Grid ref={node}>
      <Spacer />
      <SearchWrapper>
        <TextInput
          onChange={e => setSearchInput(e)}
          value={search}
          placeholder='Suche'
        />
        {search === '' &&
          <SearchButton />}
        {search !== '' &&
          <SearchButton active onClick={() => resetSearch()} />}
      </SearchWrapper>
      <TagSearchItem searchTags={searchTags} filteredTags={filteredTags} onChange={onChange} defaultTags={defaultTags} />
    </Grid>
  )
}

OverviewRefine.propTypes = {
  users: PropTypes.any,
  setUsers: PropTypes.any,
  reload: PropTypes.any,
  blur: PropTypes.bool
}

export default OverviewRefine
