import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Spacer from '../common/Spacer'
import Modal from '../common/GeneralNotification'
import UserFormfields from './FormFields'
import Button from '../common/Button'
import Notification from '../common/GeneralNotification'
import Users, { User } from '../common/ApiHandler/User'
import Translation from '../common/BasicTranslation'
import styled from 'styled-components'
import LoadIndicator from '../common/LoadIndicator'
import Permissions, { Permission } from '../common/ApiHandler/Permission'
import PermissionGroups, { PermissionGroup } from '../common/ApiHandler/PermissionGroup'
import PermissionSelection from '../Permissions/PermissionSelection'
import Comment from '../Permissions/Tree'
import LinkButton from '../common/LinkButton'

import OTPModal from './OTPModal'
import UserNotification from './UserNotifications'

const Wrapper = styled.div`
border: 1px solid lightgrey;
padding: 40px;
`

const UserUpdate = (props) => {
  const [user, setUser] = useState({
    salutation: '',
    company: '',
    email: '',
    firstname: '',
    lastname: '',
    internalnote: '',
    language: '',
    voucher: '',
    channelapp: false,
    channelportal: false,
    cloudowncloud: false
  })

  const [status, setStatus] = useState({
    edit: true,
    password: false,
    success: false,
    delete: false,
    block: false
  })

  const [permissions, setPermissions] = useState([])
  const [permissiongroups, setPermissiongroups] = useState({})
  const [errorNotification, setErrorNotification] = useState('')
  const [oldUserPermissions, setOldUserPermissions] = useState([])
  const [oldUserPermissiongroups, setOldUserPermissiongroups] = useState([])
  const [newUserPermissiongroups, setNewUserPermissiongroups] = useState([])
  const [oneTimeUsePassword, setOneTimeUsePassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingPermission, setLoadingPermission] = useState(false)
  const [allowResetPassword, setAllowResetPassword] = useState(false)

  const [selectedPermissions, setSelectedPermissions] = useState([])

  const permissionIds = []

  // Load data from API
  const { match } = props
  const userId = match.params.id

  const loadData = async () => {
    setLoading(true)
    const currentProfile = Users({ id: userId })
    const loadedUserData = await currentProfile.findOne(userId)
    setUser(loadedUserData)
    if (loadedUserData.channelportal || loadedUserData.cloudowncloud) { setAllowResetPassword(true) }
    const originalPermissions = []
    const allPermissionData = loadedUserData.permissions
    allPermissionData.forEach((permission) => {
      originalPermissions.push(permission.id)
    })
    const originalPermissiongroups = []
    const allPermissiongroupData = loadedUserData.permissionGroups
    allPermissiongroupData.forEach((permissiongroup) => {
      originalPermissiongroups.push(permissiongroup.id)
    })
    setOldUserPermissions(originalPermissions)
    setSelectedPermissions(originalPermissions)
    setOldUserPermissiongroups(originalPermissiongroups)
    setNewUserPermissiongroups(originalPermissiongroups)
    await loadPermissionGroupData()
    await loadPermissionData()
    setLoading(false)
  }

  const handlePermissionChange = async (permission, isCheckedStatus) => {
    setLoadingPermission(true)
    const API = Permission({})
    if (isCheckedStatus) {
      await API.assignUser(userId, permission)
    }
    else {
      await API.unassignUser(userId, permission)
    }
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions([...selectedPermissions, permission])
    }
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    await delay(500)
    setLoadingPermission(false)
  }

  const loadPermissionGroupData = async () => {
    const permissionGroupSelection = await PermissionGroups({ id: '' })
    const allPermissionGroups = await permissionGroupSelection.search({})
    setPermissiongroups(allPermissionGroups)
  }

  const loadPermissionData = async () => {
    const API = Permissions({})
    const allPermissions = await API.search()
    setPermissions(allPermissions)
    getPermissionsIds(allPermissions)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  const deleteUser = async () => {
    const res = await User({ id: userId }).destroy()
    if (typeof res === 'object') {
      await setStatus({ ...status, success: true, delete: false })
      goToUser()
    } else {
      await setErrorNotification(Translation(res))
    }
  }

  const blockUser = async (prop) => {
    const currentProfile = Users({ id: userId })
    const loadedUserData = await currentProfile.findOne(userId)
    loadedUserData.active = !prop
    await loadedUserData.save()
    closeModal()
    setStatus({ ...status, block: false })
    window.location.reload()
  }

  const getPermissionsIds = (permissions) => {
    // eslint-disable-next-line
    for (const permission of permissions) {
      permissionIds.push(permission.id)
      if (permission.children.length > 0) {
        getPermissionsIds(permission.children)
      }
    }
  }

  const generateOtp = useCallback(async () => {
    const currentProfile = User(user)
    const generatedPassword = await currentProfile.generatePassword({ userid: user.id })
    setOneTimeUsePassword(generatedPassword.otp)
    setStatus({ ...status, password: true })
  }, [user, status])

  const submit = async () => {
    const res = await user.save()
    if (typeof res === 'object') {
      const permissionGroupApi = PermissionGroup({})
      getUnassignedPermissiongroups().forEach(async (permissionId) => {
        await permissionGroupApi.unassignUser(user.id, permissionId)
      })
      getAssignedPermissiongroups().forEach(async (permissionId) => {
        await permissionGroupApi.assignUser(user.id, permissionId)
      })
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const getUnassignedPermissiongroups = () => {
    return oldUserPermissiongroups.filter(id => !newUserPermissiongroups.includes(id))
  }

  const getAssignedPermissiongroups = () => {
    return newUserPermissiongroups.filter(id => !oldUserPermissiongroups.includes(id))
  }

  const closeModal = () => {
    setStatus({ ...status, success: false, password: false, delete: false, block: false })
  }

  const toggleSelectedGroup = ({ id }) => {
    setNewUserPermissiongroups(prefState => {
      if (prefState.includes(id)) {
        return [...prefState].filter(elem => elem !== id)
      }
      return [...prefState, id]
    })
  }

  const goToUser = () => {
    window.location.hash = '#/users'
  }

  const handleChangeDeviceLimit = useCallback((limit) => {
    setUser({ ...user, maxdevices: limit })
  }, [user])

  return (
    <Grid>
      {status.success && status.edit &&
        <Modal type='success' title='Änderungen wurden erfolgreich übernommen' close={e => goToUser()}>
          <Grid item xs={12}>
            <Button to='/users'>Ok</Button>
          </Grid>
        </Modal>}
      {status.password &&
        <OTPModal title={`One Time Password für ${user.firstname} ${user.lastname}`} type='none' message='Das OTP ist 24 Stunden gültig' pw={oneTimeUsePassword} children close={e => closeModal()} />}
      {status.delete &&
        <UserNotification type='delete' title='Benutzer löschen' message={`Sind Sie sich sicher, dass Sie ${user.firstname} ${user.lastname} löschen möchten?`} close={e => closeModal()} errorNotification={errorNotification}>
          <Grid item xs={6}>
            <Button onClick={async () => deleteUser()}>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => closeModal()}>Nein</Button>
          </Grid>
        </UserNotification>}
      {status.block &&
        <>
          {user.active &&
            <UserNotification type='block' title='Benutzer sperren' message={`Sind Sie sich sicher, dass Sie ${user.firstname} ${user.lastname} sperren wollen?`} close={e => closeModal()} errorNotification={errorNotification}>
              <Grid item xs={6}>
                <Button onClick={async () => blockUser(true)}>Ja</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={e => closeModal()}>Nein</Button>
              </Grid>
            </UserNotification>}
          {!user.active &&
            <UserNotification type='success' title='Benutzer entsperren' message={`Sind Sie sich sicher, dass Sie ${user.firstname} ${user.lastname} entsperren wollen?`} close={e => closeModal()} errorNotification={errorNotification}>
              <Grid item xs={6}>
                <Button onClick={async () => blockUser(false)}>Ja</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={e => closeModal()}>Nein</Button>
              </Grid>
            </UserNotification>}
        </>}
      <Spacer />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Benutzer bearbeiten</h1>
        </Grid>
        <Grid item xs={3}>
          <LinkButton title='Als Vorlage nutzen' icon='userRight' to={`/users/create/${user.id}`} />
        </Grid>
        <Grid item xs={3}>
          <LinkButton icon='docSearch' to={{ pathname: `/documents/users/${user.id}` }} title='Dokumente' />
        </Grid>
        <Grid item xs={3}>
          <LinkButton title='Löschen' icon='delete' onClick={e => setStatus({ ...status, delete: true })} />
        </Grid>
        <Grid item xs={3}>
          {user.active &&
            <LinkButton title='Sperren' icon='block' onClick={e => setStatus({ ...status, block: true })} />}
          {!user.active &&
            <LinkButton title='Entsperren' icon='check' onClick={e => setStatus({ ...status, block: true })} />}
        </Grid>
      </Grid>
      <Spacer height='30px' />
      <UserFormfields user={user} setUser={setUser} errorNotification={errorNotification} setErrorNotification={setErrorNotification} onGenerateOtp={generateOtp} onChangeDeviceLimit={handleChangeDeviceLimit} allowResetPassword={allowResetPassword}>
        {permissiongroups.length &&
          <Wrapper>
            {
              permissiongroups.map((permissiongroup) => {
                const isChecked = newUserPermissiongroups.find(search => search === permissiongroup.id) !== undefined
                return (
                  <Comment key={permissiongroup.id} comment={permissiongroup} toggleSelected={toggleSelectedGroup} isChecked={isChecked} />
                )
              })
            }
          </Wrapper>}
        <Grid item xs={12}>
          <Spacer height='20px' />
          <h2>Berechtigungen</h2>
          <Spacer height='20px' />
        </Grid>
        <Grid>
          {loading &&
            <LoadIndicator />}
          {loadingPermission &&
            <Notification type='upload' title='Berechtigungen werden aktualisiert' message='Einen Moment bitte...'>
              <LoadIndicator small />
            </Notification>}
        </Grid>
        {permissions.length > 0 && !loading &&
          <PermissionSelection defaultPermissions={oldUserPermissions} onChange={handlePermissionChange} />}
        <Spacer />
        <Button onClick={() => submit()}>speichern</Button>
      </UserFormfields>
    </Grid>
  )
}

UserUpdate.propTypes = {
  match: PropTypes.any
}

export default withRouter(UserUpdate)
