import React from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import DeviceItem from './Item'
import MailItem from './MailItem'

const StyledGrid = styled(Grid)`
  margin-top: 40px;
`

const MailContainer = styled.div`
  width: 100%;
  max-height: 755px;
  overflow-y: auto;
`

const StyledHeadline = styled.h2`
  margin-top: 27px;
`

export default function DeviceList ({ devices = [], onRemove, mails = [] }) {
  return (
    <StyledGrid container>
      {!!devices.length &&
        <>
          <Grid item xs={12}>
            <h2>Geräte</h2>
          </Grid>
          {devices.map(device => (<DeviceItem key={device.id} device={device} onRemove={onRemove} />))}
        </>}
      {!!mails.length &&
        <>
          <Grid item xs={12}>
            <StyledHeadline>Mails</StyledHeadline>
          </Grid>
          <MailContainer>
            {mails.map(mail => (<MailItem key={mail.bulkId} mail={mail} />))}
          </MailContainer>
        </>}
    </StyledGrid>
  )
}
