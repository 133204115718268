import React, { useState, useCallback } from 'react'
import Grid from '@mui/material/Grid'

import styled from 'styled-components'
import UserNotification from '../UserNotifications'
import Button from '../../common/Button'
import Translation from '../../common/BasicTranslation'

import IconSales from '../../../assets/svg/Sales.svg'
import IconIPad from '../../../assets/images/iPad.jpg'
import Trash from '../../../assets/svg/Cross'

import formatAppVersion from './formatVersion.js'

const DeviceName = styled.h3`
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
`

const StyledIPad = styled.img`
  height: 80px;
`

const StyledDevice = styled(Grid)`
  margin-top: 20px;
  border-bottom: 0.5px solid #CCCCCC;
  width: 100%;
  height: 100%;
`

const StyledAppIcon = styled.img`
  width: 40px;
`

const ImgContainer = styled.div`
  height: 100px;
  text-align: center;
`

const AppInfoContainer = styled.div`
  float:left;
  min-height: 40px;
  border-top: 0.5px solid #CCCCCC;
  border-bottom: 0.5px solid #CCCCCC;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  margin-bottom: 5px;
`

const DeviceInfoText = styled.p`
  height: 14px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  white-space: nowrap;
`

const DeviceDate = styled.p`
  min-height: 17px;
  width: 100%;
  max-width: 20vw;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 10px;
`

const TrashIcon = styled(Trash)`
  float: right;
  width: 12px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  padding-top: 15px;
`

const AppContainer = styled.div`
  float:left;
  min-width: 30px;
  width: 100%;
`

const AppInfo = styled.p`
  line-height: 40px;
  padding-left: 10px;
`

const StyledNote = styled(UserNotification)`
  display: ${props => props.anzeigen ? 'none' : 'none'};
`

export default function DeviceItem ({ device = { info: {}, serviceInfo: {} }, onRemove }) {
  const [confirmRemoval, setConfirmRemoval] = useState(false)

  const handleRemove = useCallback(async () => {
    if (onRemove) {
      setConfirmRemoval(false)
      await onRemove(device, confirmRemoval)
    }
  }, [confirmRemoval, device, onRemove])

  const showConfirmRemoval = (shouldShow) => () => setConfirmRemoval(shouldShow)

  const model = device && device.info && device.info.model ? device.info.model : device && device.serviceInfo && device.serviceInfo.model ? device.serviceInfo.model : ''

  return (
    <StyledDevice container>
      <Grid item xs={12}>
        <ImgContainer>
          <StyledIPad src={IconIPad} />
        </ImgContainer>
      </Grid>
      <Grid item xs={12}>
        {(device.info || device.serviceInfo) &&
          <DeviceName>{model}</DeviceName>}
      </Grid>
      <Grid item xs={12}>
        {device.info &&
          <>
            <AppInfoContainer>
              <StyledAppIcon src={IconSales} />
              <AppInfo>Sales App</AppInfo>
              <TrashIcon data-testid='remove' onClick={showConfirmRemoval('salesApp')} fill='#A8ADB0' />
            </AppInfoContainer>
            <AppContainer>
              <DeviceInfoText>Autorisiert am:</DeviceInfoText>
              <DeviceDate>{betterDate(device.lastLogin)}</DeviceDate>
              <DeviceInfoText>Zuletzt Online am:</DeviceInfoText>
              <DeviceDate>{betterDate(device.lastSync)}</DeviceDate>
              <DeviceInfoText>Versionsnummer:</DeviceInfoText>
              <DeviceDate>{formatAppVersion(device.info.version)}</DeviceDate>
            </AppContainer>
          </>}
      </Grid>

      {confirmRemoval &&
        <StyledNote type='block' title={model} message={`Sind Sie sich sicher, dass Sie die Installation der ${Translation(confirmRemoval)} von dem Gerät ${model} entfernen wollen?`} close={showConfirmRemoval(false)}>
          <Grid item xs={6}>
            <Button onClick={handleRemove} data-testid='confirm-remove'>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={showConfirmRemoval(false)}>Nein</Button>
          </Grid>
        </StyledNote>}
    </StyledDevice>
  )
}

function betterDate (dateChange) {
  if (typeof (dateChange) !== 'string') {
    return
  }

  var date = new Date(dateChange)
  var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
  return dateString
}
