import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import Spacer from '../common/Spacer'
import OverviewTable from './OverviewTable'
import Button from '../common/Button'
import LoadIndicator from '../common/LoadIndicator'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import VizSensor from 'react-visibility-sensor'
import useStats from './useStatistics'

const NoDataText = styled.h3`
  width: 100%;
  text-align: center;
`

const StyledLoadingIndicator = styled.div`
  position: absolute;
  top: 200px;
  right: 50%;
`

const Overview = () => {
  const [filters, setFilters] = useState({})
  const [sortOptions, setSortOptions] = useState({ sortBy: 'downloadCount', sortDirection: 'desc' })
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(false)
  const { loadMore, hasMoreDataAvailable, loading, documents, error } = useStats({ filters, sortOptions })

  useEffect(() => {
    if (!loading && loadMoreButtonVisible) {
      loadMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreButtonVisible])

  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Spacer />
          <h1>Statistiken</h1>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Spacer height='40px' />
        {loading &&
          <StyledLoadingIndicator>
            <LoadIndicator />
          </StyledLoadingIndicator>}
        {error &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error || 'Unknown error'}
          </Alert>}
        <OverviewTable documents={documents} updateFilters={setFilters} updateSortOptions={setSortOptions} />
        {!loading && documents.length === 0 &&
          <>
            <Spacer height='30px' />
            <NoDataText>Keine Daten gefunden</NoDataText>
          </>}
        {loading && documents.length >= 20 &&
          <LoadIndicator />}
        <Grid item xs={12}>
          <Spacer />
          {hasMoreDataAvailable &&
            <>
              <VizSensor onChange={(isVisible) => { setLoadMoreButtonVisible(isVisible) }}>
                <Button onClick={() => loadMore()}>Mehr Laden</Button>
              </VizSensor>
              <Spacer />
            </>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Overview
