import React from 'react'
import styled from 'styled-components'
import Popover from '@mui/material/Popover'

import IconLupe from '../../assets/svg/Lupe'
import IconCross from '../../assets/svg/Cross'

import TextInput from '../common/FormElements/TextInput'
import SearchWrapper from '../common/FormElements/SearchWrapper'

const StyledSearchButton = styled.button`
  box-sizing: border-box;
  background-color: #00528C;
  color: #FFF;
  border: none;
  border-top: 1px solid #00528C
  cursor: pointer;
  width: 50%;
  &.active {
    right: 41px;
  }
  & svg {
    margin: 10px 0;
  }
`

const StyledSearchSecondaryButton = styled.button`
  box-sizing: border-box;
  background-color: #FFFFFF;
  color: #00528C;
  border: none;
  border-top: 1px solid #bdd3e1
  cursor: pointer;
  width: 50%;
  &.active {
    right: 41px;
  }
  & svg {
    margin: 10px 0;
  }
`

const StyledPopoverContainer = styled.div`
  padding: 5px;
`

const SearchPopover = ({ open, anchorEl, handleClose, setSearchInput, search, clearSearch, submitSearch, searchFieldType }) => {
  const setNumberInput = (event, key) => {
    const input = {}
    input[key] = event.target.value
    setSearchInput(input)
  }

  const intRx = /\d/
  // prevent number input field of typing non integer values
  const integerChange = (event) => {
    if ((event.key.length > 1) || event.ctrlKey || ((event.key === '-') && (!event.currentTarget.value.length)) || intRx.test(event.key)) return
    event.preventDefault()
  }

  // Add eventlistener for number input fields
  for (const numberfieldInput of document.querySelectorAll(
    'input[type="number"][step="1"]'
  )) numberfieldInput.addEventListener('keydown', integerChange)

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledPopoverContainer>
        {searchFieldType === 'text' &&
          <SearchWrapper>
            <TextInput
              onChange={e => setSearchInput(e.target.value)}
              value={search}
              placeholder='Suche'
            />
          </SearchWrapper>}
        {searchFieldType === 'number' &&
          <SearchWrapper>
            <TextInput
              onChange={e => setNumberInput(e, 'min')}
              value={search.min}
              placeholder='Min'
              type='number'
              min='0'
              step='1'
              pattern='/\d+/'
            />
            <TextInput
              onChange={e => setNumberInput(e, 'max')}
              value={search.max}
              placeholder='Max'
              type='number'
              min={search.min ? search.min : '0'}
              step='1'
              pattern='/\d+/'
            />
          </SearchWrapper>}
        {searchFieldType === 'date' &&
          <SearchWrapper>
            <TextInput
              onChange={e => setNumberInput(e, 'min')}
              value={search.min}
              label='Min'
              placeholder='Min'
              type='date'
            />
            <TextInput
              onChange={e => setNumberInput(e, 'max')}
              value={search.max}
              label='Max'
              placeholder='Max'
              type='date'
            />
          </SearchWrapper>}
      </StyledPopoverContainer>
      <StyledSearchSecondaryButton onClick={clearSearch}><IconCross width='15px' fill='#00528C' /></StyledSearchSecondaryButton>
      <StyledSearchButton onClick={submitSearch}><IconLupe width='15px' fill='#FFF' /></StyledSearchButton>
    </Popover>
  )
}

export default SearchPopover
