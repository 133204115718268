import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Images
import IconUser from '../../assets/svg/NeuBenutzer.js'
import AdminImage from '../../assets/svg/Benutzer.js'
import DocImage from '../../assets/svg/NeuDokument.js'
import TagImage from '../../assets/svg/TagNeu.js'
import TrashImage from '../../assets/svg/Trash.js'
import BlockImage from '../../assets/svg/Cross'
import CheckImage from '../../assets/svg/Haken'
import DocSearchImage from '../../assets/svg/DocSearch'
import UserSearchImage from '../../assets/svg/UserSearch'
import DownloadImage from '../../assets/svg/Download'
import MailImage from '../../assets/svg/Mail'

const styles = `
  display: block;
  border: 1.5px solid #bdd3e1;
  line-height: 38px;
  color: #003B64;
  text-align: center;
  font-weight: bold;
  clear: left;
  cursor: pointer;
  font-size: 14px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  position: relative;
  text-decoration:none;
  font-family: "Open Sans Bold";
  float: right;
`

const StyledLink = styled(Link)`
  width: ${props => props.small ? '90%' : '100%'};
  ${styles}
`

const StyledDivLink = styled.div`
  width: ${props => props.small ? '90%' : '100%'};
  ${styles}
`

const StyledMailIcon = styled.span`
  svg {
    position: absolute;
    left: 15px;
    top: 7px;
  }
`

const StyledIcon = styled.span`
  svg {
    position: absolute;
    left: 15px;
    top: 10px;
  }
`

const StyledIconRight = styled.span`
  svg {
    position: absolute;
    right: 15px;
    top: 10px;
  }
`

function NewButton ({ title, icon, ...restProps }) {
  return (
    <>
      {restProps.to &&
        <StyledLink {...restProps}>
          {icon === 'user' &&
            <StyledIcon>
              <IconUser height='18px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'doc' &&
            <StyledIcon>
              <DocImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'admin' &&
            <StyledIcon>
              <AdminImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'tag' &&
            <StyledIcon>
              <TagImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'permission' &&
            <StyledIcon>
              <TagImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'download' &&
            <StyledIcon>
              <DownloadImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {title}
          {icon === 'delete' &&
            <StyledIconRight>
              <TrashImage height='20px' fill='#003B64' />
            </StyledIconRight>}
          {icon === 'block' &&
            <StyledIconRight>
              <BlockImage height='20px' fill='#003B64' />
            </StyledIconRight>}
          {icon === 'check' &&
            <StyledIconRight>
              <CheckImage height='20px' fill='#003B64' />
            </StyledIconRight>}
          {icon === 'docSearch' &&
            <StyledIconRight>
              <DocSearchImage height='20px' fill='#003B64' />
            </StyledIconRight>}
          {icon === 'userSearch' &&
            <StyledIconRight>
              <UserSearchImage height='20px' fill='#003B64' />
            </StyledIconRight>}
        </StyledLink>}

      {!restProps.to &&
        <StyledDivLink {...restProps}>
          {icon === 'user' &&
            <StyledIcon>
              <IconUser height='18px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'doc' &&
            <StyledIcon>
              <DocImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'admin' &&
            <StyledIcon>
              <AdminImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'tag' &&
            <StyledIcon>
              <TagImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {icon === 'permission' &&
            <StyledIcon>
              <TagImage height='20px' fill='#003B64' />
            </StyledIcon>}
          {title}
          {icon === 'delete' &&
            <StyledIconRight>
              <TrashImage height='20px' fill='#003B64' />
            </StyledIconRight>}
          {icon === 'block' &&
            <StyledIconRight>
              <BlockImage height='20px' fill='#003B64' />
            </StyledIconRight>}
          {icon === 'mail' &&
            <StyledMailIcon>
              <MailImage height='25px' fill='#003B64' />
            </StyledMailIcon>}
        </StyledDivLink>}
    </>
  )
}

NewButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
}

export default NewButton
