import React, { useState, useEffect, Suspense } from 'react'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import Spacer from '../common/Spacer'
import UserFormfield from './FormFields'
import Users from '../common/ApiHandler/User'
import Modal from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import styled from 'styled-components'
import PermissionSelection from '../Permissions/PermissionSelection'

import Permissions, { Permission } from '../common/ApiHandler/Permission'
import PermissionGroups, { PermissionGroup } from '../common/ApiHandler/PermissionGroup'

import Comment from '../Permissions/Tree'

const Wrapper = styled.div`
border: 1px solid lightgrey;
padding: 20px;
`

const UserCreate = () => {
  const [user, setUser] = useState({
    salutation: '',
    company: '',
    email: '',
    firstname: '',
    lastname: '',
    internalnote: '',
    language: '',
    channelapp: false,
    channelportal: false,
    permissions: [],
    permissionGroups: [],
    country: ''
  })
  const [status, setStatus] = useState({
    create: true,
    success: false,
    drop: false
  })
  const [permissions, setPermissions] = useState([])
  const [permissiongroups, setPermissiongroups] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [selectedPermissiongroups, setSelectedPermissiongroups] = useState([])
  const [permissionsToAssign, setPermissionsToCreate] = useState([])
  const [errorNotification, setErrorNotification] = useState('')
  const [presetPermission, setPresetPermission] = useState([])
  const [presetPermissionGroups, setPresetPermissionGroups] = useState([])

  const handlePermissionChange = async (permission, isCheckedStatus) => {
    if(isCheckedStatus){
      setPermissionsToCreate([...permissionsToAssign, permission])
    }
    else{
      setSelectedPermissions(permissionsToAssign.filter(perm => perm !== permission))
    }
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions([...selectedPermissions, permission])
    }
  }

  const toggleSelectedGroup = ({ id }) => {
    setSelectedPermissiongroups(prefState => {
      if (prefState.includes(id)) {
        return [...prefState].filter(elem => elem !== id)
      }
      return [...prefState, id]
    })
  }

  const loadPreset = async () => {
    const string = window.location.hash
    const index = window.location.hash.lastIndexOf('/')
    const userId = string.slice(index + 1, string.length)
    if (userId !== 'create') {
      const currentProfile = Users({ id: userId })
      const loadedUserData = await currentProfile.findOne(userId)

      // reset unwanted info
      loadedUserData.email = ''
      loadedUserData.firstname = ''
      loadedUserData.salutation = ''
      loadedUserData.lastname = ''
      loadedUserData.internalnote = ''
      loadedUserData.voucher = ''
      const originalPermissions = []
      const allPermissionData = loadedUserData.permissions
      allPermissionData.forEach((permission) => {
        originalPermissions.push(permission.id)
      })

      const originalPermissiongroups = []
      const allPermissiongroupData = loadedUserData.permissionGroups
      allPermissiongroupData.forEach((permissiongroup) => {
        originalPermissiongroups.push(permissiongroup.id)
      })
      setPresetPermission(originalPermissions)
      setPresetPermissionGroups(originalPermissiongroups)

      /* Fill user info with info wanted to copy */
      setUser({
        ...user,
        channelapp: loadedUserData.channelapp,
        channelportal: loadedUserData.channelportal,
        language: loadedUserData.language,
        company: loadedUserData.company,
        permissionGroups: originalPermissiongroups,
        permissions: originalPermissions
      })
      setSelectedPermissiongroups(originalPermissiongroups)
      setSelectedPermissions(originalPermissions)
    }
  }

  const reload = async () => {
    const permissionGroupSelection = PermissionGroups({ id: '' })
    const allPermissionGroups = await permissionGroupSelection.search()
    await setPermissiongroups(allPermissionGroups)
    const API = Permissions({})
    const allPermissions = await API.search()
    await setPermissions(allPermissions)
  }

  const submit = async () => {
    selectedPermissiongroups.forEach(permissiongroup => {
      user.permissionGroups.push(permissiongroup)
    })

    const API = Users(user)
    const res = await API.create()

    if (typeof res === 'object') {
      const permissionAPI = Permission({})
      permissionsToAssign.forEach(async (permissionId) => {
        await permissionAPI.assignUser(res.id, permissionId)
      })

      const permissionGroupApi = PermissionGroup(res.id)
      user.permissionGroups.forEach(async (permissiongroupId) => {
        await permissionGroupApi.assignUser(res.id, permissiongroupId)
      })

      setErrorNotification('')
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
  }

  useEffect(() => {
    reload()
    loadPreset()
    // eslint-disable-next-line
  }, [])

  const goToUser = () => {
    window.location.hash = '#/users'
  }

  const resetPage = () => {
    window.location.reload()
  }

  const onChangeDeviceLimit = (maxdevices) => {
    setUser(previous => ({ ...previous, maxdevices }))
  }

  return (
    <Grid>
      {status.success && status.create &&
        <Modal type='create' title='Benutzer erfolgreich angelegt' close={e => goToUser()}>
          <Grid item xs={6}>
            <Button to='/users'>Zurück zur Übersicht</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/users/create' onClick={e => resetPage()}>Neuen Benutzer anlegen</Button>
          </Grid>
        </Modal>}
      <Spacer />
      <h1>Benutzer anlegen</h1>
      <Spacer height='30px' />
      <Suspense fallback={(<div>Loading</div>)}>
        <UserFormfield create {...{ user, setUser, onChangeDeviceLimit, errorNotification }}>
          {permissiongroups.length &&
            <Wrapper>
              {
                permissiongroups.map((permissiongroup) => {
                  const isChecked = presetPermissionGroups.find(search => search === permissiongroup.id) !== undefined || selectedPermissiongroups.find(search => search === permissiongroup.id) !== undefined
                  return (
                    <Comment key={permissiongroup.id} comment={permissiongroup} toggleSelected={toggleSelectedGroup} isChecked={isChecked} />
                  )
                })
              }
            </Wrapper>}
          <Grid item xs={12}>
            <Spacer height='20px' />
            <h2>Berechtigungen</h2>
            <Spacer height='20px' />
          </Grid>
          {permissions.length > 0 &&
            <PermissionSelection defaultPermissions={presetPermission} onChange={handlePermissionChange} />}
          <Spacer />
          <Button onClick={e => submit()}>
            Benutzer anlegen
          </Button>
        </UserFormfield>
      </Suspense>
      <Spacer />
    </Grid>
  )
}

export default UserCreate
