const RESET_FILTER = 'Filter zurücksetzen'

const options = {
  languageOptions: [
    {
      label: RESET_FILTER,
      value: 'reset'
    },
    {
      label: 'Deutsch',
      value: 'de'
    },
    {
      label: 'Englisch',
      value: 'en'
    }
  ],
  langOptions: [{
    label: 'Deutsch',
    value: 'de'
  }, {
    label: 'Englisch',
    value: 'en'
  }],
  productOptions: [],
  docartOptions: [],
  statusOptions: [
    {
      label: RESET_FILTER,
      value: 'reset',
      field: 'draft'
    },
    {
      label: 'Geparkt',
      value: 'true',
      field: 'draft'
    },
    {
      label: 'Veröffentlicht',
      value: 'false',
      field: 'draft'
    }
  ],
  salutationOptions: [
    { value: 'male', label: 'Herr' },
    { value: 'female', label: 'Frau' },
    { value: 'other', label: 'Neutral' }
  ],
  applicationOptions: [
    { value: 'reset', label: RESET_FILTER },
    { value: 'channelapp', label: 'SalesApp' },
    { value: 'channelportal', label: 'Händlerportal' },
    { value: 'all', label: 'SalesApp & Händlerportal' }
  ]
}

export default options