import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import Grid from '@mui/material/Grid'
import IconCross from '../../assets/svg/Cross.js'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '80'
  },
  content: {
    top: '15%',
    zIndex: '90'
  }
}

const Italic = styled.i`
  font-style: italic;
`

const StyledModal = styled(Modal)`
  width: 960px;
  min-height: 250px;
  margin: 0 auto;
  background: #FFF;
  border: 1px solid black;
  position: relative;
  border-radius: 10px;
`

const ClosingButton = styled.button`
  border: 0;
  background-color: transparent;
  margin-top: 50px;
  float:right;
  padding-right: 60px;
`

const StyledTitle = styled.p`
  margin-top: 90px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 30px;
  white-space: nowrap;
  text-align: center;

`

const StyledMessage = styled.p`
  height: 20px;
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`

const ErrorNotification = styled.div`
  border-color: #EBCCD1;
  background-color: #F2DEDE;
  color: #B76161;
  padding: 18px 18px 18px 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  text-align: center;
`

const StyledChildren = styled.div`
  text-align: center;
  padding: 18px;
`

const StyledChildrenMulti = styled.div`
  width: 440px;
  margin-left: 260px;
`

const StyledTitleDelete = styled.p`
  height: 22px;
  width: 140px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 45px;
  margin-bottom: 30px;
  margin-left: 55px;
  white-space: nowrap;
`

const StyledMessageDelete = styled.p`
  height: 20px;
  width: 460px;
  width: 80%;
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 55px;
`

const StyledChildrenDelete = styled.div`
  height: 40px;
  width: 440px;
  padding-top: 50px;
  padding-left: 260px;
  text-align: center;
`

const StyledTitlePublish = styled.p`
  height: 22px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 45px;
  margin-bottom: 30px;
  white-space: nowrap;
  text-align: center;
`

const StyledMessagePublish = styled.p`
  margin-left: 180px;
  margin-right: 180px;
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`

const StyledChildrenPublish = styled.div`
  height: 40px;
  width: 440px;
  padding-top: 50px;
  padding-left: 260px;
  text-align: center;
  padding-bottom: 30px;
`

const StyledTitleUpload = styled.p`
  height: 22px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 45px;
  margin-bottom: 20px;
  white-space: nowrap;
  text-align: center;
`

const StyledChildrenUpload = styled.div`
  height: 40px;
  width: 440px;
  padding-left: 260px;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
`

const StyledMessageUpload = styled.p`
  margin-left: 180px;
  margin-right: 180px;
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`

const StyledInfoMailTitle = styled.p`
  margin-top: 30px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 30px;
  white-space: nowrap;
  text-align: center;
`

const StyledInfoMailContent = styled.div`
  width: 720px;
  margin-left: 120px;
`

const generalNotification = ({ boxtitle = '', type = 'success', title = '', message = '', close, children, value, handleChange, message2, nameString }) => {
  Modal.setAppElement('#root')
  if (type === 'park') {
    type = 'delete'
  }
  return (
    <>
      {type !== 'error' &&
        <StyledModal
          onRequestClose={close}
          shouldCloseOnOverlayClick
          isOpen
          style={customStyles}
        >
          {type === 'success' &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StyledTitle>{title}</StyledTitle>
                <StyledMessage>
                  {message}
                </StyledMessage>
                <StyledChildren>
                  <Grid container spacing={2}>
                    {children}
                  </Grid>
                </StyledChildren>
              </Grid>
            </Grid>}
          {type === 'mail' &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StyledInfoMailTitle>{title}</StyledInfoMailTitle>
                <StyledInfoMailContent>
                  <Grid container spacing={2}>
                    {children}
                  </Grid>
                </StyledInfoMailContent>
              </Grid>
            </Grid>}
          {type === 'publish' && !message2 && !nameString &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StyledTitlePublish>{title}</StyledTitlePublish>
                <StyledMessagePublish>
                  {message}
                </StyledMessagePublish>
                <StyledChildrenPublish>
                  <Grid container spacing={2}>
                    {children}
                  </Grid>
                </StyledChildrenPublish>
              </Grid>
            </Grid>}
          {type === 'publish' && message2 && nameString &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StyledTitlePublish>{title}</StyledTitlePublish>
                <StyledMessagePublish>
                  {message} <Italic>{nameString}</Italic> {message2}
                </StyledMessagePublish>
                <StyledChildrenPublish>
                  <Grid container spacing={2}>
                    {children}
                  </Grid>
                </StyledChildrenPublish>
              </Grid>
            </Grid>}
          {type === 'create' &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StyledTitle>{title}</StyledTitle>
                <StyledMessage>
                  {message}
                </StyledMessage>
              </Grid>
              <StyledChildrenMulti>
                <Grid container spacing={2}>
                  {children}
                </Grid>
              </StyledChildrenMulti>
            </Grid>}
          {type === 'upload' &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StyledTitleUpload>{title}</StyledTitleUpload>
                <StyledChildrenUpload>
                  <Grid container spacing={2}>
                    {children}
                  </Grid>
                </StyledChildrenUpload>
                <StyledMessageUpload>
                  {message}
                </StyledMessageUpload>
              </Grid>
            </Grid>}
          {type === 'delete' &&
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <StyledTitleDelete>{title}</StyledTitleDelete>
              </Grid>
              <Grid item xs={2}>
                <ClosingButton onClick={close}>
                  <IconCross height='18px' width='18px' fill='#A8ADB0' />
                </ClosingButton>
              </Grid>
              <Grid item xs={12}>
                <StyledMessageDelete>
                  {message}
                </StyledMessageDelete>
                <StyledChildrenDelete>
                  <Grid container spacing={2}>
                    {children}
                  </Grid>
                </StyledChildrenDelete>
              </Grid>
            </Grid>}
        </StyledModal>}
      {type === 'error' &&
        <ErrorNotification>
          {message}
        </ErrorNotification>}
    </>

  )
}

generalNotification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.any,
  children: PropTypes.any
}

export default generalNotification
