import React from 'react'

const SvgBenutzer = props => (
  <svg viewBox='0 0 18 20' {...props}>
    <defs>
      <path
        d='M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z'
        id='benutzer_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#benutzer_svg__a'
      transform='translate(-3 -1)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgBenutzer
