import React from 'react'
import Grid from '@mui/material/Grid'
import NewButton from '../common/LinkButton'
import Spacer from '../common/Spacer'

const Dashboard = () => {
  return (
    <Grid>
      <Spacer />
      <h1>Dashboard</h1>
      <Spacer />
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={6}>
          <NewButton to='/documents/create' title='Neues Dokument anlegen' icon='doc' />
        </Grid>
        <Grid item xs={6}>
          <NewButton to='/users/create' title='Neuen Benutzer anlegen' icon='user' />
        </Grid>
        <Grid item xs={6}>
          <NewButton to='/tags/create' title='Neuen Tag anlegen' icon='tag' />
        </Grid>
        <Grid item xs={6}>
          <NewButton to='/admins' title='Administrator' icon='admin' />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard
