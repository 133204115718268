const getAvailableFilters = (allPermissionGroups) => {
  const RESET_FILTER = 'Filter zurücksetzen'

  const permissionGroupsFilter = [{ label: RESET_FILTER, value: 'all' }]
  allPermissionGroups.forEach((group) => {
    const singleFilter = {}
    singleFilter.label = group.title
    singleFilter.value = group.id
    permissionGroupsFilter.push(singleFilter)
  })
  return {
    applications: [{ label: RESET_FILTER, value: 'all' }, { label: 'SalesApp', value: 'channelapp' }, { label: 'Händlerportal', value: 'channelportal' }, { label: 'SalesApp & Händlerportal', value: 'channelportal,channelapp' }],
    permissionGroups: permissionGroupsFilter
  }
}

export default getAvailableFilters