import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import TagEditForm from '../Tag/EditForm'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '80'
  },
  content: {
    top: '15%',
    zIndex: '90'
  }
}

const StyledModal = styled(Modal)`
  width: 960px;
  margin: 0 auto;
  background: #FFF;
  border: 1px solid black;
  position: relative;
  padding-right: 60px;
  padding-left: 60px;
  height: ${props => props.errorDisplay ? '550px' : '470px'};
`

const TagPopUp = ({ close }) => {
  const [errorDisplay, setErrorDisplay] = useState(false)

  return (
    <StyledModal
      onRequestClose={close}
      shouldCloseOnOverlayClick
      isOpen
      style={customStyles}
      errorDisplay={errorDisplay}
    >
      <TagEditForm close={close} cancel withoutPermissions setErrorDisplay={setErrorDisplay} closeTagPopUp={close} />
    </StyledModal>
  )
}

TagPopUp.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.any,
  children: PropTypes.any
}

export default TagPopUp
