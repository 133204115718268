import React from 'react'
import styled from 'styled-components'
import packageJson from '../../../package.json'

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  height: 90px;
  width: 100%;
  background-color: #30393f;
  color: #ffffff;
  font-family: "Open Sans Light";
  font-size: 10px;
  box-sizing: border-box;
  padding: 40px 80px;
`

const StyledA = styled.a`
  text-transform: uppercase;
  height: 25px;
  display: inline-block;
  color: #ffffff;
  font-style: normal;
  font-family: Open Sans Light;
  cursor: pointer;
  display:inline-block;
  flex: right;
`

const StyledVersionNr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  color: #6E7478;
`

const Footer = (props) => {
  return (
    <StyledFooter id='site-footer'>
      <div>Copyright © REA Elektronik GmbH. Alle Rechte vorbehalten.</div>
      <StyledVersionNr>v{packageJson.version}</StyledVersionNr>
      <div>
        <StyledA href='https://www.rea-verifier.com/de-de/datenschutz.html' rel='noopener noreferrer' target='_blank' name='Datenschutzbestimmungen'>
          Datenschutzbestimmungen
        </StyledA>
        <span>&nbsp;/&nbsp;</span>
        <StyledA href='https://www.rea-verifier.com/de-de/impressum.html' rel='noopener noreferrer' target='_blank' name='Impressum'>
        Impressum
        </StyledA>
        <span>&nbsp;/&nbsp;</span>
        <StyledA href='https://www.rea-verifier.com/de-de/kontakt/kontaktformular.html' rel='noopener noreferrer' target='_blank' name='Kontakt'>
        Kontakt
        </StyledA>
      </div>
    </StyledFooter>
  )
}

export default Footer
