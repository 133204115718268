import React, { useState } from 'react'
import styled from 'styled-components'
import IconCross from '../../../assets/svg/Cross'
import IconCheck from '../../../assets/svg/Haken'
import Grid from '@mui/material/Grid'
import Spacer from '../../common/Spacer'

import CopyImage from '../../../assets/svg/Copy'

const StyledSpan = styled.span`
  cursor: pointer;
`

const Wrapper = styled.div`
  display:flex;
  margin-top: 35px;
  margin-left: 125px
`

const ClosingButton = styled.button`
  border: 0;
  background-color: transparent;
  padding-top: 40px;
  padding-left: -65px;
`

const StyledTitle = styled.p`
  font-weight: bold;
  margin-left: 60px;
  padding-top: 40px;
  margin-bottom: 20px;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
`

const StyledMessage = styled.p`
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding-left:60px;
`

const StyledChar = styled.div`
  width: 50px;
  height: 40px;
  border: 1px solid;
  border-color: #DADADA;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-decoration:none;
  font-family: "Open Sans Bold";
  vertical-align: top;
  display:inline-block;
  margin-right: 10px;
  color: #00528C;
`

const StyledCopy = styled(CopyImage)`
  margin-top: 45px;
`

const StyledCheck = styled(IconCheck)`
  margin-top: 45px;
`

export default function ShowOtp ({ title = '', message = '', otp = '', onClose }) {
  const [hasCopied, setHasCopied] = useState(false)
  const otpCharacters = [...otp]

  const handleCopyOtp = () => {
    copyToClipboard(otp)
  }

  const copyToClipboard = (contentToCopy) => {
    const elem = document.createElement('textarea')
    elem.value = contentToCopy
    document.body.appendChild(elem)
    elem.select()
    document.execCommand('copy')
    document.body.removeChild(elem)
    setHasCopied(true)
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={10}>
        <StyledTitle>{title}</StyledTitle>
      </Grid>
      <Grid item xs={2}>
        <ClosingButton onClick={onClose} data-testid='close'>
          <IconCross height='18px' width='18px' fill='#A8ADB0' />
        </ClosingButton>
      </Grid>

      <Grid item xs={12}>
        <StyledMessage>{message}</StyledMessage>
      </Grid>

      <Wrapper>
        {otpCharacters.map((otpChar, index) => (<StyledChar key={index}>{otpChar}</StyledChar>))}
      </Wrapper>

      <StyledSpan onClick={handleCopyOtp}>
        {!hasCopied && <StyledCopy width='35px' height='35px' fill='#A8ADB0' />}
        {hasCopied && <StyledCheck height='18px' width='18px' fill='#A8ADB0' />}
      </StyledSpan>

      <Grid item xs={12}>
        <Spacer heigh='15px' />
        <Grid container spacing={2} />
        <Spacer height='10px' />
      </Grid>
    </Grid>
  )
}
