import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import NewButton from '../common/LinkButton'
import Spacer from '../common/Spacer'
import OverviewTable from './OverviewTable'
import Admins from '../common/ApiHandler/Admin'
import InfoBar from '../common/EntryInfoBar'
import Button from '../common/Button'
import LoadIndicator from '../common/LoadIndicator'

const Overview = () => {
  const [admins, setAdmins] = useState([])
  const [options, setOptions] = useState({
    pageSize: 10,
    increasePageSize: 10
  })
  const [loading, setLoading] = useState(false)

  const setPageSize = (pageSize) => {
    setOptions({ ...options, pageSize: pageSize })
  }

  const loadMore = () => {
    setPageSize(options.pageSize + options.increasePageSize)
  }

  const reload = async () => {
    setLoading(true)
    const API = Admins({})
    const allAdmins = await API.findAll()
    await setAdmins(allAdmins)
    setLoading(false)
  }

  useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [])

  const visibleEntryCount = admins.length > options.pageSize ? options.pageSize : admins.length

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Spacer />
          <h1>Administratoren</h1>
        </Grid>
        <Grid item xs={6}>
          <Spacer />
          <NewButton title='Neuen Administrator anlegen' icon='admin' to='/admins/create' />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Spacer height='40px' />
        {!loading &&
          <OverviewTable admins={admins} options={options} />}
        {loading &&
          <LoadIndicator />}
        <Grid item xs={12}>
          {!loading &&
            <InfoBar
              totalEntryCount={admins.length}
              visibleEntryCount={visibleEntryCount}
            />}
          <Spacer />
          {options.pageSize < admins.length &&
            <>
              <Button onClick={() => loadMore()}>Mehr Laden</Button>
              <Spacer />
            </>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Overview
