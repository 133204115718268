import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Tags from '../common/ApiHandler/Tag'

const Wrapper = styled.div`
  background-color: #efefef;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
`

const TabSelection = styled.div`
  background: #F5F5F5;
  border-bottom: 1px solid #d1d1d1;
  padding: 10px 20px 0px 20px;
  margin-bottom: 0px;
`
const TabButton = styled.button`
  position: relative;
  border: 1px solid #F5F5F5;
  background-color: #F5F5F5;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
  height: 43px;
  color: #30393F;
  padding: 0px 20px;
  margin-bottom: -1.5px;
  &.active {
    font-family: 'Open Sans Bold';
    border-color: #d1d1d1;
    background-color: #FFF;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid #FFF;
  }
`

const TabContent = styled.div`
  background: #FFF;
  padding: 40px 40px 20px 40px;
`

const Tab = styled.div`
  background: #FFF;
  display: ${props => props.active ? 'block' : 'none'};
`

const TagMarker = styled.i `
  display: ${props => props.noTab ? 'none' : ''};
  color: #00528C;
  text-decoration: none;
  font-size: 12px;
  font-family: 'Open Sans Bold';
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 1px solid #FFF;
  padding-right: 3px;
`

const StyledLink = styled(Link)`
  color: #003B64;
  text-decoration: none;
  position: relative;
  font-style: italic;
  display: block;
  &:before {
    content: '\\2022';
    color: #003B64;
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    top: -5px;
  }
  margin-bottom: 20px;
`

const SpanUnderline = styled.span`
text-decoration: underline;
margin-left: 15px;
display: block;
`
const splitToChunks = (array, parts) => {
  const result = []
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
  }
  return result
}

const loadTags = async () => {
  const tagSelection = await Tags({ id: '' })
  const allTags = await tagSelection.findAll({})
  /* Get Tag Types */
  const structuredTags = {}
  if (Array.isArray(allTags) && allTags.length > 0) {
    allTags.forEach((element) => {
      if (!(element.type in structuredTags)) {
        structuredTags[element.type] = []
      }
      structuredTags[element.type].push(element)
    })
  }
  return structuredTags
}

export { Wrapper, TabSelection, Tab, TabButton, TabContent, StyledLink, SpanUnderline, loadTags, splitToChunks, TagMarker}
