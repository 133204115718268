import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

const InfoEntryCounts = styled.div`
  padding-top: 30px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 14px;
`

const InfoBarWrapper = styled.div`
  background-color: #D8D8D8;
`

const InfoBarFill = styled.div`
  background-color: #00528C;
  width: ${props => props.widthPercentage + '%' || '10%'};
  height: 5px;
`

const InfoBar = ({ visibleEntryCount = 0, totalEntryCount = 0 }) => {
  const widthPercentage = Math.round((visibleEntryCount / totalEntryCount) * 100)
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <InfoEntryCounts>
          <p>Sie sehen {visibleEntryCount} von {totalEntryCount} Einträgen</p>
        </InfoEntryCounts>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <InfoBarWrapper>
          <InfoBarFill widthPercentage={widthPercentage} />
        </InfoBarWrapper>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  )
}

InfoBar.propTypes = {
  visibleEntryCount: PropTypes.number,
  totalEntryCount: PropTypes.number
}

export default InfoBar
