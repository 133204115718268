const translations = {
  de: {
    'email is a required parameter for this action': 'Bitte geben Sie eine E-Mail-Adresse an.',
    'email must be valid': 'Bitte geben sie eine gültige E-Mail-Adresse an.',
    'password is a required parameter for this action': 'Bitte geben Sie ein Passwort an.',
    'salutation is a required parameter for this action': 'Bitte geben Sie eine Anrede an.',
    'firstname is a required parameter for this action': 'Bitte geben Sie einen Vornamen an.',
    'lastname is a required parameter for this action': 'Bitte geben Sie einen Nachnamen an.',
    'company is a required parameter for this action': 'Bitte geben Sie eine Firma an.',
    'language is a required parameter for this action': 'Bitte geben Sie eine Sprache an.',
    'email must be unique': 'Diese E-Mail-Adresse ist bereits in Benutzung.',
    'the title for at least one language must be given. i.e. title_en': 'Bitte geben Sie mindestens einen Titel an.',
    'type must be one of brand, product, docart, application, industry, material, group': 'Der Tag muss einen der Folgenden Typen angehören: Marke, Produkt, Dokumentenart, Anwendung, Industrie, Material oder Gruppe',
    'type is a required parameter for this action': 'Bitte wählen Sie eine Kategorie aus.',
    'abbreviation is a required parameter for this action': 'Bitte hinterlegen Sie ein Kürzel',
    'title_de is a required parameter for this action': 'Bitte geben Sie den deutschen Titel an.',
    'title_en is a required parameter for this action': 'Bitte geben Sie den englischen Titel an.',
    'unknown username or password': 'Email-Adresse oder Passwort falsch',
    'password must have at least 10 characters and consist of at least one uppercase, lowercase letter, a digit and a special character': 'Das Passwort muss mindestens 10 Zeichen haben, davon jeweils mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen',
    brands: 'Produktlinien',
    materials: 'Oberflächen',
    products: 'Produkte',
    docarts: 'Dokumentart',
    industries: 'Branchen',
    applications: 'Anwendungen',
    themes: 'Themen',
    meta: 'Meta',
    salesApp: 'Sales App'
  },
  en: {
    'email is a required parameter for this action': 'Email is required to create an user',
    'email must be valid': 'Email must be valid',
    'salutation is a required parameter for this action': 'Salutation is required to create an user',
    'firstname is a required parameter for this action': 'Firstname is required to create an user',
    'lastname is a required parameter for this action': 'Lastname is required to create an user',
    'company is a required parameter for this action': 'Company is required to create an user',
    'language is a required parameter for this action': 'Language is required to create an user',
    'email must be unique': 'Email adress is already in use',
    'the title for at least one language must be given. i.e. title_en': 'At least one title must be set',
    'type must be one of brand, product, docart, application, industry, material, group': 'The type of the tag must be one of: brand, product, docart, application, industry, material, group'
  }
}

export default translations