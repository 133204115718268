import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ActionButton from '../common/ActionButton'
import Grid from '@mui/material/Grid'
import Modal from '../common/GeneralNotification'
import Button from '../common/Button'
import Users, { User } from '../common/ApiHandler/User'
import Translation from '../common/BasicTranslation'

const ActionWrapper = styled.div`
  position: relative;
  height: 40px;
  margin-top: ${props => props.isFirefox ? '-5px' : '0px'};
`

const StyledActionButton = styled(ActionButton)`
  padding-top: 8px;
`

const OverviewTableItem = ({ user }) => {
  const [status, setStatus] = useState({
    delete: false,
    success: false,
    device: false
  })
  const [errorNotification, setErrorNotification] = useState('')
  const userId = user.id
  const closeModal = async () => {
    await setStatus({ ...status, success: false, delete: false, device: false })
  }
  const showDelete = async () => {
    await setStatus({ ...status, delete: true })
  }
  const deleteUser = async () => {
    const res = await User({ id: userId }).destroy()
    if (typeof res === 'object') {
      await setStatus({ ...status, delete: false })
      window.location.reload()
    } else {
      await setErrorNotification(Translation(res))
    }
  }
  const betterCreatedAt = (user) => {
    var date = new Date(user.createdAt)
    var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
    return dateString
  }
  const resetStatus = () => {
    setStatus({ delete: false, success: false })
    window.location.reload()
  }

  const loadData = async () => {
    const currentProfile = Users({ id: userId })
    const loadedUserData = await currentProfile.findOne(userId)
    const UserPermissionGroups = []
    const allPermissiongroupData = loadedUserData.permissionGroups
    allPermissiongroupData.forEach((permissiongroup) => {
      UserPermissionGroups.push(permissiongroup.id)
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
    }, [])

  const isFirefox = typeof InstallTrigger !== 'undefined'

  const actions = (
    <ActionWrapper isFirefox={isFirefox}>
      {status.success &&
        <Modal type='success' title='Änderungen wurden erfolgreich übernommen' close={e => resetStatus()}>
          <Grid item xs={12}>
            <Button onClick={e => resetStatus()}>Ok</Button>
          </Grid>
        </Modal>}
      {status.delete &&
        <Modal type='delete' title='Benutzer löschen' message={`Sind Sie sich sicher, dass Sie ${user.firstname} ${user.lastname} löschen möchten?`} close={e => closeModal()} errorNotification={errorNotification}>
          <Grid item xs={6}>
            <Button onClick={async () => deleteUser()}>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => closeModal()}>Nein</Button>
          </Grid>
        </Modal>}
      {typeof InstallTrigger === 'undefined' &&
        <ActionButton action='edit' to={`/users/update/${user.id}`} />}
      {/* Check if browser is Firefox */}
      {typeof InstallTrigger !== 'undefined' &&
        <StyledActionButton action='edit' to={`/users/update/${user.id}`} />}
      <ActionButton action='delete' onClick={async () => showDelete()} />
    </ActionWrapper>
  )

  return (
    <tr>
      <td>{user.company}</td>
      <td>{user.firstname}</td>
      <td>{user.lastname}</td>
      <td>{betterCreatedAt(user)}</td>
      <td>{actions}</td>
    </tr>
  )
}

OverviewTableItem.propTypes = {
  user: PropTypes.any
}

export default OverviewTableItem
