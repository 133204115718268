import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DocAction, DocActionWrapper } from './OverviewItemActions'
import Modal from '../common/GeneralNotification'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import Document from '../common/ApiHandler/Document'

const Tooltip = styled.div`
  visibility: hidden;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding-top: 3px;

  width: 44px;
  height: 15px;
  top: 100%;
  left: 50%;
  margin-left: -22px;
  margin-top: 7px;

  /* Position the tooltip */
  position: absolute;
`

const DocItem = styled.div`
  margin: 1px;
  position: relative;
  min-height: 180px;
  text-align: center;
  font-size: 10px;
  padding-top: 20px;
  &:hover {
    div${Tooltip} {
      visibility: visible
    }
  }
`
const DocImage = styled.img`
  max-width: 100%;
  height: 150px;
  max-height: 150px;
`

const AddSelectionButton = styled.button`
  height: 100%;
  width: 100%;
  border: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: transparent;
  &:before {
    content: "";
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: block;
    width: 22px;
    height: 22px;
    border: 2px solid #00528C;
    border-radius: 50%;
    background-color: #FFF;
  }
`
const RemoveSelectionButton = styled(AddSelectionButton)`
  &:before {
    background-color: #00528C;
  }
`

const Wrapper = styled.div`
  position: relative;
  height: 150px;
  outline: 1px solid #FFF;
  border: 1px solid #c7c7c7;
  &.selected {
    border: 1px solid #00528C;
    outline: 1px solid #00528C;
  }
  div${DocActionWrapper} {
    display: none;
  }
  &:hover {
    div${DocActionWrapper} {
      display: block;
    }
  }
`

const Badge = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  color: #000;
  background-color: ${props => props.type === 'park' ? '#73BFB8' : '#FEC601'};
`

const StyledTitle = styled.p`
  float: left;
  text-align: left;
  font-size: 10px;
  line-height: 15px;
  margin-top: -3px;
  cursor: default;

`

const StyledProduct = styled.p`
  text-align: left;
  font-family: "Open Sans Bold";
  font-size: 10px;
  flex: 1;
  float: left;
  width: 100%;
  cursor: default;
  height: 20px;
`

const DocTitle = styled.div`
  padding-top: 4px;
  max-height: 250px;
  margin-bottom: 15px;
  &: hover {
    ${StyledProduct} {
      text-decoration: underline;
    }
    ${StyledTitle} {
      text-decoration: underline;
    }
  }
`

Badge.propTypes = {
  type: PropTypes.string
}

const DocumentOverviewItem = ({ document, toggleParkOrPublish, remove, toggleSelection, selectionMode, selection, onChange }) => {
  const [modal, setModal] = useState({
    open: false,
    message: '',
    type: '',
    title: ''
  })

  const [thumbnail, setThumbnail] = useState()
  const [downloadUrl, setDownloadUrl] = useState()
  // const [newDocument, setNewDocument] = useState(false)

  const toggleModal = () => {
    const updatedModal = { ...modal }
    updatedModal.open = updatedModal.open === false
    setModal(updatedModal)
    // onChange()
  }

  const showModal = (type, message, title = '') => {
    const updatedModal = { ...modal }
    updatedModal.open = true
    updatedModal.type = type
    updatedModal.message = message
    updatedModal.title = title
    setModal(updatedModal)
  }

  const truncate = (input) => input.length > 90 ? `${input.substring(0, 87)}...` : input

  const parkAndShowNotification = async (document) => {
    let error = false
    if (!document.filePreviewPreviewGenerated && !document.filePreviewGenerated) {
      error = true
    }
    if (document.draft === false) {
      await toggleParkOrPublish('park', document, false, true, 'singleAction')
      document.draft = true
      showModal('park', 'Das Dokument wird den Benutzern nicht mehr angezeigt', 'Geparkt')
    } else if (document.draft === true) {
      await toggleParkOrPublish('publish', document, true, true, 'singleAction')
      if (!error) {
        document.draft = false
        showModal('park', 'Das Dokument wird den Benutzern angezeigt', 'Veröffentlicht')
      }
    }
  }

  const removeAndCloseModal = async (document) => {
    await remove(document)
    toggleModal()
    onChange()
  }

  const reloadAndCloseModal = () => {
    toggleModal()
    onChange()
  }

  const isSelected = (document) => {
    return selection.findIndex(doc => doc.id === document.id) !== -1
  }

  const getThumbnail = useCallback(async () => {
    if (document.file || document.filenamePreview || document.referenceid) {
      const thumbnail = await Document(document).getThumbnail(document)
      setThumbnail(thumbnail)
    }
  }, [document])

  useEffect(() => {
    if (!document) {
      return
    }
    getThumbnail()
    const DocDownAPI = Document(document)
    const downloadUrl = DocDownAPI.getDownloadUrl(document.id)
    setDownloadUrl(downloadUrl)
    // checkNewDocs(document)
  }, [document, getThumbnail])

  const betterMimeType = (mimetype) => {
    if (mimetype) {
      const index = mimetype.indexOf('/')
      return mimetype.slice(index + 1, mimetype.length).toUpperCase()
    }
  }

  const getThemesOrProducts = () => {
    if (document.tags && document.tags.length > 0) {
      let theme
      let product
      const allTags = document.tags
      allTags.forEach(tag => {
        if (tag.type === 'products') {
          product = tag.title_de
        } else if (tag.type === 'themes') {
          theme = tag.title_de
        }
      })
      if (!product) {
        return theme
      } else {
        return product
      }
    }
  }

  const title = document.title_de ? document.title_de : (document.title_en !== '' ? document.title_en : 'Kein Titel gesetzt')
  return (
    <DocItem>
      {modal.open &&
        <Modal type={modal.type} title={modal.title} message={modal.message} close={toggleModal}>
          {modal.type === 'delete' &&
            <>
              <Grid item xs={6}>
                <Button onClick={() => removeAndCloseModal(document)}>Ja</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => toggleModal()}>Nein</Button>
              </Grid>
            </>}
          {modal.type !== 'delete' && modal.title !== 'Gelöscht' &&
            <Grid item xs={12}>
              <Button onClick={e => toggleModal()}>Ok</Button>
            </Grid>}
          {modal.title === 'Gelöscht' &&
            <Grid item xs={12}>
              <Button onClick={e => reloadAndCloseModal()}>Ok</Button>
            </Grid>}
        </Modal>}
      <Wrapper className={selectionMode && isSelected(document) ? 'selected' : ''}>
        {document.draft &&
          <Badge type='park'>
          Geparkt
          </Badge>}
        {!selectionMode &&
          <DocActionWrapper>
            <DocAction title='Bearbeiten' icon='edit' to={`/documents/update/${document.id}`} />
            {document.file &&
              <DocAction title='Herunterladen' icon='download' href={downloadUrl} />}
            {!document.file && document.referenceid &&
              <DocAction title='Herunterladen' icon='download' href={downloadUrl} />}
            {!document.file && !document.referenceid &&
              <DocAction title='Herunterladen' icon='download' disabled />}
            {document.draft &&
              <DocAction title='Veröffentlichen' icon='park' onClick={e => parkAndShowNotification(document)} />}
            {!document.draft &&
              <DocAction title='Parken' icon='park' onClick={e => parkAndShowNotification(document)} />}
            <DocAction title='Löschen' icon='delete' onClick={() => showModal('delete', `Sind Sie sich sicher, dass Sie ${title} löschen möchten?`, 'Dokument löschen')} />
          </DocActionWrapper>}
        <DocImage src={thumbnail} />
        {selectionMode && isSelected(document) &&
          <RemoveSelectionButton onClick={() => toggleSelection(document)} />}
        {selectionMode && !isSelected(document) &&
          <AddSelectionButton onClick={() => toggleSelection(document)} />}
      </Wrapper>
      <DocTitle>
        <StyledProduct>{getThemesOrProducts()}</StyledProduct>
        <br />
        <StyledTitle>
          {truncate(title)}
        </StyledTitle>
      </DocTitle>
      {document.mimetype &&
        <Tooltip>{betterMimeType(document.mimetype)}</Tooltip>}
    </DocItem>

  )
}

DocumentOverviewItem.propTypes = {
  document: PropTypes.any,
  toggleParkOrPublish: PropTypes.any,
  remove: PropTypes.any,
  toggleSelection: PropTypes.any,
  selectionMode: PropTypes.bool,
  selection: PropTypes.array
}

export default DocumentOverviewItem
