import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'

const Wrapper = styled(Grid)`
    text-align: center;
`

const LoadIndicator = ({ small }) => {
  return (
    <Wrapper item xs={12}>
      {!small &&
        <CircularProgress style={{ color: '#00528C' }} />}
      {small &&
        <CircularProgress style={{ color: '#00528C', height: '25px', width: '25px' }} />}
    </Wrapper>
  )
}

export default LoadIndicator
