import React from 'react'
import PropTypes from 'prop-types'
import IconLupe from '../../../assets/svg/Lupe.js'
import IconCross from '../../../assets/svg/Cross.js'
import styled from 'styled-components'

const StyledSearchButton = styled.button`
  box-sizing: border-box;
  background-color: #00528C;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  cursor: pointer;
  width: 40px;
  svg {
    color: #FFF;
    fill: #FFF;
  }
  &.active {
    right: 41px;
  }
`

const StyledIcon = styled(IconLupe)`
  position: absolute;
  top: 10px;
  left: 10px;
`

const StyledCross = styled(IconCross)`
  position: absolute;
  top: 12px;
  left: 12px;
`

const SearchButton = (props) => {
  return (
    <StyledSearchButton {...props}>
      {props.active &&
        <StyledCross width='16px' />}
      {!props.active &&
        <StyledIcon width='20px' fill='#FFF' />}
    </StyledSearchButton>
  )
}

SearchButton.propTypes = {
  active: PropTypes.bool
}

export default SearchButton
