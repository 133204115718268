import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import IconButton from '@mui/material/IconButton'

import IconLupe from '../../assets/svg/Lupe'
import ArrowDropUp from '../../assets/svg/ArrowDropUp'
import ArrowDropDown from '../../assets/svg/ArrowDropDown'

import SearchPopover from './SearchPopover'

const StyledTable = styled.div`
  table {
    margin-top: 5px;
    border-spacing: 0;
    border: none;
    width: 100%;
    table-layout: fixed;
    tbody {
      tr {
        :nth-child(odd) {
          background-color: #E7F7FF;
        }
        td {
          font-family: "Open Sans";
          line-height: 18px;
          height: 40px;
          padding-top: 0;
          padding-bottom: 0;
          vertical-align: middle;
        }
      }
    }
    td {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      vertical-align:top;
      box-sizing: border-box;
      padding: 0px 0px 0px 15px;
      vertical-align: middle;
    }
  }
  .pagination {
    display: none;
  }
`

const StyledTh = styled.th`
  border-bottom: 1px solid #979797;
  text-align: left;
  padding: 7px 0px 5px 15px;
  font-family: "Open Sans SemiBold";
  color: ${props => props.color};
  cursor: pointer;
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  vertical-align:bottom;
  box-sizing: border-box;
  padding: 0px 0px 0px 15px;
  margin-right: 15px;
  & .statistic-tableheader {
    font-size: 14px;
    line-height: 28px;
    font-family: "Open Sans SemiBold";
    color: ${props => props.color};
  }
  & .statistic-sorticon {
    font-weight: bold;
    vertical-align: middle;
    width: 21px;
    height: 21px;
    display: inline-block;
    color: ${props => props.color};
  }
`

const StyledLupenIcon = styled.span`
  svg {
    left: 15px;
    top: 7px;
    padding-bottom: 2px;
  }
`

const NumberTableCell = styled.td`
  text-align: right;
`

const StyledLink = styled(Link)`
  color: #003B64;
  text-decoration: underline;
  position: relative;
  font-style: italic;
  display: block;
`

const tableHeadTranslation = {
  firstname: {
    title: 'Vorname',
    width: '10%',
    sortKey: 'firstname'
  },
  lastname: {
    title: 'Zuname',
    width: '10%',
    sortKey: 'lastname'
  },
  company: {
    title: 'Firma',
    width: '10%',
    sortKey: 'company'
  },
  documentTitle: {
    title: 'Dokumententitel',
    width: '21%',
    sortKey: 'title'
  },
  documentViews: {
    title: 'Aufrufe',
    width: '10%',
    sortKey: 'viewCount'
  },
  documentLastViewed: {
    title: 'Zuletzt <br /> geöffnet',
    width: '13%',
    sortKey: 'lastViewed'
  },
  documentDownload: {
    title: 'Downloads',
    width: '11%',
    sortKey: 'downloadCount'
  },
  documentLastDownloaded: {
    title: 'Zuletzt <br /> heruntergeladen',
    width: '15%',
    sortKey: 'lastDownload'
  }
}

const filterDefault = {
  firstname: '',
  lastname: '',
  company: '',
  documentTitle: '',
  documentLastViewed: {
    min: '',
    max: ''
  },
  documentViews: {
    min: '',
    max: ''
  },
  documentLastDownloaded: {
    min: '',
    max: ''
  },
  documentDownload: {
    min: '',
    max: ''
  }
}

const searchFieldTypes = {
  number: ['documentViews', 'documentDownload'],
  date: ['documentLastViewed', 'documentLastDownloaded']
}

const OverviewTable = ({ documents, updateFilters, updateSortOptions }) => {
  const [popoverIsActive, setPopoverIsActive] = useState(false)
  const [searchFieldType, setSearchFieldType] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverKey, setPopoverKey] = useState('firstname')
  const [filter, setFilter] = useState(filterDefault)
  const [sortBy, setSortBy] = useState('downloadCount')
  const [sortDirection, setSortDirection] = useState('desc')

  const handleFilterClick = (event, key) => {
    setAnchorEl(event.currentTarget)
    setPopoverKey(key)

    let searchFieldType = 'text'

    for (const fieldType in searchFieldTypes) {
      if (searchFieldTypes[fieldType].includes(key)) {
        searchFieldType = fieldType
      }
    }

    setSearchFieldType(searchFieldType)
    setPopoverIsActive(true)
  }

  const handleClose = () => {
    setPopoverIsActive(false)
  }

  const setSearchInput = (insertValue) => {
    if (searchFieldType === 'text') {
      setFilter({
        ...filter,
        [popoverKey]: insertValue
      })
    } else {
      setFilter({
        ...filter,
        [popoverKey]: {
          ...filter[popoverKey],
          ...insertValue
        }
      })
    }
  }

  const clearSearch = () => {
    setFilter({
      ...filter,
      [popoverKey]: ''
    })
    const filterCopy = JSON.parse(JSON.stringify(filter))
    filterCopy[popoverKey] = ''
    updateFilters(filterCopy)
    setPopoverIsActive(false)
  }

  const submitSearch = () => {
    updateFilters(filter)
    setPopoverIsActive(false)
  }

  const sortDocuments = headerKey => {
    const key = tableHeadTranslation[headerKey].sortKey
    if (sortBy === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortDirection('desc')
      setSortBy(key)
    }
  }

  const getIconColor = (key) => {
    if ((typeof filter[key] === 'string' && filter[key]) || (typeof filter[key] === 'object' && (filter[key].min || filter[key].max))) {
      return '#69BED0'
    } else {
      return '#30393F'
    }
  }

  useEffect(() => {
    updateSortOptions({ sortBy, sortDirection })
  }, [sortBy, sortDirection, updateSortOptions])

  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            {Object.keys(tableHeadTranslation).map(key => (
              <StyledTh key={`tableheader_${key}`} color={getIconColor(key)} width={tableHeadTranslation[key].width}>
                <span className='statistic-tableheader' onClick={() => sortDocuments(key)} dangerouslySetInnerHTML={{ __html: `${tableHeadTranslation[key].title} &nbsp;` }} />
                <span className='statistic-sorticon' onClick={() => sortDocuments(key)}>{tableHeadTranslation[key].sortKey === sortBy ? sortDirection === 'asc' ? <ArrowDropUp fill={getIconColor(key)} /> : <ArrowDropDown fill={getIconColor(key)} /> : ''}</span>
                <IconButton onClick={event => handleFilterClick(event, key)} color='primary' size='small'>
                  <StyledLupenIcon>
                    <IconLupe height='10px' fill={getIconColor(key)} />
                  </StyledLupenIcon>
                </IconButton>
              </StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {documents.map(doc => {
            return (
              <tr key={`tr_${JSON.stringify(doc)}`}>
                <td>{doc.firstname}</td>
                <td>{doc.lastname}</td>
                <td>{doc.company}</td>
                <td><StyledLink target='_blank' to={`/documents/update/${doc.id}`}>{doc.title_de || doc.title_en}</StyledLink></td>
                <NumberTableCell>{doc.viewCount}</NumberTableCell>
                <td>{doc.lastViewed ? new Date(doc.lastViewed).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}</td>
                <NumberTableCell>{doc.downloadCount}</NumberTableCell>
                <td>{doc.lastDownload ? new Date(doc.lastDownload).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <SearchPopover
        open={popoverIsActive}
        anchorEl={anchorEl}
        handleClose={handleClose}
        setSearchInput={setSearchInput}
        search={filter[popoverKey]}
        clearSearch={clearSearch}
        submitSearch={submitSearch}
        searchFieldType={searchFieldType}
      />
    </StyledTable>
  )
}

OverviewTable.propTypes = {
  documents: PropTypes.any,
  updateFilters: PropTypes.func.isRequired,
  updateSortOptions: PropTypes.func.isRequired
}

export default OverviewTable
