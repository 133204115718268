import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Header from './common/Header'
import Footer from './common/Footer'
import Grid from '@mui/material/Grid'
import NavBar from './common/NavBar'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { useSession } from './common/ApiHandler/Session'
// import config from './common/ApiHandler/Config'

import '../assets/reset.css'
import '../assets/main.css'

import Dashboard from './Dashboard/Dashboard'
import UserOverview from './User/Overview'
import UserCreate from './User/Create'
import UserUpdate from './User/Update'
import DocumentOverview from './Document/Overview'
import DocumentEdit from './Document/Edit'
import TagOverview from './Tag/Overview'
import TagEdit from './Tag/EditForm'
import styled from 'styled-components'
import Login from './Login/Login'
import Permissions from './Permissions/Overview'
import PermissionEdit from './Permissions/Edit'
import AdminEdit from './Admin/Edit'
import AdminOverview from './Admin/Overview'
import PermissionGroupOverview from './PermissionGroups/Overview'
import PermissionGroupEdit from './PermissionGroups/Edit'
import UserDevice from './User/Device/Sidebar'
import FilteredDocuments from './Document/FilteredOverview'
import DocumentSidebar from './Document/DocumentSidebar'
import StatisticsOverview from './Statistics/Overview'

const StyledApp = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

const LoginBackground = styled(Grid)`
  background: linear-gradient(180deg, #D3D6D8 0%, #A8ADB0 100%);
  margin-top: 100px;
`

const Spacer = styled.div`
  height: 20px;
`

const FixedGrid = styled(Grid)`
  flex: 1;
  justify-content: center;
  max-width: 100vw;
`

const FixedGridLogin = styled(Grid)`
  flex: 1;
  justify-content: center;
`

function App () {
  const { session } = useSession()
  // If session not exists redirect to login
  if (session) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router basename={window.location.pathname + '#'}>
          <StyledApp>
            <Header />
            <FixedGrid container spacing={5}>
              <Grid item xs={2}>
                <NavBar />
              </Grid>
              <Grid item xs={8}>
                <Switch>
                  <Route path='/' exact component={Dashboard} />
                  <Route path='/dashboard' exact component={Dashboard} />
                  <Route path='/users/' exact component={UserOverview} />
                  <Route path='/users/create/' exact component={UserCreate} />
                  <Route path='/users/update/:id' component={UserUpdate} />
                  <Route path='/users/:id' exact component={UserOverview} />
                  <Route path='/users/create/:id' exact component={UserCreate} />
                  <Route path='/documents/' exact component={DocumentOverview} />
                  <Route path='/documents/create/' component={DocumentEdit} />
                  <Route path='/documents/:id' exact component={FilteredDocuments} />
                  <Route path='/documents/users/:id' exact component={FilteredDocuments} />
                  <Route path='/documents/update/:id' component={DocumentEdit} />
                  <Route path='/admins/' exact component={AdminOverview} />
                  <Route path='/admins/create/' component={AdminEdit} />
                  <Route path='/admins/update/:id' component={AdminEdit} />
                  <Route path='/statistics/' component={StatisticsOverview} />
                  <Route path='/permissiongroups/' exact component={PermissionGroupOverview} />
                  <Route path='/permissiongroups/create/' component={PermissionGroupEdit} />
                  <Route path='/permissiongroups/update/:id' component={PermissionGroupEdit} />
                  <Route path='/tags/' exact component={TagOverview} />
                  <Route path='/tags/create' exact component={TagEdit} />
                  <Route path='/tags/update/:id' component={TagEdit} />
                  <Route path='/permissions' exact component={Permissions} />
                  <Route path='/permissions/create/' component={PermissionEdit} />
                  <Route path='/permissions/update/:id' component={PermissionEdit} />
                  <Route render={() => (
                    <Redirect to='/' />
                  )}
                  />
                </Switch>
              </Grid>
              <Grid item xs={2}>
                <Route path='/documents/update/:id' exact component={DocumentSidebar} />
                <Route path='/users/update/:userid' exact component={UserDevice} />
              </Grid>
            </FixedGrid>
            <Footer />
          </StyledApp>
        </Router>
      </LocalizationProvider>
    )
  } else {
    return (
      <Router basename={window.location.pathname + '#'}>
        <StyledApp>
          <Header />
          <Spacer />
          <FixedGridLogin container spacing={5}>
            <LoginBackground item xs={12}>
              <Switch>
                <Route path='/login' exact component={Login} />
                <Route path='/' exact component={Login} />
                <Route render={() => (
                  <Redirect to='/' />
                )}
                />
              </Switch>
            </LoginBackground>
          </FixedGridLogin>
          <Footer />
        </StyledApp>
      </Router>
    )
  }
}

export default App
