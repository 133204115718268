import React from 'react'
import Logo from '../../assets/svg/ReaSalesLogo'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSession } from './ApiHandler/Session'

const StyledHeader = styled.header`
  height: 120px;
  min-height: 120px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: solid 1px #ececec;
`

const StyledLogout = styled.div`
  cursor: pointer;
  height: 20px;
  width: 59px;
  color: #30393F;
  position: absolute;
  right: 40px;
`

const Header = (props) => {
  const { destroySession } = useSession()

  const { session } = useSession()

  const logout = () => {
    destroySession()
  }

  return (
    <StyledHeader id='#header'>
      <Link to='/dashboard'>
        <Logo height='32px' width='300px' />
      </Link>
      {session && <StyledLogout onClick={() => logout()}>Logout</StyledLogout>}
    </StyledHeader>
  )
}

Header.propTypes = {
  isLoggedIn: PropTypes.string,
  logout: PropTypes.string
}

export default Header
