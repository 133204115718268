import React, { useCallback, useEffect, useState, useRef } from 'react'
import Documents from '../common/ApiHandler/Document'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import LinkButton from '../common/LinkButton'
import DocumentOverview from './OverviewList'
import DocumentRefine from './OverviewRefine'

import _ from 'lodash'

const Overview = () => {
  const [filter, setFilter] = useState({})
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadedDocuments, setLoadedDocuments] = useState([])
  const [totalDocuments, setTotalDocuments] = useState(0)

  const testDebounceLoad = async (docFilter) => {
    const DocApi = Documents({})
    const documentList = await DocApi.findAll(docFilter)

    setLoadedDocuments(documentList.documents)
    setTotalDocuments(documentList.total)

    setFilteredDocuments(...filteredDocuments.concat(documentList.documents))
    return documentList
  }

  const testFunction = useRef(_.debounce(docFilter => testDebounceLoad(docFilter), 500)).current

  const loadMoreDocuments = useCallback(async (docFilter) => {
    setLoading(true)
    if (!docFilter.limit) {
      docFilter = { ...docFilter, limit: 30, offset: 0 }
    }
    await testFunction(docFilter)
    setLoading(false)
  }, [testFunction])

  /* Initial Dataload and Reload */
  useEffect(() => {
    let abort = false
    const setData = async () => {
      const initialFilter = { limit: 30, offset: 0 }
      const documentList = await loadMoreDocuments(initialFilter)
      if (!abort) {
        if (documentList && documentList.documents) {
          setFilteredDocuments(documentList.documents)
        }
      }
    }
    setData()
    return () => {
      abort = true
    }
  }, [loadMoreDocuments])

  useEffect(() => {
    loadMoreDocuments(filter)
  }, [filter, loadMoreDocuments])

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Spacer />
              <h1>Dokumente</h1>
            </Grid>
            <Grid item xs={6}>
              <Spacer />
              <LinkButton title='Neues Dokument anlegen' icon='doc' to='/documents/create' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DocumentRefine filter={filter} setFilter={setFilter} loading={loading} />
      <DocumentOverview filter={filter} documents={loadedDocuments} totalDocuments={totalDocuments} loading={loading} loadMoreDocuments={loadMoreDocuments} />
    </Grid>
  )
}

export default Overview
