import React from 'react'
import propTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'
import Select from '../common/FormElements/Select'
import SelectionTitle from '../common/FormElements/SelectionTitle'
import FormControl from '@mui/material/FormControl'

const FormFields = ({ permission, setPermission, children, errorNotification, ...restProps }) => {
  const handleChange = (event, field) => {
    let newVal
    if (field === 'salutation' | field === 'language') {
      newVal = event.value
    } else {
      newVal = event.target.value
    }
    setPermission({
      ...permission,
      [field]: newVal
    })
  }

  const options = ['Gruppe 1', 'Gruppe 2', 'Gruppe 3', 'Gruppe 4']

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <Spacer height='20px' />
        <TextInput
          title='Bezeichnung'
          onChange={e => handleChange(e, 'title')}
          value={permission.title}
        />
      </Grid>
      <Grid item xs={6}>
        <Spacer height='20px' />
        <FormControl fullWidth>
          <SelectionTitle title='Gruppe' />
          <Select placeholder='Auswählen...' options={options} size={3} onChange={e => handleChange(e, 'salutation')} />
        </FormControl>
      </Grid>
    </Grid>
  )
}
FormFields.propTypes = {
  handleChange: propTypes.any,
  document: propTypes.any
}

export default FormFields
