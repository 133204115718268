import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import Spacer from '../common/Spacer'
import DocumentOverviewItem from './OverviewItem'
import IconSelection from '../../assets/svg/Mehrfach'
import IconTrash from '../../assets/svg/Trash'
import IconPark from '../../assets/svg/Parken'
import Modal from '../common/GeneralNotification'
import styled from 'styled-components'
import InfoBar from '../common/EntryInfoBar'
import VizSensor from 'react-visibility-sensor'
import IconRelease from '../../assets/svg/Haken'

const DocumentOverview = ({ filter, loadMoreDocuments, totalDocuments, documents, loading, visibleEntries }) => {
  const [selectionMode, setSelectionMode] = useState(false)
  const [selection, setSelection] = useState([])
  const [modal, setModal] = useState({})
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(false)
  const [options, setOptions] = useState({
    pageSize: 30,
    increasePageSize: 30
  })
  const [noActiveFilter, setNoActiveFilter] = useState(true)

  if (!documents) {
    documents = []
  }

  let visibleEntryCount

  if (!visibleEntries) {
    visibleEntryCount = totalDocuments > options.pageSize ? documents.length : totalDocuments
  } else {
    visibleEntryCount = visibleEntries
  }

  let documentsWithoutPreview = []
  let previewError = false
  let publishedDoc

  const loadMore = async (reset) => {
    if (totalDocuments < 30) {
      reset = true
    }
    if (totalDocuments < options.pageSize) {
      setOptions({ ...options, pageSize: totalDocuments })
    }
    let newFilter = {}
    if (reset || !totalDocuments || totalDocuments < 31) {
      setOptions({ pageSize: 30, increasePageSize: 30 })
      newFilter = { ...filter, limit: 30, offset: 0 }
    } else {
      newFilter = { ...filter, limit: options.pageSize + options.increasePageSize, offset: 0 }
      setOptions({ increasePageSize: 30, pageSize: options.pageSize + options.increasePageSize })
    }
    await loadMoreDocuments(newFilter)
  }

  useEffect(() => {
    if (filter.offset !== 0) {
      loadMore('reset')
    }
    // eslint-disable-next-line
  }, [filter.draft, filter.title, filter.permissions, filter.permissiongroups, filter.application, filter.tags, filter.language])

  useEffect(() => {
    if (options.pageSize === 1 && totalDocuments > 1) {
      loadMore()
    }
    // eslint-disable-next-line
  }, [options.pageSize])

  /**
   * Lazy Load for Documents
   */
  useEffect(() => {
    if (!loading && loadMoreButtonVisible) {
      loadMore()
    }
    // eslint-disable-next-line
  }, [loadMoreButtonVisible])

  useEffect(() => {
    loadMore('reset')
    // eslint-disable-next-line
  }, [totalDocuments])

  useEffect(() => {
    if (!filter.draft && !filter.title && !filter.permissions && !filter.permissiongroups && !filter.application && !filter.tags && !filter.language && documents.length === 0 && !totalDocuments) {
      setNoActiveFilter(false)
    } else {
      if (!noActiveFilter) {
        setNoActiveFilter(true)
      }
    }
  }, [filter.draft, filter.title, filter.permissions, filter.permissiongroups, filter.application, filter.tags, filter.language, documents, totalDocuments, noActiveFilter])

  const showModal = (type, message, title = '', nameString, message2) => {
    const updatedModal = { ...modal }
    updatedModal.open = true
    updatedModal.type = type
    updatedModal.message = message
    updatedModal.title = title
    updatedModal.name = nameString
    updatedModal.message2 = message2
    setModal(updatedModal)
  }

  const toggleModal = () => {
    const updatedModal = { ...modal }
    updatedModal.open = updatedModal.open === false
    setModal(updatedModal)
  }

  const toggleSelection = (document) => {
    const selectionIndex = selection.findIndex(doc => doc.id === document.id)
    const updatedSelection = [...selection]
    if (selectionIndex === -1) {
      updatedSelection.push(document)
    } else {
      updatedSelection.splice(selectionIndex, 1)
    }
    setSelection(updatedSelection)
  }

  const toggleSelectionMode = () => {
    if (selectionMode === false) {
      setSelectionMode(true)
    } else {
      setSelection([])
      setSelectionMode(false)
    }
  }

  const toggleValue = async (action, field, document, publish, lastDocument, singleAction) => {
    if (singleAction) {
      documentsWithoutPreview = []
      previewError = false
      publishedDoc = false
    }
    if (publish) {
      // if (action === 'publish' && document[field] === false && !lastDocument) {
      //   return
      // }
      document[field] = false
    } else {
      // if (action === 'park' && document[field] === true && !lastDocument) {
      //   return
      // }
      document[field] = true
    }
    /* Fast publish date fix */
    if (field === 'draft' && !document.draft) {
      document.publishDate = new Date()
    }
    const response = await document.save()
    // Change states back because document couldn´t update
    if (typeof response === 'string') {
      if (publish) {
        document[field] = true
      } else {
        document[field] = false
      }
    }
    if (typeof response === 'object' && publish) {
      publishedDoc = true
    }
    previewError = true
    if (response === 'no preview image found. cannot publish') {
      if (!document.title_de) {
        documentsWithoutPreview.push(document.title_en)
      } else {
        documentsWithoutPreview.push(document.title_de)
      }
    }
    if (documentsWithoutPreview.length > 0 && lastDocument) {
      let nameString = ''
      documentsWithoutPreview.forEach((document, index) => {
        if (index !== documentsWithoutPreview.length - 1) {
          nameString += document + ', '
        } else {
          nameString += document + ' '
        }
      })
      if (publishedDoc) {
        showModal('publish', 'Achtung: Dokument', 'Die Dokumente wurden veröffentlicht', nameString, 'besitzt kein Vorschaubild und kann nicht veröffentlicht werden. Bitte fügen Sie ein Vorschaubild hinzu und wiederholen Sie den Vorgang')
      } else {
        showModal('publish', 'Dokument', 'Achtung', nameString, 'besitzt kein Vorschaubild und kann nicht veröffentlicht werden. Bitte fügen Sie ein Vorschaubild hinzu und wiederholen Sie den Vorgang')
      }
      documentsWithoutPreview = []
    } else if (lastDocument && documentsWithoutPreview.length === 0 && !singleAction) {
      if (publish) {
        showModal('publish', 'Die Dokumente werden den Benutzern angezeigt', 'Veröffentlicht')
      } else {
        showModal('park', 'Die Dokumente werden den Benutzern nicht mehr angezeigt ', 'Geparkt')
      }
    } else {
    }
  }

  const toggleParkOrPublish = async (action, document, publish, lastDocument, singleAction) => {
    await toggleValue(action, 'draft', document, publish, lastDocument, singleAction)
  }

  const batchAction = async (action, documents) => {
    if (documents.length > 0) {
      let singleAction
      if (documents.length === 1) {
        singleAction = true
      }
      if (action === 'park') {
        documents.forEach(async (document, index) => {
          let lastDocument = false
          if (documents.length - 1 === index) {
            lastDocument = true
          }
          await toggleParkOrPublish('park', document, false, lastDocument, singleAction)
        })
      }
      if (action === 'publish') {
        documents.forEach(async (document, index) => {
          let lastDocument = false
          if (documents.length - 1 === index) {
            lastDocument = true
          }
          await toggleParkOrPublish('publish', document, true, lastDocument, singleAction)
        })
      }
      if (action === 'remove') {
        for (const item of documents) {
          await remove(item)
        }
      }
    }
  }

  const toggleParkBatchAndShowNotification = async (action) => {
    if (selection.length > 0) {
      let previewError = false
      selection.forEach(document => {
        if (!document.filenamePreview && !document.filePreviewGenerated) {
          previewError = true
        }
      })
      if (action === 'park') {
        await batchAction('park', selection)
        setSelection([])
        if (!previewError) {
          showModal('park', 'Die Dokumente werden den Benutzern nicht mehr angezeigt ', 'Geparkt')
        }
      } else {
        await batchAction('publish', selection)
        setSelection([])
        if (!previewError) {
          showModal('park', 'Die Dokumente werden den Benutzern angezeigt', 'Veröffentlicht')
        }
      }
    }
  }

  const showDeleteConfirmation = async () => {
    if (selection.length > 0) {
      await showModal('delete', 'Sind Sie sich sicher, dass Sie die ausgewählten Dokumente löschen möchten?', 'Löschen')
    }
  }

  const deleteConfirmed = async () => {
    await batchAction('remove', selection)
    setSelection([])
    toggleModal()
    window.location.reload()
  }

  const remove = async (document) => {
    try {
      await document.destroy()
    } catch (err) {
      console.error(err)
    }
  }

  const IconButton = styled.button`
    border: none;
    outline: none;
    background: transparent;
    float: right;
    svg {
      width: 20px;
      height: 20px;
      fill: #A8ADB0;
    }
  `
  return (
    <Grid container spacing={0}>
      {modal.open &&
        <Modal type={modal.type} title={modal.title} message={modal.message} nameString={modal.name} message2={modal.message2} close={toggleModal}>
          {modal.type === 'park' &&
            <Grid item xs={12}>
              <Button onClick={() => toggleModal()}>Ok</Button>
            </Grid>}
          {modal.type === 'success' &&
            <Grid item xs={12}>
              <Button onClick={() => toggleModal()}>Ok</Button>
            </Grid>}
          {modal.type === 'delete' &&
            <>
              <Grid item xs={6}>
                <Button onClick={() => deleteConfirmed()}>Ja</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => toggleModal()}>Nein</Button>
              </Grid>
            </>}
          {modal.type === 'publish' &&
            <Grid item xs={12}>
              <Button onClick={() => toggleModal()}>Ok</Button>
            </Grid>}
        </Modal>}
      <Grid item xs={6}>
        <Spacer height='40px' />
        <h3>Ihre Ergebnisse</h3>
      </Grid>
      <Grid item xs={6}>
        <Spacer height='40px' />
        <IconButton onClick={() => toggleSelectionMode()}>
          {!selectionMode &&
            <IconSelection />}
          {selectionMode &&
            <IconSelection fill='#003B64' height='20px' width='20px' />}
        </IconButton>
        {selectionMode &&
          <>
            <IconButton onClick={() => toggleParkBatchAndShowNotification('park')}><IconPark /></IconButton>
            <IconButton onClick={() => toggleParkBatchAndShowNotification()}><IconRelease /> </IconButton>
            <IconButton onClick={() => showDeleteConfirmation()}><IconTrash /></IconButton>
          </>}
        <Spacer height='20px' />
      </Grid>
      {Array.isArray(documents) && documents.length > 0 && documents.slice(0, options.pageSize).map((document, index) => {
        return (
          <Grid key={`${index}-${document.id}`} item xs={4} lg={2} md={3}>
            <DocumentOverviewItem
              document={document}
              toggleParkOrPublish={toggleParkOrPublish}
              remove={remove}
              toggleSelection={toggleSelection}
              selectionMode={selectionMode}
              selection={selection}
              onChange={() => window.location.reload()}
              error={previewError}
            />
          </Grid>
        )
      })}
      <Grid item xs={12}>
        {!loading && noActiveFilter &&
          <InfoBar
            totalEntryCount={totalDocuments}
            visibleEntryCount={visibleEntryCount}
          />}
        <Spacer />
        {!loading && Array.isArray(documents) && totalDocuments > 0 && options.pageSize < totalDocuments && totalDocuments > 30 &&
          <>
            <VizSensor onChange={(isVisible) => { setLoadMoreButtonVisible(isVisible) }}>
              <Button onClick={() => loadMore()}>Mehr Laden</Button>
            </VizSensor>
            <Spacer />
          </>}
      </Grid>

    </Grid>
  )
}

DocumentOverview.propTypes = {
  documents: PropTypes.any,
  reload: PropTypes.any,
  options: PropTypes.any
}

export default DocumentOverview
