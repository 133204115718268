import React from 'react'

const SvgDetailprofil = props => (
  <svg viewBox='0 0 20 16' {...props}>
    <defs>
      <path
        d='M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM8 9c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8H4v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1zm6.009-1.825l1.703 3.21-.958.51-1.71-3.208.12-.389-.091-.178a3.345 3.345 0 11.45-.24l.097.176.389.119zm-2.953-.903a2.313 2.313 0 002.891-1.537 2.313 2.313 0 00-1.537-2.892 2.313 2.313 0 00-2.891 1.537 2.313 2.313 0 001.537 2.892zm1.832-1.592l-.984-.301-.301.984-.492-.15.3-.985-.983-.3.15-.493.984.301.301-.984.492.15-.3.985.983.3-.15.493z'
        id='detailprofil_svg__a'
      />
    </defs>
    <use
      fill='#A8ADB0'
      xlinkHref='#detailprofil_svg__a'
      transform='translate(-2 -4)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgDetailprofil
