import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'
// Custom components
import Notification from '../common/GeneralNotification'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'
import Select from '../common/FormElements/Select'
import SelectionTitle from '../common/FormElements/SelectionTitle'
import TextArea from '../common/FormElements/TextArea'
import Switch from '../common/FormElements/Switch'
import OtpConfiguration from './Otp/Configuration'
import Button from '../common/Button'
// API imports
import options from '../common/ApiHandler/Options'
import Admins from '../common/ApiHandler/Admin'
import User from '../common/ApiHandler/User'

import Countries from './Countries'
import UserNotification from './UserNotifications'

const LinkText = styled.button`
  color: #003B64;
  background-color: transparent;
  font-family: Open Sans Light;
  text-decoration: underline;
`

const StyledGridForSwitch = styled(Grid)`
  label {
    font-family: Open Sans Bold;
  }
`

const Divider = styled(Spacer)`
  border-bottom: 0.5px solid #CCCCCC;
`

const EditFormFields = ({ user, setUser, children, errorNotification, setErrorNotification, create, onGenerateOtp, onChangeDeviceLimit, allowResetPassword, ...restProps }) => {
  const isNewUser = !!user.id
  const [creatorName, setCreatorName] = useState('')

  const [modalStatus, setModalStatus] = useState({
    password: false
  })

  const handleSelectChange = (event, field) => {
    setUser({
      ...user,
      [field]: event.target.checked
    })
  }

  const handleChange = (event, field) => {
    let newVal
    if (field === 'salutation' || field === 'language' || field === 'country') {
      newVal = event.value
    } else {
      newVal = event.target.value
    }
    setUser({
      ...user,
      [field]: newVal
    })
  }

  const betterCreatedAt = (user) => {
    var date = new Date(user.createdAt)
    var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
    return dateString
  }

  const betterCreatedBy = async (creator) => {
    if (creator) {
      const currentAdmin = Admins({ id: creator })
      const foundAdmin = await currentAdmin.findOne()
      const admin = foundAdmin

      const Creator = `${admin.firstname} ${admin.lastname}`
      setCreatorName(Creator)
    }
  }

  useEffect(() => {
    betterCreatedBy(user.createdBy)
    // eslint-disable-next-line
    }, [user])

  if (!user.internalnote) {
    user.internalnote = ''
  }

  const openPasswordModal = () => {
    setModalStatus({ ...modalStatus, password: true })
  }

  const closeModal = () => {
    setModalStatus({ ...modalStatus, password: false })
  }

  const sendNewLoginData = async () => {
    const currentProfile = User(user)
    const resResetedPassword = await currentProfile.resetPassword({ email: user.email })
    if (typeof resResetedPassword !== 'object') {
      setErrorNotification(resResetedPassword)
    } else {
      setModalStatus({ ...modalStatus, password: false })
      window.location.reload()
    }
  }

  return (
    <Grid container>
      {modalStatus.password &&
        <UserNotification type='delete' title='Neue Zugangsdaten senden' message={`Sind Sie sich sicher, dass Sie ${user.firstname} ${user.lastname} neue Zugangsdaten für das Händlerportal senden wollen?`} close={e => closeModal()} errorNotification={errorNotification}>
          <Grid item xs={6}>
            <Button onClick={e => sendNewLoginData()}>Bestätigen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => closeModal()}>Abbrechen</Button>
          </Grid>
        </UserNotification>}
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <SelectionTitle title='Anrede' />
            <Select placeholder='Auswählen...' options={options.salutationOptions} size={3} onChange={e => handleChange(e, 'salutation')} value={options.salutationOptions.find(option => option.value === user.salutation)} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            title='Email-Adresse'
            onChange={e => handleChange(e, 'email')}
            value={user.email}
          />
        </Grid>
      </Grid>
      <Spacer height='20px' />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <TextInput
            title='Zuname'
            onChange={e => handleChange(e, 'lastname')}
            value={user.lastname}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            title='Vorname'
            onChange={e => handleChange(e, 'firstname')}
            value={user.firstname}
          />
        </Grid>
      </Grid>
      <Spacer height='20px' />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <TextInput
            title='Firma'
            onChange={e => handleChange(e, 'company')}
            value={user.company}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <SelectionTitle title='Land' />
            <Select placeholder='Auswählen...' options={Countries} size={20} onChange={e => handleChange(e, 'country')} value={Countries.find(option => option.value === user.country)} />
          </FormControl>
        </Grid>
      </Grid>
      <Spacer height='20px' />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          {!create &&
            <TextInput
              title='Angelegt von'
              value={creatorName}
              readOnly
            />}
        </Grid>
        <Grid item xs={6}>
          {!create &&
            <TextInput
              title='Erstelldatum'
              value={betterCreatedAt(user)}
              readOnly
            />}
        </Grid>
      </Grid>
      <Spacer height='20px' />
      <Grid item xs={12}>
        <FormControl fullWidth>
          <SelectionTitle title='Sprache' />
          <Select placeholder='Auswählen...' name='language' options={options.langOptions} size={20} onChange={e => handleChange(e, 'language')} value={options.langOptions.find(option => option.value === user.language)} />
        </FormControl>
      </Grid>
      <Spacer height='20px' />
      <Grid item xs={12}>
        <TextArea
          title='Interne Bemerkung'
          onChange={e => handleChange(e, 'internalnote')}
          value={user.internalnote}
        />
      </Grid>
      <Spacer height='40px' />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2>Applikationen</h2>
        </Grid>
        <Grid item xs={12}>
          <Divider height='1px' />
        </Grid>
        <StyledGridForSwitch item xs={6}>
          <Switch
            label='SalesApp'
            onChange={e => handleSelectChange(e, 'channelapp')}
            checked={user.channelapp}
          />
        </StyledGridForSwitch>
        <Grid item xs={12}>
          <OtpConfiguration user={user} onGenerateOtp={onGenerateOtp} onChangeDeviceLimit={onChangeDeviceLimit} />
        </Grid>
        <Grid item xs={12}>
          <Divider height='1px' />
        </Grid>
        <StyledGridForSwitch item xs={6}>
          <Switch
            label='Händlerportal'
            onChange={e => handleSelectChange(e, 'channelportal')}
            checked={user.channelportal}
          />
        </StyledGridForSwitch>
        <StyledGridForSwitch item xs={6}>
          <Switch
            label='MyCloud'
            onChange={e => handleSelectChange(e, 'cloudowncloud')}
            checked={user.cloudowncloud}
          />
        </StyledGridForSwitch>
        {isNewUser && allowResetPassword &&
          <Grid item xs={6}>
            <LinkText onClick={e => openPasswordModal()}>Neue Zugangsdaten schicken</LinkText>
          </Grid>}
        <Grid item xs={12}>
          <Divider height='1px' />
        </Grid>
        <Grid item xs={12}>
          <h2>Berechtigungsgruppen</h2>
        </Grid>
        <Spacer height='20px' />
      </Grid>
      <Grid item xs={12}>
        {children}
        {errorNotification !== '' &&
          <>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </>}
        <Spacer />
      </Grid>
    </Grid>
  )
}

EditFormFields.propTypes = {
  user: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.any,
  setUser: PropTypes.any,
  errorNotification: PropTypes.string,
  allowResetPassword: PropTypes.bool.isRequired
}

EditFormFields.defaultProps = {
  allowResetPassword: false
}

export default EditFormFields
