import React, { useEffect, useState } from 'react'
import Documents from '../common/ApiHandler/Document'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import DocumentOverview from './OverviewList'
import CircularProgress from '../common/LoadIndicator'
import Tag from '../common/ApiHandler/Tag'
import PermissionGroups from '../common/ApiHandler/PermissionGroup'
import Permissions from '../common/ApiHandler/Permission'
import Users from '../common/ApiHandler/User'

const FilteredOverview = () => {
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchParam, setSearchParam] = useState({})
  const [type, setType] = useState('')
  const [user] = useState(window.location.href.includes('user'))
  const [permissions, setPermissions] = useState([])
  const [title, setTitle] = useState('')
  const [visibleEntries, setVisibleEntries] = useState(0)

  const getCorrectPermission = async (list, matchId) => {
    let match = {}

    await list.forEach((entry) => {
      if (entry.id === matchId) {
        match = entry
      } else if (entry.children && entry.children.length > 0 && match !== {}) {
        getCorrectPermission(entry.children, matchId)
      } else {
      }
    })
    if (match && match.id) {
      setSearchParam(match)
      setType('permissions')
      return match
    }
  }

  /* Initial Dataload and Reload */
  useEffect(() => {
    const setSearchParamData = async () => {
      const matchId = /[^/]*$/.exec(window.location.href)[0]
      let apiHandler
      let item = {}
      if (user) {
        apiHandler = Users({ id: matchId })
        item = await apiHandler.findOne(matchId)
        if (typeof item === 'object') {
          setType('user')
        }
      } else {
        apiHandler = Tag({ id: matchId })
        item = await apiHandler.findOne()
        if (typeof item === 'object') {
          setType('tags')
        }
        if (typeof item !== 'object') {
          apiHandler = PermissionGroups({ id: '' })
          item = await apiHandler.findOne({ id: matchId })
          if (typeof item === 'object') {
            setType('permissiongroups')
            setTitle(item.title)
          }
        }
        if (typeof item !== 'object') {
          apiHandler = Permissions({ id: '' })
          item = await apiHandler.get({ id: matchId })
          setPermissions(item)
          getCorrectPermission(item, matchId)
          if (typeof item === 'object') {
            setType('permissions')
          }
        }
      }
      if (searchParam && !searchParam.id) {
        setSearchParam(item)
      }
    }
    setSearchParamData()
    // eslint-disable-next-line
  }, [])

  let name = searchParam.title

  const createTitle = (permissionsList, matchId) => {
    let match = {}
    permissionsList.forEach((entry) => {
      if (entry.id === matchId) {
        match = entry
      } else if (entry.children && entry.children.length > 0 && match !== {}) {
        createTitle(entry.children, matchId)
      }
    })
    if (match.id) {
      name = match.title + '.' + name
      if (match.parentId) {
        createTitle(permissions, match.parentId)
      }
      setTitle(name)
    }
  }

  useEffect(() => {
    if (permissions.length > 1) {
      if (searchParam.parentId) {
        createTitle(permissions, searchParam.parentId)
      } else {
        setTitle(searchParam.title)
      }
    }
    // eslint-disable-next-line
  },[searchParam])

  const loadDocuments = async () => {
    await setLoading(true)
    let documentList = []
    if (type !== 'user') {
      if (type === 'tags') {
        const DocApi = Documents({})
        documentList = await DocApi.search({ [type]: [`${searchParam.id}`], limit: 1000000, offset: 0 })
      } else {
        const DocApi = Documents({})
        documentList = await DocApi.search({ [type]: `${searchParam.id}`, limit: 1000000, offset: 0 })
      }
    } else {
      const DocApi = Documents({})
      documentList = await DocApi.searchUser(searchParam.id)
    }
    await setLoading(false)
    return documentList
  }

  const increaseEntryCount = (filter) => {
    if (filter.limit > documents.length) {
      setVisibleEntries(documents.length)
    } else {
      setVisibleEntries(filter.limit)
    }
  }

  useEffect(() => {
    if (searchParam && searchParam.id && type && type.length > 1) {
      const setData = async () => {
        const documentList = await loadDocuments()
        await setDocuments(documentList)
      }
      setData()
    }
    // eslint-disable-next-line
  }, [searchParam, type])

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Spacer />
          {searchParam && searchParam.abbreviation &&
            <h1>Dokumente für {searchParam.abbreviation}</h1>}
          {title && title.length > 1 &&
            <h1>Dokumente für {title}</h1>}
          {searchParam && searchParam.firstname &&
            <h1>Dokumente für {searchParam.firstname} {searchParam.lastname}</h1>}
        </Grid>
      </Grid>
      {loading &&
        <>
          <Spacer />
          <CircularProgress />
        </>}
      {!loading && <DocumentOverview documents={documents} filter={[]} loadMoreDocuments={increaseEntryCount} totalDocuments={documents.length} visibleEntries={visibleEntries} />}
    </Grid>
  )
}

export default FilteredOverview
