import React from 'react'
import Grid from '@mui/material/Grid'
import Translation from '../common/BasicTranslation'
import styled from 'styled-components'

const StyledTagName = styled.p`
  cursor: pointer;
  float:left;
  padding-left: 5px;
`

const StyledTagType = styled.p`
  font-size: 12px;
  color: #939393
  float:right;
  margin-left: 5px;
  margin-right: 20px;
`

const ResultScroll = styled(Grid)`
  height: 0px;
  overflow-y: 'visible'
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const StyledCheckbox = styled.div`
  width: 20px;
  height: 20px;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
  background: ${props => (props.checked ? '#00528C' : '#fff')}
`

const SingleItem = styled.div`
  cursor: pointer;
  background: #fff
  & :hover {
    background: #F5F5F5;
    border-radius: 5px;
  }
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 10px;
`

const ItemBox = styled.div`
margin-top: 10px;
position: relative;
z-index: 2;
border: 1px solid #A49EA4;
border-radius: 5px;
background-color: #fff;
visibility: ${props => (props.active > 0 ? 'visible' : 'hidden')}
`

const TagSearchItem = ({ filteredTags, searchTags, onChange, defaultTags, blur, setBlur }) => {

  return (
    <ResultScroll>
      <ItemBox active={searchTags.length}>
        {searchTags.map((tag, index) => {
          const isChecked = defaultTags.find(search => search === tag.id) !== undefined
          return (
            <SingleItem container onClick={e => {
              e.preventDefault()
              onChange(tag.id)
            }} key={tag.id} checked={isChecked}>
              <StyledCheckbox checked={isChecked}>
                {isChecked && (
                  <Icon viewBox='0 0 24 24'>
                    <polyline points='20 6 9 17 4 12' />
                  </Icon>
                )}
              </StyledCheckbox>
              <StyledTagName>
                {tag.title_de}
              </StyledTagName>
              <StyledTagType>
                ({Translation(tag.type)})
              </StyledTagType>
            </SingleItem>
          )
        })}
      </ItemBox>
    </ResultScroll>
  )
}

export default TagSearchItem
