import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Checkbox from '../common/FormElements/Checkbox'
import { Wrapper, TabSelection, TabButton, Tab, TabContent, loadTags, splitToChunks, TagMarker } from './TagBase'
import Translation from '../common/BasicTranslation'
import LoadIndicator from '../common/LoadIndicator'
import Tags from '../common/ApiHandler/Tag'
import TagSelectionRefine from './TagSelectionRefine'
import Spacer from '../common/Spacer'

const TagSelection = ({ defaultTags, onChange, tagTypes, refreshTagList, refresh }) => {
  /* Selected TAB */
  const [activeTab, setActiveTab] = useState(0)
  /* List of all tags */
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [noTag, setNoTag] = useState({
    applications: true,
    brands: true,
    themes: true,
    docarts: true,
    products: true,
    industries: true,
    materials: true,
    meta: true
  })
  const [oldTagGroups, setOldTagGroups] = useState([])
  const [filteredTags, setFilteredTags] = useState([])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      setTags(await loadTags())
      setLoading(false)
    }
    loadData()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      setTags(await loadTags())
      setLoading(false)
    }
    if (refresh) {
      loadData()
      refreshTagList()
    }
  }, [refresh, refreshTagList])

  const getDifferenceFromArray = (a1, a2) => {
    var a = []; var diff = []
    if (a1.length < 1 || a2.length < 1) {
      return diff
    }
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true
    }
    for (var x = 0; x < a2.length; x++) {
      if (a[a2[x]]) {
        delete a[a2[x]]
      } else {
        a[a2[x]] = true
      }
    }
    for (var k in a) {
      diff.push(k)
    }
    return diff
  }

  const getAllTypes = async () => {
    const tagArray = []
    for (var i = 0; i < defaultTags.length; i++) {
      const currentTag = Tags({ id: defaultTags[i] })
      const foundTag = await currentTag.findOne()
      tagArray.push(foundTag)
    }
    return tagArray
  }

  const checkForUnassignedCategory = async () => {
    const keys = []
    for (var key in tags) keys.push(key)
    const currentKeys = []
    const allTypes = await getAllTypes()
    allTypes.forEach(docTags => {
      if (currentKeys.indexOf(docTags.type) === -1) {
        currentKeys.push(docTags.type)
      }
    })
    if (currentKeys.length < 1) {
      setNoTag({
        applications: true,
        brands: true,
        themes: true,
        docarts: true,
        products: true,
        industries: true,
        materials: true,
        meta: true
      })
    }
    if (currentKeys.length > oldTagGroups.length) {
      currentKeys.forEach((tagGroup) => {
        addNoTags(tagGroup)
      })
    } else if (currentKeys.length < oldTagGroups.length) {
      const removedGroup = getDifferenceFromArray(currentKeys, oldTagGroups)
      setNoTag((noTag) => ({ ...noTag, [removedGroup]: true }))
    }
    setOldTagGroups(currentKeys)
  }

  const addNoTags = (tagGroup) => {
    setNoTag((noTag) => ({ ...noTag, [tagGroup]: false }))
  }

  useEffect(() => {
    checkForUnassignedCategory()
    // eslint-disable-next-line
  }, [tags, defaultTags])

  return (
    <div>
      {!loading &&
        <>
          <TagSelectionRefine tags={tags} setFilteredTags={setFilteredTags} filteredTags={filteredTags} onChange={onChange} defaultTags={defaultTags} />
          <Spacer height='20px' />
          <Wrapper>
            <TabSelection>
              {Object.keys(tags).sort().map((type, index) => {
                return (
                  <TabButton key={index} onClick={() => setActiveTab(index)} className={(activeTab === index ? 'active' : '')}>
                    <TagMarker noTab={noTag[type]}>{'\u25CF'}</TagMarker>
                    {Translation(type)}
                  </TabButton>
                )
              })}
            </TabSelection>
            <TabContent>
              {Object.keys(tags).sort().map((type, index) => {
                const sortedTags = tags[type].sort((a, b) => {
                  const abbreviationA = a.abbreviation.toUpperCase()
                  const abbreviationB = b.abbreviation.toUpperCase()
                  return (abbreviationA < abbreviationB) ? -1 : (abbreviationA > abbreviationB) ? 1 : 0
                })
                const tagChunks = splitToChunks([...sortedTags], 3)
                if (activeTab === index) {
                  return (
                    <Tab key={index} active={activeTab === index}>
                      <Grid container>
                        {tagChunks.map((chunk, index) => {
                          return (
                            <Grid key={index} item xs={4}>
                              {chunk.map((tag, index) => {
                                const isChecked = defaultTags.find(search => search === tag.id) !== undefined
                                return (
                                  <Checkbox key={index} onChange={() => { onChange(tag.id) }} title={tag.abbreviation} value={tag.id} isChecked={isChecked} />
                                )
                              })}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Tab>
                  )
                } else {
                  return null
                }
              })}
            </TabContent>
          </Wrapper>
        </>}
      {loading &&
        <LoadIndicator />}
    </div>
  )
}

export default TagSelection
