import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import Button from '../common/LinkButton'
import SaveButton from '../common/Button'
import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import { withRouter } from 'react-router'
import PermissionGroupForm from './FormFields'
import PermissionSelection from '../Permissions/PermissionSelection'
import PermissionGroups, { PermissionGroup } from '../common/ApiHandler/PermissionGroup'
import Users from '../common/ApiHandler/User'
import LoadIndicator from '../common/LoadIndicator'

import styled from 'styled-components'
import Comment from '../Permissions/Tree'

const Wrapper = styled.div`
border: 1px solid lightgrey;
padding: 20px 20px 0px 20px;
`

const PermissionGroupEdit = ({ match, ...props }) => {
  const [permissiongroup, setPermissiongroup] = useState({
    title: '',
    permissions: []
  })

  const [errorNotification, setErrorNotification] = useState('')
  // eslint-disable-next-line
  const [users, setUsers] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [permissionsToAssign, setPermissionsToCreate] = useState([])
  const [oldGroupPermissions, setOldGroupPermissions] = useState([])
  const [loadingPermission, setLoadingPermission] = useState(false)
  const [newUsers, setNewUsers] = useState([])
  const [oldUsers, setOldUsers] = useState([])

  /* Default: Create Mode */
  const [status, setStatus] = useState({
    create: true,
    update: false,
    success: false
  })

  const handlePermissionChange = async (permission, isCheckedStatus) => {
    if (match.params.id !== undefined) {
      setLoadingPermission(true)
    }
    const permissionGroupApi = PermissionGroup({})
    if (match.params.id !== undefined) {
      if (isCheckedStatus) {
        await permissionGroupApi.assignPermission(match.params.id, permission)
      }
      else {
        await permissionGroupApi.unassignPermission(match.params.id, permission)
      }
    }
    if(isCheckedStatus){
      setPermissionsToCreate([...permissionsToAssign, permission])
    }
    else{
      setSelectedPermissions(permissionsToAssign.filter(perm => perm !== permission))
    }
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions([...selectedPermissions, permission])
    }
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    await delay(500)
    setLoadingPermission(false)
  }

  const toggleSelectedUser = ({ id }) => {
    setNewUsers(prefState => {
      if (prefState.includes(id)) {
        return [...prefState].filter(elem => elem !== id)
      }
      return [...prefState, id]
    })
  }

  /* Switch to update mode and receive data */
  useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus({ ...status, update: true, create: false })
      loadData()
    }
    loadUser()
    // eslint-disable-next-line
  }, [match])

  const loadData = async () => {
    const API = PermissionGroups({ id: '' })
    const res = await API.findOne({ id: match.params.id })
    setPermissiongroup(res)
    const originalPermissions = []
    const allPermissionData = res.permissions
    allPermissionData.forEach((permission) => {
      originalPermissions.push(permission.id)
    })
    setOldGroupPermissions(originalPermissions)
    setSelectedPermissions(originalPermissions)
    const selectedUsers = []
    const allSelectedUser = res.users
    allSelectedUser.forEach((user) => {
      selectedUsers.push(user.id)
    })
    setOldUsers(selectedUsers)
    setNewUsers(selectedUsers)
    loadUser()
  }

  const loadUser = async () => {
    const UserApi = Users({})
    const allUsers = await UserApi.findAll()
    allUsers.forEach((user) => {
      user.title = user.company + ' ' + user.firstname + ' ' + user.lastname + ' '
    })
    setUsers(allUsers)
  }

  const submit = async () => {
    if (match.params.id !== undefined) {
      await save()
    } else {
      await create()
    }
  }

  const create = async () => {
    const API = PermissionGroups({ id: '' })
    const res = await API.create(permissiongroup)
    if (typeof res === 'object') {
      if(permissionsToAssign && permissionsToAssign.length){
        const permissionGroupApi = PermissionGroup({})
        await permissionsToAssign.map(async (permissionId) => {
          await permissionGroupApi.assignPermission(res.id, permissionId)
        })
      }
      getAssignedUsers().forEach(async (userId) => {
        await res.assignUser(userId, res.id)
      })
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const save = async () => {
    const res = await permissiongroup.set(permissiongroup)
    if (typeof res === 'object') {
      getUnassignedUsers().forEach(async (userId) => {
        await permissiongroup.unassignUser(userId, permissiongroup.id)
      })
      getAssignedUsers().forEach(async (userId) => {
        await permissiongroup.assignUser(userId, permissiongroup.id)
      })
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const getUnassignedUsers = () => {
    return oldUsers.filter(id => !newUsers.includes(id))
  }

  const getAssignedUsers = () => {
    return newUsers.filter(id => !oldUsers.includes(id))
  }

  const showDeletePermissionGroupConfirmation = () => {
    setStatus({ ...status, delete: true })
  }

  const deletePermissionGroup = async () => {
    await permissiongroup.delete()
    window.location.replace('/#/permissiongroups')
  }

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus({ ...status, success: false, delete: false })
  }

  const goToGroups = () => {
    window.location.hash = '#/permissiongroups'
  }
  return (
    <Grid>
      {status.update && status.delete &&
        <Notification type='delete' title='Berechtigungsgruppe löschen' message={`Sind Sie sich sicher, dass Sie ${permissiongroup.title} löschen möchten?`} close={e => closeModal()} errorNotification={errorNotification}>
          <Grid item xs={6}>
            <SaveButton onClick={async () => deletePermissionGroup()}>Ja</SaveButton>
          </Grid>
          <Grid item xs={6}>
            <SaveButton onClick={e => closeModal()}>Nein</SaveButton>
          </Grid>
        </Notification>}
      {status.success && status.create &&
        <Notification type='create' title='Berechtigungsgruppe erfolgreich angelegt' close={e => goToGroups()}>
          <Grid item xs={6}>
            <SaveButton to='/permissionGroups'>Zurück zur Übersicht</SaveButton>
          </Grid>
          <Grid item xs={6}>
            <SaveButton to='/permissionGroups/create' onClick={() => window.location.reload()}>Neue Berechtigungsgruppe</SaveButton>
          </Grid>
        </Notification>}
      {status.success && status.update &&
        <Notification type='success' title='Änderungen wurden erfolgreich übernommen' close={e => goToGroups()}>
          <Grid item xs={12}>
            <SaveButton to='/permissiongroups'>Ok</SaveButton>
          </Grid>
        </Notification>}
      <Spacer height='40px' />
      <Grid container>
        <Grid item xs={7}>
          {status.create &&
            <h1>Neue Berechtigungsgruppe anlegen</h1>}
          {status.update &&
            <h1>Berechtigungsgruppe bearbeiten</h1>}
        </Grid>
        <Grid item xs={5}>
          {status.update &&
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button icon='delete' onClick={() => showDeletePermissionGroupConfirmation()} title='Löschen' />
              </Grid>
              <Grid item xs={6}>
                <Button icon='docSearch' to={{ pathname: `/documents/${permissiongroup.id}` }} title='Dokumente' />
              </Grid>
            </Grid>}
        </Grid>
      </Grid>
      {loadingPermission &&
        <Notification type='upload' title='Berechtigungen werden aktualisiert' message='Einen Moment bitte...'>
          <LoadIndicator small />
        </Notification>}
      <Spacer height='30px' />
      <PermissionGroupForm permissionGroup={permissiongroup} setPermissionGroup={setPermissiongroup}>
        <PermissionSelection defaultPermissions={oldGroupPermissions} onChange={handlePermissionChange} />
        <Spacer height='30px' />
        <h3>Benutzer</h3>
        <Spacer height='20px' />
        <Wrapper>
          {
            users.map((user) => {
              const isChecked = newUsers.find(search => search === user.id) !== undefined
              return (
                <Comment key={user.id} comment={user} toggleSelected={toggleSelectedUser} isChecked={isChecked} />
              )
            })
          }
        </Wrapper>
        {errorNotification &&
          <div>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </div>}
        <Spacer />
        <SaveButton onClick={e => submit()}>
          {status.create &&
            <>Berechtigungsgruppe erstellen</>}
          {status.update &&
            <>Berechtigungsgruppe aktualisieren</>}
        </SaveButton>
      </PermissionGroupForm>
    </Grid>
  )
}
export default withRouter(PermissionGroupEdit)
