import React from 'react'
import Grid from '@mui/material/Grid'

import styled from 'styled-components'

const StyledHistory = styled(Grid)`
  margin-top: 20px;
  border-bottom: 0.5px solid #CCCCCC;
  width: 100%;
`

const AppContainer = styled.div`
  float:left;
  min-width: 30px;
  width: 100%;
`

const MailInfoText = styled.p`
  height: 14px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  white-space: nowrap;
`

const MailData = styled.p`
  min-height: 17px;
  width: 100%;
  max-width: 20vw;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 10px;
`

export default function MailItem ({ mail = {} }) {
  return (
    <StyledHistory container>
      <Grid item xs={12}>
        <AppContainer>
          <MailInfoText>Betreff:</MailInfoText>
          <MailData>{mail.subject}</MailData>
          <MailInfoText>Wurde erstellt am:</MailInfoText>
          <MailData>{betterDate(mail.createdAt)}</MailData>
          <MailInfoText>Versandstatus:</MailInfoText>
          <MailData>{translateStatus(mail.status)}</MailData>
        </AppContainer>
      </Grid>
    </StyledHistory>
  )
}

function translateStatus (status) {
  switch (status) {
    case 'success':
      return 'Versendet'
    case 'pending':
      return 'Ausstehend'
    default:
      return 'Fehlgeschlagen'
  }
}

function betterDate (dateChange) {
  if (typeof (dateChange) !== 'string') {
    return
  }

  var date = new Date(dateChange)
  var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes()
  return dateString
}
