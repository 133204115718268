import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import IconCross from '../../assets/svg/Cross.js'
import Grid from '@mui/material/Grid'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '80'
  },
  content: {
    top: '15%',
    zIndex: '90'
  }
}

const StyledModal = styled(Modal)`
  width: 960px;
  height: 250px;
  margin: 0 auto;
  background: #FFF;
  border: 1px solid black;
  position: relative;
  border-radius: 10px;
`

const ClosingButton = styled.button`
  border: 0;
  background-color: transparent;
  margin-top: 50px;
  float:right;
  padding-right: 60px;
`

const StyledTitle = styled.p`
  height: 22px;
  width: 140px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 45px;
  margin-bottom: 30px;
  margin-left: 55px;
  white-space: nowrap;
`

const StyledMessage = styled.p`
  height: 20px;
  width: 100%;
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 55px;
`

const StyledChildren = styled.div`
  height: 40px;
  width: 440px;
  padding-top: 50px;
  padding-left: 260px;
  text-align: center;
`

const UserNotification = ({ boxtitle = '', type = 'success', title = '', message = '', close, children, value, handleChange }) => {
  return (
    <StyledModal
      onRequestClose={close}
      shouldCloseOnOverlayClick
      isOpen
      style={customStyles}
      ariaHideApp={false}
    >
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <StyledTitle>{title}</StyledTitle>
        </Grid>
        <Grid item xs={2}>
          <ClosingButton onClick={close}>
            <IconCross height='18px' width='18px' fill='#A8ADB0' />
          </ClosingButton>
        </Grid>
        <Grid item xs={12}>
          <StyledMessage>
            {message}
          </StyledMessage>
          <StyledChildren>
            <Grid container spacing={2}>
              {children}
            </Grid>
          </StyledChildren>
        </Grid>
      </Grid>
    </StyledModal>
  )
}

UserNotification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.any,
  children: PropTypes.any
}

export default UserNotification
