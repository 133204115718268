import React from 'react'

const SvgReaSalesLogo = props => (
  <svg viewBox='0 0 188 26' {...props}>
    <g fillRule='nonzero' fill='none'>
      <path
        d='M0 25V0h9.261c5.222 0 9.064 2.227 9.064 7.49 0 3.239-1.478 5.465-4.138 6.882L20 24.392v.507h-6.01l-4.335-8.3H5.42v8.3H0V25zm10.345-12.652c1.28-.81 2.463-2.429 2.463-4.453 0-2.53-1.872-3.543-4.335-3.543H5.419v7.996h4.926zM24 25V0h14.5v4.352h-9v5.972h7.9v4.352h-7.9v5.972H39V25zM41 25v-.506L49.823 0h5.949L64 24.494V25h-5.552l-1.289-4.049h-9.517L46.353 25H41zm14.672-8.4L52.401 6.376l-3.37 10.222h6.641z'
        fill='#00528C'
      />
      <path
        d='M78 24.28v-3.54h.409c2.35 1.011 5.311 1.72 7.865 1.72 4.495 0 6.742-1.316 6.742-3.744 0-2.327-1.839-3.44-4.494-4.25l-4.087-1.314c-3.78-1.315-5.618-3.44-5.618-6.475C78.817 2.327 82.903 0 88.828 0c2.35 0 5.925.607 7.56 1.416v3.339h-.41c-2.349-.81-5.21-1.315-7.15-1.315-3.882 0-6.13.708-6.13 3.136 0 2.124 1.737 2.833 3.78 3.54l3.984 1.417C94.242 12.747 97 14.67 97 18.716 97 23.167 92.914 26 86.376 26c-3.268-.202-6.027-.81-8.376-1.72zM169 24.28v-3.54h.409c2.35 1.011 5.311 1.72 7.865 1.72 4.495 0 6.742-1.316 6.742-3.744 0-2.327-1.839-3.44-4.494-4.25l-4.087-1.314c-3.78-1.315-5.618-3.44-5.618-6.475 0-4.35 4.086-6.677 10.011-6.677 2.35 0 5.925.607 7.56 1.416v3.339h-.41c-2.349-.81-5.21-1.315-7.15-1.315-3.882 0-6.13.708-6.13 3.136 0 2.124 1.737 2.833 3.78 3.54l3.984 1.417c3.78 1.214 6.538 3.136 6.538 7.183 0 4.451-4.086 7.284-10.624 7.284-3.268-.202-6.027-.81-8.376-1.72zM147 24.9V0h16.218v3.327H150.81v6.854h10.943v3.327H150.81v8.165H164V25h-17z'
        fill='#C0BBAE'
      />
      <path
        d='M100 25v-.414L109.437 0h4.743L123 24.565v.395h-3.92l-2.322-6.642h-10.702l-2.47 6.662L100 25zm15.582-9.958l-4.077-11.524-4.263 11.524h8.34zM127 25V0h3.57v21.704H142V25z'
        fill='#BDBAAE'
      />
    </g>
  </svg>
)

export default SvgReaSalesLogo
