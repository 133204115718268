import React, { useEffect, useState, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import SearchButton from '../common/FormElements/SearchButton'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'
import SearchWrapper from '../common/FormElements/SearchWrapper'
import PropTypes from 'prop-types'
import Select from '../common/FormElements/Select'
import getAvailableFilters from './getAvailableFilters'
import filterUsers from './filterUsers'
import PermissionGroups from '../common/ApiHandler/PermissionGroup'

const OverviewRefine = ({ users, setFilteredUsers, reload }) => {
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({})
  const [availableFilters, setAvailableFilters] = useState({})

  const setSearchInput = (event) => {
    if (event !== '') {
      setSearch(event.target.value)
      submitSearch(event.target.value)
    } else {
      setSearch('')
      submitSearch('')
    }
  }

  useEffect(() => {
    const getPermissionGroups = async () => {
      const permissionGroupSelection = await PermissionGroups({ id: '' })
      const allPermissionGroups = await permissionGroupSelection.search({})
      return allPermissionGroups
    }

    const loadFilter = async () => {
      const permissionGroups = await getPermissionGroups()
      const availableFilters = getAvailableFilters(permissionGroups)
      setAvailableFilters(availableFilters)
    }

    loadFilter()
  }, [])

  const submitSearch = (searchString) => {
    const result = []
    const insensitiveSearch = searchString.toLowerCase()
    users.forEach(user => {
      const userSearchValues = user.company.toLowerCase() + user.firstname.toLowerCase() + user.lastname.toLowerCase()
      if (userSearchValues.includes(insensitiveSearch)) {
        result.push(user)
      }
      return result
    })
    setFilteredUsers(result)
  }

  const resetSearch = () => {
    setSearchInput('')
    reload()
  }

  const selectFilter = useCallback((type, value) => {
    setFilter((previousFilter) => {
      const newFilter = { ...previousFilter }
      if (value === 'all') {
        delete newFilter[type]
      } else {
        newFilter[type] = value
      }
      return newFilter
    })
  }, [setFilter])

  const handleFilterSelection = (type) => (event) => {
    selectFilter(type, event.value)
  }

  const handleFilterChange = useCallback(async (filter) => {
    setFilteredUsers(filterUsers({ users, filter }))
  }, [users, setFilteredUsers])

  useEffect(() => {
    handleFilterChange(filter)
  }, [filter, handleFilterChange])

  return (
    <Grid>
      <Spacer />
      <SearchWrapper>
        <TextInput
          onChange={e => setSearchInput(e)}
          value={search}
          placeholder='Suche'
        />
        {search !== '' &&
          <SearchButton active onClick={() => resetSearch()} />}
      </SearchWrapper>
      <Spacer />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Select onChange={handleFilterSelection('applications')} placeholder='Applikation' name='applicationOption' options={availableFilters.applications} size={6} />
        </Grid>
        <Grid item xs={6}>
          <Select onChange={handleFilterSelection('permissionGroupId')} placeholder='Berechtigungsgruppe' name='permissiongroupsOption' options={availableFilters.permissionGroups} size={6} />
        </Grid>
      </Grid>
    </Grid>
  )
}

OverviewRefine.propTypes = {
  users: PropTypes.any,
  setUsers: PropTypes.any,
  reload: PropTypes.any
}

export default OverviewRefine
