import Api from './Api'
import Session from './Session'

const api = Api()

const Admin = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    save: async function () {
      const res = await api.exec(`/admins/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        return res.error
      }
      return Admin(res)
    },
    destroy: async () => {
      const res = await api.exec(`/admins/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    }
  }
}
// - POST admin/authorize {email, password} -> {token}
// - Die folgenden Requests dann mit dem Header {Authorization: 'Token ${token}'}

const Admins = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    create: async () => {
      const res = await api.exec('/admins/', {
        method: 'POST',
        body: JSON.stringify(restProps)
      })
      if (res.error) {
        return res.error
      }
      return Admin(res.admin)
    },
    findAll: async (params) => {
      const res = await api.exec('/admins', {
        method: 'GET',
        body: JSON.stringify(params)
      })

      if (res.error) {
        return res.error
      }
      if (res.admins.length < 1) {
        return []
      }
      return res.admins.map(admin => Admin(admin))
    },
    findOne: async () => {
      const res = await api.exec(`/admins/${id}`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
      return Admin(res.admin)
    },
    authorize: async (params) => {
      const res = await api.exec('/admins/authorize',
        {
          method: 'POST',
          body: JSON.stringify(params)
        }, true)
      if (res.error) {
        return res.error
      }
      Session().start(res)
      return Admin(res)
    }
  }
}

export { Admin }
export default Admins
