import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SelectionTitle from './SelectionTitle'

const StyledInput = styled.input`
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  border: none;
  flex: 1;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  resize: none;
  color: #30393f;
  width: 100%;
  background-color: #ffffff;
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledReadOnlyInput = styled(StyledInput)`
  background-color: #F5F5F5;
  cursor: pointer;
  position: relative;
`

const StyledIcon = styled.span`
  position: absolute;
  z-index: 2;
  margin-left: -25px;
  float: right;
  pointer-events: none;
`

const TextInput = ({ title, icon, ...props }) => {
  return (
    <>
      {title !== '' &&
        <SelectionTitle title={title} />}
      {!props.readOnly &&
        <StyledWrapper>
          <StyledInput {...props} />
          <StyledIcon>
            {icon}
          </StyledIcon>
        </StyledWrapper>}
      {props.readOnly &&
        <StyledWrapper>
          <StyledReadOnlyInput {...props} readOnly />
        </StyledWrapper>}
    </>
  )
}

TextInput.defaultProps = {
  title: '',
  readOnly: false
}

TextInput.propTypes = {
  title: PropTypes.string,
  readOnly: PropTypes.any,
  value: PropTypes.string
}

export default TextInput
