import React from 'react'
import Grid from '@mui/material/Grid'

import DeviceLimits from './DeviceLimits'
import GenerateOtp from './GenerateOtp'

const OtpConfiguration = ({ onChangeDeviceLimit, user = {}, onGenerateOtp }) => {
  const isNewUser = !!user.id
  return (
    <Grid container>
      <Grid item xs={6}>
        <DeviceLimits limit={user.maxdevices} onChange={onChangeDeviceLimit} />
      </Grid>
      {isNewUser &&
        <Grid item xs={6}>
          <GenerateOtp onGenerate={onGenerateOtp} />
        </Grid>}
    </Grid>
  )
}

export default OtpConfiguration
