const filterUsers = ({ users = [], filter = {} }) => {
  return users
    .filter(({ channelapp, channelportal }) => {
      if (!filter.applications) {
        return true
      }
      if (filter.applications === 'channelportal,channelapp') {
        return (Boolean(channelapp) && Boolean(channelportal))
      }
      if (filter.applications === 'channelportal') {
        return (!(channelapp) && Boolean(channelportal))
      }
      if (filter.applications === 'channelapp') {
        return (Boolean(channelapp) && !(channelportal))
      }
      throw new Error(`unknown value for applications filter ${filter.applications}`)
    })
    .filter(({ permissionGroups }) => {
      if (!filter.permissionGroupId) {
        return true
      }
      return permissionGroups.some(permissionGroup => permissionGroup.id === filter.permissionGroupId)
    })
}

export default filterUsers