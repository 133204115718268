import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import TextInput from '../common/FormElements/TextInput'
import FormControl from '@mui/material/FormControl'
import Select from '../common/FormElements/Select'
import SelectionTitle from '../common/FormElements/SelectionTitle'
import Notification from '../common/GeneralNotification'
import Flag from '../common/Flag'
import { withRouter } from 'react-router'
import Tag from '../common/ApiHandler/Tag'
import Button from '../common/Button'
import NewButton from '../common/LinkButton'
import Translation from '../common/BasicTranslation'
import PermissionSelection from '../Permissions/PermissionSelection'
import { Permission } from '../common/ApiHandler/Permission'
import LoadIndicator from '../common/LoadIndicator'

const EditForm = ({ cancel, close, withoutPermissions, setErrorDisplay, closeTagPopUp }, props) => {
  const [tag, setTag] = useState({
    title_de: '',
    title_en: '',
    abbreviation: ''
  })
  const [tagCategories, setTagCategories] = useState([])
  const [status, setStatus] = useState({
    create: false,
    update: false,
    success: false,
    delete: false,
    showModal: false
  })
  const [errorNotification, setErrorNotification] = useState('')
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [permissionsToAssign, setPermissionsToCreate] = useState([])
  const [oldPermissions, setOldPermissions] = useState([])
  const [itemId, setItemId] = useState(0)
  const [loadingPermission, setLoadingPermission] = useState(false)

  useEffect(() => {
    const checkUpdate = async () => {
      const currentLink = window.location.href
      const id = currentLink.slice(currentLink.lastIndexOf('/') + 1, currentLink.length)
      const apiHandler = Tag({ id: id })
      const item = await apiHandler.findOne()
      if (id === 'create') {
        setItemId(0)
      } else if (typeof item !== 'object') {
        setItemId(0)
      } else {
        setItemId(id)
      }
    }

    checkUpdate()
  }, [])

  useEffect(() => {
    if (itemId !== 0) {
      loadData()
      setStatus({ ...status, update: true, create: false })
    } else {
      setStatus({ ...status, create: true })
    }
    getItemTypes()
    // eslint-disable-next-line
  }, [itemId])

  const getItemTypes = async () => {
    const apiHandler = Tag({ id: '' })
    const allItems = await apiHandler.findAll({})
    const itemTypes = []
    allItems.forEach((element) => {
      if (undefined === itemTypes.find(type => type.value === element.type)) {
        itemTypes.push({ label: Translation(element.type), value: element.type })
      }
    })
    setTagCategories(itemTypes)
  }

  const permissionIdArray = (array) => {
    const permissionIds = []
    array.forEach((permission) => {
      permissionIds.push(permission.id)
    })
    return permissionIds
  }

  const loadData = async () => {
    const apiHandler = Tag({ id: itemId })
    const item = await apiHandler.findOne()
    setTag(item)

    const allPermissionData = item.permissions
    if (typeof item === 'object') {
      setOldPermissions(permissionIdArray(allPermissionData))
      setSelectedPermissions(permissionIdArray(item.permissions))
    }
  }

  const submit = async () => {
    if (itemId === 0) {
      const apiHandler = Tag(tag)
      const res = await apiHandler.create()

      if(permissionsToAssign && permissionsToAssign.length){
        const API = Permission({})
        await permissionsToAssign.map(async (permissionId) => {
          await API.assignTag(res.id, permissionId)
        })
      }

      if (typeof res === 'object') {
        setStatus({ ...status, success: true, showModal: true })
      } else {
        setErrorNotification(Translation(res))
        setErrorDisplay(true)
      }
    } else {
      const res = await tag.save()
      if (typeof res === 'object') {
        setStatus({ ...status, success: true, showModal: true })
      } else {
        setErrorNotification(Translation(res))
      }
    }
  }

  const showDelete = async () => {
    await setStatus({ ...status, delete: true, showModal: true })
  }

  const deleteConfirmed = async () => {
    const res = await tag.destroy()
    if (typeof res === 'object') {
      await setStatus({ ...status, success: true, update: false, delete: true, showModal: true })
      goToTags()
    } else {
      await setErrorNotification(Translation(res))
    }
  }

  const closeModal = () => {
    setStatus({ ...status, showModal: false, success: false })
    // closeTagPopUp()
  }

  const handleSwitchChange = (event, field) => {
    setTag({
      ...tag,
      [field]: event.value
    })
  }

  const handleChange = (event, field) => {
    setTag({
      ...tag,
      [field]: event.target.value
    })
  }

  const handlePermissionChange = async (permission, isCheckedStatus) => {
    if (itemId !== 0) {
      setLoadingPermission(true)
    }
    const API = Permission({})
    if (isCheckedStatus) {
      await API.assignTag(tag.id, permission)
    }
    else {
      await API.unassignTag(tag.id, permission)
    }
    if(isCheckedStatus){
      setPermissionsToCreate([...permissionsToAssign, permission])
    }
    else{
      setSelectedPermissions(permissionsToAssign.filter(perm => perm !== permission))
    }
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions([...selectedPermissions, permission])
    }
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    if (itemId !== 0) {
      await delay(500)
    }
    setLoadingPermission(false)
  }

  const goToTags = () => {
    window.location.hash = '#/tags'
  }

  const resetForm = () => {
    setTag({
      title_de: '',
      title_en: '',
      abbreviation: '',
      type: ''
    })
    setStatus({ ...status, success: false })
  }

  return (
    <Grid>
      {withoutPermissions && status.success &&
        <Notification type='block' title='Glückwunsch' message='Tag erfolgreich angelegt' close={e => closeModal()} errorNotification={errorNotification}>
          <Grid item xs={12}>
            <Button onClick={e => closeModal()}>OK</Button>
          </Grid>
        </Notification>}
      {status.delete && !status.success && status.showModal &&
        <Notification type='delete' title='Tag löschen' message={`Sind Sie sich sicher, dass Sie ${tag.abbreviation} löschen möchten?`} close={(e) => closeModal()}>
          <Grid item xs={6}>
            <Button onClick={async () => deleteConfirmed()}>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => closeModal()}>Nein</Button>
          </Grid>
        </Notification>}
      {status.success && status.create && status.showModal && !closeTagPopUp &&
        <Notification type='create' title='Tag erfolgreich angelegt' close={e => goToTags()}>
          <Grid item xs={6}>
            <Button to='/tags'>Zurück zur Übersicht</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/tags/create' onClick={e => window.location.reload()}>Neuen Tag anlegen</Button>
          </Grid>
        </Notification>}
      {status.success && status.create && status.showModal && closeTagPopUp &&
        <Notification type='create' title='Tag erfolgreich angelegt' close={e => goToTags()}>
          <Grid item xs={6}>
            <Button onClick={e => closeTagPopUp()}>Zurück zum Dokument</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => resetForm()}>Neuen Tag anlegen</Button>
          </Grid>
        </Notification>}
      {status.success && status.update && status.showModal &&
        <Notification type='success' title='Änderungen wurden erfolgreich übernommen' close={e => goToTags()}>
          <Grid item xs={12}>
            <Button to='/tags'>Ok</Button>
          </Grid>
        </Notification>}
      <Spacer />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          {itemId !== 0 &&
            <h1>Tag bearbeiten</h1>}
          {itemId === 0 &&
            <h1>Neuen Tag anlegen</h1>}
        </Grid>
        {itemId !== 0 &&
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <NewButton icon='delete' onClick={() => showDelete()} title='Tag löschen' />
              </Grid>
              <Grid item xs={6}>
                <NewButton icon='docSearch' to={{ pathname: `/documents/${tag.id}`, state: { filter: tag.id } }} title='Dokumente' />
              </Grid>
            </Grid>
          </Grid>}
      </Grid>

      <Spacer height='30px' />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <SelectionTitle title='Bitte wählen sie die Kategorie aus zudem das Tag zugewiesen werden soll.' />
            <Select options={tagCategories} value={tag && tag.type !== '' ? tagCategories.find(option => option.value === tag.type) : null} size={4} onChange={e => handleSwitchChange(e, 'type')} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Spacer height='20px' />
          <TextInput
            title='Bitte hinterlegen Sie die Kürzel.'
            value={tag.abbreviation}
            onChange={e => handleChange(e, 'abbreviation')}
          />
          <Spacer height='20px' />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Flag flag='de' />
          <TextInput
            title='Tag benennen in Deutsch'
            value={tag.title_de}
            onChange={e => handleChange(e, 'title_de')}
          />
        </Grid>
        <Grid item xs={6}>
          <Flag flag='en' />
          <TextInput
            title='Tag benennen in Englisch'
            value={tag.title_en}
            onChange={e => handleChange(e, 'title_en')}
          />
        </Grid>
        {loadingPermission &&
          <Notification type='upload' title='Berechtigungen werden aktualisiert' message='Einen Moment bitte...'>
            <LoadIndicator small />
          </Notification>}
        {!withoutPermissions &&
          <Grid item xs={12}>
            <h3>Berechtigungen</h3>
            <Spacer height='20px' />
            <PermissionSelection defaultPermissions={oldPermissions} onChange={handlePermissionChange} />
          </Grid>}
      </Grid>
      <Spacer />
      {errorNotification !== '' &&
        <>
          <Notification type='error' message={errorNotification} />
          <Spacer />
        </>}
      {!cancel &&
        <Button onClick={e => submit()}>
          {itemId !== 0 &&
            <>Tag aktualisieren</>}
          {itemId === 0 &&
            <>Tag anlegen</>}
        </Button>}
      {cancel &&
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Button style={{ backgroundColor: '#A8ADB0' }} onClick={close}>
              Abbrechen
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => submit()}>
              Tag anlegen
            </Button>
          </Grid>
        </Grid>}

      <Spacer />
    </Grid>
  )
}

export default withRouter(EditForm)
