import Api from './Api'

const api = Api()

let foundDocs = {}

function waitForChange (params) {
  return new Promise(resolve => {
    const intervalId = setInterval(() => {
        if (foundDocs[JSON.stringify(params)] !== 'loading') {
            clearInterval(intervalId)
            resolve(foundDocs[JSON.stringify(params)])
        }
    }, 100)
  })
}

const Statistics = () => {
  return {
    findDocs: async (params) => {
      const paramsString = JSON.stringify(params)
      
      if(foundDocs[paramsString] === 'loading') {
        const result = await waitForChange(params)
        return result
      }
      
      foundDocs[paramsString] = 'loading'
      const res = await api.exec('/stats/docs', {
        method: 'GET',
        body: paramsString
      })
      if (res.error) {
        return res.error
      }
      foundDocs[paramsString] = res.stats
      return res.stats
    }
  }
}

export default Statistics
