import { useState, useEffect} from 'react'
import Statistics from '../common/ApiHandler/Statistic'

const useStats = ({ filters, sortOptions }) => {
  const [documents, setDocuments] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasMoreDataAvailable, setHasMoreDataAvailable] = useState(true)
  const [limit, setLimit] = useState(100) // eslint-disable-line no-unused-vars
  const [offset, setOffset] = useState(0)

  const loadData = async (concat = false) => {
    setLoading(true)
    setError(null)
    const StatsApi = Statistics({})
    const params = {}

    const textFilters = ['firstname', 'lastname', 'company', 'documentTitle']
    textFilters.forEach(filterName => {
      const filter = (filters || {})[filterName]
      if (filter) {
        params[filterName] = filter
      }
    })

    const minMaxFilters = ['documentLastDownloaded', 'documentLastViewed', 'documentDownload', 'documentViews']
    const minMax = ['min', 'max']
    minMaxFilters.forEach(filterName => {
      if (typeof filters[filterName] === 'object') {
        minMax.forEach(rangeEnd => {
          if (filters[filterName][rangeEnd]) {
            const camelCasedProperty = `${filterName}${rangeEnd[0].toUpperCase()}${rangeEnd.slice(1)}`
            params[camelCasedProperty] = filters[filterName][rangeEnd]
          }
        })
      }
    })

    const options = {
      ...params,
      offset,
      limit
    }
    if (sortOptions && sortOptions.sortBy) {
      options.sortBy = sortOptions.sortBy
    }
    if (sortOptions && sortOptions.sortDirection) {
      options.sortDirection = sortOptions.sortDirection
    }

    const docs = await StatsApi.findDocs(options)
    if (!Array.isArray(docs)) {
      setError(docs)
      setLoading(false)
      return
    }

    setHasMoreDataAvailable(docs.length === limit)

    if (concat) {
      setDocuments([...documents, ...docs])
    } else {
      setDocuments(docs)
    }
    setLoading(false)
  }

  const loadMore = () => {
    setOffset(offset + limit)
  }

  useEffect(() => {
    loadData(!!offset)
  }, [offset, limit]) // eslint-disable-line

  useEffect(() => {
    if (offset === 0) {
      loadData()
    } else {
      setOffset(0)
    }
  }, [filters, sortOptions]) // eslint-disable-line

  return { loading, error, loadMore, hasMoreDataAvailable, documents }
}

export default useStats
