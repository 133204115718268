const formatAppVersion = version => {
  const match = /(\d+\.\d+\.\d+(?:-[a-zA-Z0-9]+)?)/.exec(String(version))
  if (!Array.isArray(match) || !match[1]) {
    return version
  }
  const semver = match[1]
  const buildNumber = version.replace(semver, '').replace(/[^0-9]/, '')
  return `${semver}-${buildNumber}`.replace(/-$/, '')
}

export default formatAppVersion
