import translations from './Translation'

const Translation = (res) => {
  // var userLang = navigator.language || navigator.userLanguage

  // if (userLang.indexOf('-') > -1) {
  //   userLang = userLang.substring(0, userLang.indexOf('-'))
  // }

  // Fallback Language
  // if (!translations[userLang]) {
  //   userLang = 'de'
  // }

  const userLang = 'de'
  if (translations[userLang][res]) {
    const translatedRes = translations[userLang][res]
    return translatedRes
  } else {
    return res
  }
}

export default Translation
