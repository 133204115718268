import React from 'react'

const SvgArrowRight = props => (
  <svg viewBox='0 0 12 12' width='1em' height='1em' {...props}>
    <title>Path 2 Copy</title>
    <path d='M0 0v12l12-6z' fill='#DCDCDC' fillRule='evenodd' />
  </svg>
)

export default SvgArrowRight
