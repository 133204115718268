import React, { useState, useEffect } from 'react'
import Permissions from '../common/ApiHandler/Permission'
import Modal from '../common/GeneralNotification'
import Button from '../common/Button'
import Translation from '../common/BasicTranslation'
import { withRouter } from 'react-router'
import Grid from '@mui/material/Grid'
import Spacer from '../common/Spacer'
import FormFields from './FormFields'

const PermissionEdit = ({ match, ...props }) => {
  const [permission, setPermission] = useState({
    title: '',
    parentid: ''
  })

  const [errorNotification, setErrorNotification] = useState('')

  const handleChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  /* Load Data for Update */
  const loadData = async () => {
    const currentPermission = Permissions({ id: match.params.id })
    const foundPermission = await currentPermission.findOne()
    setPermission(foundPermission)
  }

  const [status, setStatus] = useState({
    create: false,
    update: false,
    success: false
  })

  useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus({ ...status, update: true, create: false })
      loadData()
    }
    // eslint-disable-next-line
    }, [match])

  const submit = async () => {
    let res = false
    if (!permission.id) {
      const API = Permissions(permission)
      res = await API.create()
    } else {
      const updatedPermission = { ...permission }
      delete updatedPermission.referenceid
      res = await updatedPermission.save()
    }
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus({ ...status, success: true })
    } else {
      setErrorNotification(Translation(res))
    }
  }

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus({ ...status, success: false })
  }

  return (
    <Grid>
      {status.success && status.create &&
        <Modal type='create' title='Dokument erfolgreich angelegt' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/documents'>Zurück zur Übersicht</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/documents/create'>Neues Dokument anlegen</Button>
          </Grid>
        </Modal>}
      {status.success && status.update &&
        <Modal type='success' title='Änderungen wurden erfolgreich übernommen' close={e => closeModal()}>
          <Grid item xs={12}>
            <Button to='/permissions'>Ok</Button>
          </Grid>
        </Modal>}
      <Spacer height='40px' />
      {status.create &&
        <h1>Neue Berechtigung anlegen</h1>}
      {status.update &&
        <h1>Berechtigung bearbeiten</h1>}
      <Spacer height='30px' />
      <FormFields permission={permission} setPermission={setPermission} handleChange={handleChange} />
      {errorNotification !== '' &&
        <div>
          <Modal type='error' message={errorNotification} />
          <Spacer />
        </div>}
      <Button onClick={e => submit()}>
        {status.create &&
          <>Berechtigung erstellen</>}
        {status.update &&
          <>Berechtigung aktualisieren</>}
      </Button>
    </Grid>
  )
}
export default withRouter(PermissionEdit)
