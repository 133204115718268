import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import NewButton from '../common/LinkButton'
import Spacer from '../common/Spacer'
import OverviewTable from './OverviewTable'
import OverviewRefine from './OverviewRefine'
import Users from '../common/ApiHandler/User'
import InfoBar from '../common/EntryInfoBar'
import Button from '../common/Button'
import LoadIndicator from '../common/LoadIndicator'
import VizSensor from 'react-visibility-sensor'
import Permissions from '../common/ApiHandler/Permission'

const Overview = () => {
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [options, setOptions] = useState({
    pageSize: 15,
    increasePageSize: 10
  })
  const [loading, setLoading] = useState(false)
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(false)
  const [permission, setPermission] = useState({})
  const [allPermissions, setAllPermissions] = useState([])
  const [title, setTitle] = useState('')

  let name = permission.title

  const createTitle = (permissionsList, matchId) => {
    let match = {}
    permissionsList.forEach((entry) => {
      if (entry.id === matchId) {
        match = entry
      } else if (entry.children && entry.children.length > 0 && match !== {}) {
        createTitle(entry.children, matchId)
      }
    })
    if (match.id) {
      name = match.title + '.' + name
      if (match.parentId) {
        createTitle(allPermissions, match.parentId)
      }
      setTitle(name)
    }
  }

  useEffect(() => {
    if (permission && permission.id && permission.parentId) {
      createTitle(allPermissions, permission.parentId)
    } else if (permission && permission.id && !permission.parentId) {
      setTitle(permission.title)
    }
    // eslint-disable-next-line
  },[allPermissions, permission])

  /**
   * Lazy Load for Documents
   */
  useEffect(() => {
    if (!loading && loadMoreButtonVisible) {
      loadMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreButtonVisible])

  const setPageSize = (pageSize) => {
    setOptions({ ...options, pageSize: pageSize })
  }

  const loadMore = () => {
    setPageSize(options.pageSize + options.increasePageSize)
  }

  const prepareDataForSearch = (users) => {
    const preparedArray = []
    if (Array.isArray(users) && users.length > 0) {
      users.forEach((user) => {
        user.complete = user.company + ' ' + user.firstname + ' ' + user.lastname + ' ' + user.internalnote + ' ' + user.voucher + ' ' + user.email
        preparedArray.push(user)
      })
    }
    return preparedArray
  }

  const getCorrectPermission = async (list, matchId) => {
    let match = {}
    await list.forEach((entry) => {
      if (entry.id === matchId) {
        match = entry
      } else if (entry.children && entry.children.length > 0 && match !== {}) {
        getCorrectPermission(entry.children, matchId)
      } else {
      }
    })
    if (match && match.id) {
      setPermission(match)
    }
  }

  const reload = async () => {
    setLoading(true)
    const API = Users({})
    const matchId = /[^/]*$/.exec(window.location.href)[0]
    if (matchId !== 'users') {
      const allUsers = await API.findAll({ permissionid: matchId })
      const preparedUsers = prepareDataForSearch(allUsers)
      setUsers(preparedUsers)

      const currentPermission = Permissions({ id: '' })
      const foundPermission = await currentPermission.get({ id: matchId })
      getCorrectPermission(foundPermission, matchId)
      setAllPermissions(foundPermission)
    } else {
      const allUsers = await API.findAll()
      const preparedUsers = prepareDataForSearch(allUsers)
      setUsers(preparedUsers)
      setFilteredUsers(preparedUsers)
    }
    setLoading(false)
  }

  useEffect(() => {
    reload()
    // eslint-disable-next-line
  },[])

  const visibleEntryCount = filteredUsers.length > options.pageSize ? options.pageSize : filteredUsers.length

  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Spacer />
          {permission && !permission.id &&
            <h1>Benutzer</h1>}
          {permission && permission.id &&
            <h1>Benutzer für {title}</h1>}

        </Grid>
        <Grid item xs={2}>
          <Spacer />
          {/* <NewButton title='CSV Export' icon='download' to={generateCVSLink()}/> */}
        </Grid>
        <Grid item xs={4}>
          <Spacer />
          <NewButton title='Neuen Benutzer anlegen' icon='user' to='/users/create' />
        </Grid>
      </Grid>
      <OverviewRefine users={users} setFilteredUsers={setFilteredUsers} reload={reload} />
      <Grid item xs={12}>
        <Spacer height='40px' />
        <h3>Ihre Ergebnisse</h3>
        {!loading &&
          <OverviewTable users={filteredUsers} options={options} />}
        {loading &&
          <LoadIndicator />}
        <Grid item xs={12}>
          {!loading &&
            <InfoBar
              totalEntryCount={users.length}
              visibleEntryCount={visibleEntryCount}
            />}
          <Spacer />
          {options.pageSize < users.length &&
            <>
              <VizSensor onChange={(isVisible) => { setLoadMoreButtonVisible(isVisible) }}>
                <Button onClick={() => loadMore()}>Mehr Laden</Button>
              </VizSensor>
              <Spacer />
            </>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Overview
