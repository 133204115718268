import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Documents from '../common/ApiHandler/Document'
import moment from 'moment'
import MailItem from '../User/Device/MailItem'

const InfoContainer = styled.div`
  float:left;
  border-bottom: 0.5px solid #CCCCCC;
  width: 100%;
  padding-top: 20px;

`

const DocumentInfoText = styled.p`
  height: 14px;
  width: 66px;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
`

const DocumentDate = styled.p`
  height: 17px;
  width: 100%;
  color: #30393F;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: left;
  margin-bottom: 10px;
`

const StyledGrid = styled.div`
  margin-top: 40px;
  border-left: 0.5px solid #CCCCCC;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
`

const MailHeadlineContainer = styled.div`
  float:left;
  width: 100%;
  padding-top: 20px;
`

const Filename = styled.h3`
  line-height: 22px;
`

const MailContainer = styled.div`
  max-height: 730px;
  overflow-y: auto;
  width: 100%;
`

const DocumentSidebar = () => {
  // eslint-disable-next-line
  const [errorNotification, setErrorNotification] = useState('')
  const [document, setDocument] = useState({})
  const [mails, setMails] = useState([])

  const betterDate = (dateChange) => {
    if (typeof dateChange === 'string') {
      var date = new Date(dateChange)
      var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return dateString
    } else {

    }
  }

  const checkLengthFilename = (document) => {
    if (document && document.filename) {
      const singleWords = document.filename.split(/\s+/)
      const lengthArray = singleWords.map(({ length }) => length)
      lengthArray.forEach((length, index) => {
        if (length > 22) {
          const str1 = singleWords[index].slice(0, 21)
          const str2 = singleWords[index].slice(22, singleWords[index].length)
          singleWords[index] = str1 + '\n' + str2
        }
      })
      let newFilename = ''
      singleWords.forEach((string) => {
        newFilename += string + ' '
      })
      setDocument({ ...document, filename: newFilename })
    }
  }

  const loadDocument = async () => {
    const url = window.location.href
    const splitURL = url.split('/')
    const documentId = splitURL[splitURL.length - 1]
    const currentDoc = Documents({ id: documentId })
    const loadedDoc = await currentDoc.findOne(documentId)
    const loadedMails = await currentDoc.getInfoMails({ type: 'documentInfo' })
    setMails(loadedMails)
    setDocument(loadedDoc)
    checkLengthFilename(loadedDoc)
  }

  const reloadMailsInterval = useCallback(async (documentId) => {
    if (mails && mails.length && mails[0].status === 'pending') {
      const currentDoc = Documents({ id: documentId })
      const loadedMails = await currentDoc.getInfoMails({ type: 'documentInfo' })
      setMails(loadedMails)
    }
  }, [mails])

  useEffect(() => {
    const url = window.location.href
    const splitURL = url.split('/')
    const documentId = splitURL[splitURL.length - 1]
    reloadMailsInterval(documentId)
  }, [mails, reloadMailsInterval])

  useEffect(() => {
    loadDocument()
    // eslint-disable-next-line
  }, [])

  const showSize = (byte) => {
    let kb = 0
    if (byte > 1024) {
      kb = byte / 1024
    }
    if (kb < 1000) {
      return kb.toFixed(2) + 'KB'
    } else {
      const mb = kb / 1000
      return mb.toFixed(2) + 'MB'
    }
  }

  return (
    <>
      {document &&
        <StyledGrid container spacing={0}>
          <Filename>{document.filename}</Filename>
          <InfoContainer>
            <DocumentInfoText>Hochgeladen am:</DocumentInfoText>
            <DocumentDate>{betterDate(document.createdAt)}</DocumentDate>
          </InfoContainer>
          <InfoContainer>
            <DocumentInfoText>Veröffentlicht am:</DocumentInfoText>
            <DocumentDate>{betterDate(document.publishDate)}</DocumentDate>
          </InfoContainer>
          <InfoContainer>
            <DocumentInfoText>Zuletzt ausgetauscht am:</DocumentInfoText>
            <DocumentDate>{betterDate(document.uploadedAt)}</DocumentDate>
          </InfoContainer>
          {!!document.newSince &&
            <InfoContainer>
              <DocumentInfoText>Neu-Zeitstempel am:</DocumentInfoText>
              <DocumentDate>{moment(document.newSince).format('DD.MM.YYYY HH:mm')}</DocumentDate>
            </InfoContainer>}
          <InfoContainer>
            <DocumentInfoText>Größe:</DocumentInfoText>
            <DocumentDate>{showSize(document.filesize)}</DocumentDate>
          </InfoContainer>
          <InfoContainer>
            <DocumentInfoText>Mimetype:</DocumentInfoText>
            <DocumentDate>{document.mimetype}</DocumentDate>
          </InfoContainer>
          {!!mails.length &&
            <>
              <MailHeadlineContainer>
                <h2>Info-Mails</h2>
              </MailHeadlineContainer>
              <MailContainer>
                {mails.map(mail => (<MailItem key={mail.id} mail={mail} />))}
              </MailContainer>
            </>}
        </StyledGrid>}
    </>

  )
}

DocumentSidebar.propTypes = {
  user: PropTypes.any
}

export default DocumentSidebar
