import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ActionButton from '../common/ActionButton'
import Grid from '@mui/material/Grid'
import Modal from '../common/GeneralNotification'
import Button from '../common/Button'
import { Admin } from '../common/ApiHandler/Admin'
import Translation from '../common/BasicTranslation'

const ActionWrapper = styled.div`
  position: relative;
  height: 40px;
  margin-top: ${props => !props.isFirefox ? '0px' : '-5px'};
`

const StyledActionButton = styled(ActionButton)`
  position: relative;
  height: 20px;
`
const OverviewTableItem = ({ admin }) => {
  // eslint-disable-next-line
  const [admins, setAdmins] = useState([])
  // eslint-disable-next-line
  const [status, setStatus] = useState({
    delete: false,
    success: false
  })
  const [errorNotification, setErrorNotification] = useState('')
  const adminId = admin.id

  const isFirefox = typeof InstallTrigger !== 'undefined'

  const closeModal = async () => {
    await setStatus({ ...status, success: false, delete: false })
  }
  const showDelete = async () => {
    await setStatus({ ...status, delete: true })
  }
  const deleteAdmin = async () => {
    const res = await Admin({ id: adminId }).destroy()
    if (typeof res === 'object') {
      await setStatus({ ...status, success: true, delete: false })
    } else {
      await setErrorNotification(Translation(res))
    }
  }
  const betterUpdatedAt = (admin) => {
    var date = new Date(admin.updatedAt)
    var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
    return dateString
  }
  const resetStatus = () => {
    setStatus({ delete: false, success: false })
    window.location.reload()
  }
  const toggleActive = (admin) => {
    if (admin.active) {
      admin.active = false
    } else {
      admin.active = true
    }
  }

  admin.active = true

  const actions = (
    <ActionWrapper isFirefox={isFirefox}>
      {status.success &&
        <Modal type='success' title='Administrator gelöscht' message='Erfolgreich gelöscht' close={e => closeModal()}>

            <Grid item xs={6}>
              <Button to='/admins/' onClick={() => resetStatus()}>Administratorübersicht</Button>
            </Grid>
            <Grid item xs={6}>
              <Button to='/admins/create'>Neue Administrator erstellen</Button>
            </Grid>

        </Modal>}
      {status.delete &&
        <Modal type='delete' title='Administrator löschen' message={`Sind Sie sich sicher, dass Sie ${admin.firstname} ${admin.lastname} löschen wollen?`} close={e => closeModal()} errorNotification={errorNotification}>
          <Grid item xs={6}>
            <Button onClick={async () => deleteAdmin()}>Ja</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={e => closeModal()}>Nein</Button>
          </Grid>
        </Modal>}
      {admin.active === false &&
        <ActionButton action='disabled' onClick={() => toggleActive(admin)} />}
      {admin.active === true &&
        <ActionButton action='enabled' onClick={() => toggleActive(admin)} />}
      {typeof InstallTrigger === 'undefined' &&
        <ActionButton action='edit' to={`/admins/update/${admin.id}`} />}
      {/* Check if browser is Firefox */}
      {typeof InstallTrigger !== 'undefined' &&
        <StyledActionButton action='edit' to={`/admins/update/${admin.id}`} />}
      <ActionButton action='delete' onClick={async () => showDelete()} />
    </ActionWrapper>
  )

  return (
    <tr>
      <td>{admin.firstname}</td>
      <td>{admin.lastname}</td>
      <td>{betterUpdatedAt(admin)}</td>
      <td>{actions}</td>
    </tr>
  )
}

OverviewTableItem.propTypes = {
  admin: PropTypes.any
}

export default OverviewTableItem
