import Api from './Api'

const api = Api()

const Document = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    save: async function () {
      const res = await api.exec(`/documents/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })

      if (res.error) {
        return res.error
      }
      return Document(res.document)
    },
    getThumbnail: async ({ referenceid, id }) => {
      const res = await api.exec(`/documents/${id}/download/true?returnUrl=true`, {
        method: 'GET'
      })
      return res.targetUrl
    },
    destroy: async () => {
      const res = await api.exec(`/documents/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    },
    download: async (id) => {
      const res = await api.exec(`/documents/${id}/download/false`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
    },
    getDownloadUrl: (id) => {
      return api.buildUrl(`/documents/${id}/download/false`)
    },
    assignTag: async (tagId) => {
      const res = await api.exec(`/documents/${id}/tags/${tagId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            documentid: id,
            tagid: tagId
          })
        })
      if (res.error) return res.error
      return res
    },
    unassignTag: async (tagId) => {
      const res = await api.exec(`/documents/${id}/tags/${tagId}`,
        {
          method: 'DELETE',
          body: JSON.stringify({
            documentid: id,
            tagid: tagId
          })
        })
      if (res.error) return res.error
      return res
    }
  }
}

const Documents = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    create: async () => {
      const res = await api.exec('/documents', {
        method: 'POST',
        body: JSON.stringify(restProps)
      })
      if (res.error) {
        return res.error
      }
      return Document(res.document)
    },
    upload: async (file, thumbnail, id, skippreviewgeneration = false) => {
      const uploadData = new FormData()
      uploadData.append('file', file)
      uploadData.append('skippreviewgeneration', skippreviewgeneration)
      const res = await api.exec(`/documents/${id}/upload/${thumbnail}`, {
        method: 'POST',
        headers: {},
        body: uploadData
      })
      if (res.error) {
        return res.error
      }
      return Document(res)
    },
    findAll: async (params) => {
      const res = await api.exec('/documents', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        return res.error
      }
      if (res.documents.length < 1) {
        return []
      }
      return { documents: res.documents.map(document => Document(document)), total: res.total }
    },
    search: async (params) => {
      const res = await api.exec('/documents', {
        method: 'GET',
        body: JSON.stringify(params)
      })

      if (res.error) {
        return res.error
      }
      if (res.documents.length < 1) {
        return []
      }
      return res.documents.map(document => Document(document))
    },
    searchUser: async (id) => {
      const res = await api.exec(`/documents/users/${id}`, {
        method: 'GET',
        body: JSON.stringify(id)
      })

      if (res.error) {
        return res.error
      }
      if (res.documents.length < 1) {
        return []
      }
      return res.documents.map(document => Document(document))
    },
    sendMail: async (params) => {
      const res = await api.exec(`/documents/${id}/emails`, {
        method: 'POST',
        body: JSON.stringify(params)
      })

      return res
    },
    getInfoMails: async (params) => {
      const res = await api.exec(`/documents/${id}/emails`, {
        method: 'GET',
        body: JSON.stringify(params)
      })

      if (res.error) {
        return res.error
      }
      if (res.emails.length < 1) {
        return []
      }
      return res.emails
    },
    findOne: async () => {
      const res = await api.exec(`/documents/${id}`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
      // quickfix @todo
      if (res.document.title_en === null) {
        res.document.title_en = ''
      }
      return Document(res.document)
    }
  }
}

export { Document }
export default Documents
