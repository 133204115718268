const countries = [
  { label: 'Ägypten', value: 'ägypten' },
  { label: 'Argentinien', value: 'argentinien' },
  { label: 'Australien', value: 'australien' },
  { label: 'Belgien', value: 'belgien' },
  { label: 'Bosnien und Herzegowina', value: 'bosnien-und-herzegowina' },
  { label: 'Brasilien', value: 'brasilien' },
  { label: 'Bulgarien', value: 'bulgarien' },
  { label: 'Chile', value: 'chile' },
  { label: 'China', value: 'china' },
  { label: 'Dänemark', value: 'dänemark' },
  { label: 'Deutschland', value: 'deutschland' },
  { label: 'Finnland', value: 'finnland' },
  { label: 'Frankreich', value: 'frankreich' },
  { label: 'Griechenland', value: 'griechenland' },
  { label: 'Großbritannien', value: 'großbritannien' },
  { label: 'Indien', value: 'indien' },
  { label: 'Indonesien', value: 'indonesien' },
  { label: 'Iran', value: 'iran' },
  { label: 'Island', value: 'island' },
  { label: 'Israel', value: 'israel' },
  { label: 'Italien', value: 'italien' },
  { label: 'Japan', value: 'japan' },
  { label: 'Jordanien', value: 'jordanien' },
  { label: 'Kanada', value: 'kanada' },
  { label: 'Kolumbien', value: 'kolumbien' },
  { label: 'Lettland', value: 'lettland' },
  { label: 'Malaysia', value: 'malaysia' },
  { label: 'Marokko', value: 'marokko' },
  { label: 'Mexiko', value: 'mexiko' },
  { label: 'Neuseeland', value: 'neuseeland' },
  { label: 'Niederlande', value: 'niederlande' },
  { label: 'Nigeria', value: 'nigeria' },
  { label: 'Norwegen', value: 'norwegen' },
  { label: 'Österreich', value: 'österreich' },
  { label: 'Pakistan', value: 'pakistan' },
  { label: 'Polen', value: 'polen' },
  { label: 'Portugal', value: 'portugal' },
  { label: 'Rumänien', value: 'rumänien' },
  { label: 'Russland', value: 'russland' },
  { label: 'Saudi-Arabien', value: 'saudi-arabien' },
  { label: 'Schweden', value: 'schweden' },
  { label: 'Schweiz', value: 'schweiz' },
  { label: 'Serbien', value: 'serbien' },
  { label: 'Singapur', value: 'singapur' },
  { label: 'Slowakei', value: 'slowakei' },
  { label: 'Slowenien', value: 'slowenien' },
  { label: 'Spanien', value: 'spanien' },
  { label: 'Südafrika', value: 'südafrika' },
  { label: 'Südkorea', value: 'südkorea' },
  { label: 'Taiwan', value: 'taiwan' },
  { label: 'Thailand', value: 'thailand' },
  { label: 'Tschechien', value: 'tschechien' },
  { label: 'Türkei', value: 'türkei' },
  { label: 'Ungarn', value: 'ungarn' },
  { label: 'USA', value: 'usa' },
  { label: 'Vereinigte Arabische Emirate', value: 'vereinigte arabische emirate' },
  { label: 'Vietnam', value: 'vietnam' }
]

export default countries