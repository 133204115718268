import React from 'react'

const SvgClosed = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <title>closed</title>
    <path
      d='M12.75 7h-.875V5.25A4.377 4.377 0 007.5.875 4.377 4.377 0 003.125 5.25V7H2.25C1.288 7 .5 7.787.5 8.75v8.75c0 .962.788 1.75 1.75 1.75h10.5c.963 0 1.75-.788 1.75-1.75V8.75c0-.963-.787-1.75-1.75-1.75zM7.5 14.875c-.963 0-1.75-.787-1.75-1.75s.787-1.75 1.75-1.75 1.75.787 1.75 1.75-.787 1.75-1.75 1.75zM4.875 7V5.25A2.621 2.621 0 017.5 2.625a2.621 2.621 0 012.625 2.625V7h-5.25z'
      fill='#FFF'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgClosed
