import React from 'react'
import styled from 'styled-components'
import SelectionTitle from './SelectionTitle'
import PropTypes from 'prop-types'

const StyledTextArea = styled.textarea`
  height: 100%;
  resize: none;
  color: #30393f;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 10px;
  border: none;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  min-height: 100px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
`

function TextArea (props) {
  return (
    <div>
      <SelectionTitle title={props.title} />
      <StyledTextArea
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        type={props.type}
        onChange={props.onChange}
        {...props}
      />
    </div>
  )
}
TextArea.propTypes = {
  title: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.any
}
export default TextArea
