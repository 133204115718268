import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledLoginInput = styled.input`
  height: 34px;
  width: 400px;
  box-sizing: border-box;
  border-radius: 17px;
  background-color: rgba(48,57,63,0.8);
  border: 1px solid rgba(0, 0, 0, 0);
  appearance: none;
  padding: 10px 16px;
  font-size: 14px;
  letter-spacing: 0.55px;
  line-height: 16px;
  font-family: 'Open Sans Light';
  color: #FFFF;
`

const StyledLabel = styled.div`
  height: 17px;
  color: rgba(255,255,255,0.8);
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.55px;
  line-height: 17px;
  text-align: left;
  padding-bottom: 5px;
  padding-left: 15px;
`

const LoginInput = ({ type, label, placeholder, onChange }) => {
  return (
    <label>
      <StyledLabel>{label}</StyledLabel>
      <StyledLoginInput onChange={onChange} placeholder={placeholder} type={type} />
    </label>
  )
}

LoginInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string

}
export default LoginInput
